import classNames from 'classnames'

export const SectionInstructions = ({
    className,
    title = 'Как работать с разделом?',
    onClick = () => {},
}) => {
    return (
        <div
            className={classNames(
                className,
                'cursor-pointer text-blue-500',
                'hover:text-blue-700',
                'dark:text-blue-400 dark:hover:text-blue-200'
            )}
            onClick={onClick}
        >
            {title}
        </div>
    )
}
