export const callTouch = () => {
    const scriptId = 'calltouch-script'
    const existingScript = document.getElementById(scriptId)

    if (!existingScript) {
        const script = document.createElement('script')
        script.id = scriptId
        script.async = true
        script.src = `https://mod.calltouch.ru/init-min.js?id=${import.meta.env.VITE_APP_MOD_ID}`

        document.head.appendChild(script)
    }
}
