import classNames from 'classnames'

const Badge = ({
    size = 'basic', // 'large' || 'basic'
    rounded = false,
    color = 'gray', // 'gray' || 'red' || 'yellow' || 'green' || 'blue'
    bright = false,
    children,
    className,
}) => {
    return (
        <>
            <span
                className={classNames(
                    className,
                    'inline-flex items-center font-medium',
                    size === 'basic' && 'px-2.5 py-0.5 text-xs',
                    size === 'large' && 'px-3 py-0.5 text-sm',
                    rounded ? 'rounded-full' : 'rounded',
                    color === 'gray' &&
                        (!bright
                            ? 'bg-gray-200 text-gray-800 dark:bg-gray-500 dark:text-white'
                            : 'bg-gray-400 text-white dark:bg-gray-300 dark:text-gray-800'),
                    color === 'red' &&
                        (!bright
                            ? 'bg-red-200 text-red-800 dark:bg-red-800/50 dark:text-red-400'
                            : 'bg-red-500 text-white dark:bg-red-400 dark:text-red-800'),
                    color === 'yellow' &&
                        (!bright
                            ? 'bg-yellow-200 text-yellow-800 dark:bg-yellow-500/75 dark:text-yellow-300'
                            : 'bg-yellow-400 text-white dark:bg-yellow-300 dark:text-yellow-800'),
                    color === 'green' &&
                        (!bright
                            ? 'bg-green-200 text-green-800 dark:bg-green-400/50 dark:text-green-300'
                            : 'bg-green-400 text-white dark:bg-green-300 dark:text-green-800'),
                    color === 'blue' &&
                        (!bright
                            ? 'bg-blue-200 text-blue-800 dark:bg-blue-800/50 dark:text-blue-300'
                            : 'bg-blue-500 text-white dark:bg-blue-300 dark:text-blue-800')
                )}
            >
                {children}
            </span>
        </>
    )
}

export {Badge}
