import {TooltipContentWrapper} from '@components/applicationUi/Tooltip'

import {GreenExtension} from '@views/marketplaces/pages/ImportWb/components/ImportWbExtensionItem/components/GreenExtension'
import {RedExtension} from '@views/marketplaces/pages/ImportWb/components/ImportWbExtensionItem/components/RedExtension'
import {YellowExtension} from '@views/marketplaces/pages/ImportWb/components/ImportWbExtensionItem/components/YellowExtension'

export const IMPORT_WB_HEADER_CELL_MAP = {
    dataExisting: {
        title: 'Наличие данных',
        content: (
            <TooltipContentWrapper>
                <ul
                    className={
                        'ml-4 mt-2 list-disc space-y-2 text-gray-700 dark:text-gray-300'
                    }
                    role='list'
                >
                    <li>
                        Зеленый цвет индикатора означает, что данные получены и
                        они корректны. По умолчанию мы считаем корректными
                        данные, полученные из Excel.
                    </li>
                    <li>
                        Желтый цвет означает, что данные за период получены не
                        полностью.
                    </li>
                    <li>
                        Красный цвет показывает отклонение данных API от Excel.
                        Если присутствуют отклонения в данных API от Excel,
                        рекомендуем установить Фин отчеты Excel в качестве
                        приоритетного источника данных и загрузить отчеты за
                        недостающие периоды.
                    </li>
                    <li>
                        Серый цвет означает, что данные еще не были загружены в
                        систему
                    </li>
                </ul>
            </TooltipContentWrapper>
        ),
    },
}

export const HIDDEN_ICON_MAP = {
    api_green: true,
    api_yellow: true,
    xls_red: true,
}

const GRAY_WB_TEXT = {
    api: 'Данные по API не были подгружены',
    xls: 'Детализация Excel не была загружена',
}

export const IMPORT_WB_TOOLTIP_CONTENT = {
    green: (props) => {
        if (props.name === 'api') return null
        return <GreenExtension {...props} />
    },
    yellow: YellowExtension,
    gray: ({name}) => <span>{GRAY_WB_TEXT[name]}</span>,
    red: RedExtension,
}
