import slide2Dark from '@assets/auth/images/slide-2-dark.png'
import slide2Light from '@assets/auth/images/slide-2-light.png'
import {EmptyDataStub} from '@components/applicationUi'
import {
    InformerBlock,
    InformerBlockDemo,
} from '@components/applicationUi/InformerBlock'
import {ContainerFullOnMobile, NoCredentialsWrapper} from '@components/layout'
import {Overlay} from '@components/overlays'
import {useSelector} from '@hooks/mobxStoreHooks/useSelector'
import {wbFilter} from '@utils/helpers'
import {ErrorLegalModal} from '@views/marketplaces/pages/ImportWb/components/ErrorLegalModal'
import {ErrorUnknownZipContent} from '@views/marketplaces/pages/ImportWb/components/ErrorUnknownZipContent'
import {ErrorZipContent} from '@views/marketplaces/pages/ImportWb/components/ErrorZipContent'
import {ImportWbInfo} from '@views/marketplaces/pages/ImportWb/components/ImportWbInfo'
import {ImportWbOverlay} from '@views/marketplaces/pages/ImportWb/components/ImportWbOverlay'
import {ImportWbTable} from '@views/marketplaces/pages/ImportWb/components/ImportWbTable'
import {SuccessLegalModal} from '@views/marketplaces/pages/ImportWb/components/SuccessLegalModal'
import {ImportWbFilterStore} from '@views/marketplaces/pages/ImportWb/store/ImportWbFilterStore'
import {ImportWbOverlayStore} from '@views/marketplaces/pages/ImportWb/store/ImportWbOverlayStore'
import {ImportWbStatusesStore} from '@views/marketplaces/pages/ImportWb/store/ImportWbStatusesStore'
import {ImpostWbStore} from '@views/marketplaces/pages/ImportWb/store/ImpostWbStore'
import {observer} from 'mobx-react'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'

export const ImportWb = observer(() => {
    const {isDarkTheme} = useSelector((store) => store.themeStore)

    const marketStore = useSelector((store) => store.marketStore)
    const userStore = useSelector((store) => store.userStore)

    const [importWbOverlayStore] = useState(() => new ImportWbOverlayStore())
    const [importWbFilterStore] = useState(
        () => new ImportWbFilterStore(marketStore)
    )
    const [impostWbStore] = useState(
        () => new ImpostWbStore(importWbFilterStore)
    )

    const [importWbStatusesStore] = useState(
        () =>
            new ImportWbStatusesStore(
                importWbFilterStore,
                () => impostWbStore.importWbListPolling.startPolling(),
                () => userStore.requestUserData()
            )
    )

    useEffect(() => {
        const cabinet = marketStore.credentialsOptions.filter(wbFilter)[0] || {}
        importWbFilterStore.cabinet.setSelectedItem(cabinet, true)
        if (cabinet.key) {
            importWbFilterStore.initialPriorityData(cabinet.key)
        }
        return () => {
            importWbFilterStore.destroy()
            impostWbStore.destroy()
            importWbStatusesStore.destroy()
        }
    }, [])

    const handleCheckStatuses = (
        cabinet,
        isConnectedLegal = false,
        legalName
    ) => {
        if (!importWbStatusesStore.isConnectedLegal) {
            importWbStatusesStore.setIsConnectedLegal(isConnectedLegal)
            if (isConnectedLegal && legalName)
                importWbStatusesStore.setLegalOptions({
                    legalName,
                    cabinet,
                })
        }
        importWbStatusesStore.importStatusesListingPolling.startPolling(cabinet)
    }

    useEffect(() => {
        if (importWbStatusesStore.errorZipImport.result) {
            toast(
                <ErrorZipContent
                    documentList={
                        importWbStatusesStore.errorZipImport.documentList
                    }
                />,
                {
                    autoClose: false,
                    draggable: true,
                    type: 'warning',
                }
            )
        }
    }, [importWbStatusesStore.errorZipImport])

    useEffect(() => {
        if (importWbStatusesStore.errorUnknownZipImport.result) {
            const errorData = importWbStatusesStore.errorUnknownZipImport
            toast(
                <ErrorUnknownZipContent
                    unknownList={errorData.errorList}
                    cabinetName={errorData.cabinetName}
                />,
                {
                    autoClose: false,
                    draggable: true,
                    type: 'warning',
                }
            )
        }
    }, [importWbStatusesStore.errorUnknownZipImport])

    const handleSuccessApply = () => {
        importWbStatusesStore.resetLegalOptions()
    }
    return (
        <NoCredentialsWrapper>
            <ContainerFullOnMobile className='space-y-6 py-6'>
                <InformerBlock />
                <InformerBlockDemo />
                <ImportWbInfo
                    filterStore={importWbFilterStore}
                    impostWbStore={impostWbStore}
                    importWbOverlayStore={importWbOverlayStore}
                    importWbStatusesStore={importWbStatusesStore}
                />
            </ContainerFullOnMobile>
            {!importWbFilterStore.cabinet.selected?.key ? (
                <EmptyDataStub
                    title={
                        'Загрузка данных доступна только для кабинетов Wilberries'
                    }
                    description={''}
                    className={'sm:pl-6 lg:pl-8'}
                    img={isDarkTheme ? slide2Dark : slide2Light}
                />
            ) : (
                <ImportWbTable importWbStore={impostWbStore} />
            )}
            <Overlay
                open={importWbOverlayStore.overlayControl.isOpen}
                setOpen={importWbOverlayStore.overlayControl.close}
                title={'Загрузка отчетов Wildberries'}
                size={'full'}
            >
                <ImportWbOverlay
                    onCheckStatuses={handleCheckStatuses}
                    importWbOverlayStore={importWbOverlayStore}
                    cabinet={importWbFilterStore.cabinet.selected}
                />
            </Overlay>
            <ErrorLegalModal
                open={importWbStatusesStore.errorLegalModalControl.isOpen}
                onClose={importWbStatusesStore.errorLegalModalControl.close}
                onOpenImportOverlay={
                    !importWbOverlayStore.overlayControl.isOpen &&
                    importWbOverlayStore.overlayControl.open
                }
                legalList={importWbStatusesStore.errorLegalXlsImport.legalList}
                cabinetName={
                    importWbStatusesStore.errorLegalXlsImport.cabinetName
                }
                isConnectedLegal={importWbFilterStore.validLegalEntity}
            />
            <SuccessLegalModal
                open={importWbStatusesStore.successLegalModalControl.isOpen}
                onClose={() => {
                    importWbStatusesStore.successLegalModalControl.close()
                    userStore.requestUserData()
                }}
                legalOptions={importWbStatusesStore.legalOptions}
                onApply={handleSuccessApply}
            />
        </NoCredentialsWrapper>
    )
})

ImportWb.displayName = 'ImportWb'
