const COMMAS = {
    RUR: ',',
    USD: '.',
    PCS: ' ',
    PER: ',',
}

const DIVIDERS = {
    RUR: ' ',
    USD: ',',
    PCS: ' ',
    PER: ' ',
}

export const SIGNS = {
    RUR: '₽',
    USD: '$',
    PCS: 'шт.',
    PER: '%',
    PP: 'п.п.',
}
export const SIGNS_KEY = {
    RUR: 'RUR',
    USD: 'USD',
    PCS: 'PCS',
    PER: 'PER',
    PP: 'PP',
}
export const convertAmountValue = ({
    value = 0,
    showCurrency = true,
    currency = 'RUR',
    decimalLength = 2,
    isPlus = false,
    nospace = false,
    withFixed = false,
}) => {
    const comma = COMMAS[currency] || '.'
    const sign = SIGNS[currency] || currency
    const divider = DIVIDERS[currency] || ' '
    const plus = isPlus && value > 0 ? '+' : ''

    // Разбиваем число на целую и десятичную часть
    let [integerPart, decimalPart] = value.toString().split('.')

    // Обрезаем десятичную часть до нужной длины и удаляем конечные нули
    if (decimalPart && !withFixed) {
        decimalPart = decimalPart.slice(0, decimalLength)
        while (decimalPart.endsWith('0')) {
            decimalPart = decimalPart.slice(0, -1)
        }
    }

    // Добавляем разделители в целую часть числа
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, divider)

    // Формируем итоговую строку
    let result = integerPart
    if (decimalPart) {
        result += comma + decimalPart
    }
    if (showCurrency) {
        result += (nospace ? '' : ' ') + sign
    }

    if (plus) {
        result = plus + result
    }

    return result
}
