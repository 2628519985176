import {SettingsStore} from '@components/applicationUi/SettingsOverlay/store/SettingsStore'
import SupportOverlayStore from '@components/applicationUi/SupportOverlay/store/SupportOverlayStore'
import AcademyStore from '@views/academy/store/AcademyStore'
import AccountStore from '@views/account/store/AccountStore'
import AdminPartnersStore from '@views/adminPartners/store/AdminPartnersStore'
import AdminUsersStore from '@views/adminUsers/store/AdminUsersStore'
import AuthStore from '@views/auth/store/AuthStore'
import BillStore from '@views/billing/store/BillStore'
import HelpStore from '@views/help/store/HelpStore'
import LayoutSidebarStore from '@views/layouts/store/LayoutSidebarStore'
import PromotionStore from '@views/promotion/store/PromotionStore'
import TelegramStore from '@views/telegram/store/TelegramStore'
import MarketStore from '../views/marketplaces/pages/Marketplaces/store/MarketStore'
import ErrorStore from './ErrorStore'
import {InformerStore} from './InformerStore'
import ThemeStore from './ThemeStore'
import UserStore from './UserStore'

class S24rootStore {
    constructor() {
        this.settingsStore = new SettingsStore()
        this.userStore = new UserStore(this)
        this.errorStore = new ErrorStore(this)
        this.authStore = new AuthStore(this)
        this.marketStore = new MarketStore(this)
        this.layoutSidebarStore = new LayoutSidebarStore(this)
        this.themeStore = new ThemeStore(this)
        this.accountStore = new AccountStore(this)
        this.billStore = new BillStore(this)
        this.promotionStore = new PromotionStore(this)
        this.supportOverlayStore = new SupportOverlayStore(this)
        this.telegramStore = new TelegramStore(this)
        this.informerStore = new InformerStore(this)
        this.helpStore = new HelpStore(this)
        this.adminUsersStore = new AdminUsersStore(this)
        this.adminPartnersStore = new AdminPartnersStore(this)
        this.academyStore = new AcademyStore(this)
    }
}

export default S24rootStore
