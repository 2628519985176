import {ArrowLeftIcon} from '@heroicons/react/outline'
import {useHistory} from 'react-router-dom'

const GoBack = ({label = 'Назад'}) => {
    const history = useHistory()
    return (
        <div
            className={'flex cursor-pointer items-center space-x-2 px-4'}
            onClick={history.goBack}
        >
            <ArrowLeftIcon
                className={'h-6 w-6 text-blue-500 dark:text-blue-400'}
            />
            <span
                className={
                    'ml-4 flex items-center text-lg font-medium text-gray-800 transition-all hover:text-blue-500 dark:text-gray-200 dark:hover:text-blue-400'
                }
            >
                {label}
            </span>
        </div>
    )
}

export {GoBack}
