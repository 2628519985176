import MarketService from '@services/MarketService/MarketService'
import {reportsStatus} from '@utils/constants'
import {PollingV2} from '@utils/polling/pollingv2'
import {makeAutoObservable, reaction} from 'mobx'
import {ModalControl} from '../../../../../store/ModalControl'

const initialLegalOptions = {
    legalName: '',
    cabinet: 0,
}

export class ImportWbStatusesStore {
    constructor(filters, requestList, requestUserData) {
        this.requestList = requestList
        this.filters = filters
        this.requestUserData = requestUserData
        makeAutoObservable(this)
        this.importStatusesListingPolling =
            this.createImportStatusesListingPolling()
        this.importStatusesRecalcPolling =
            this.createImportStatusesRecalcPolling()

        this.disposer = reaction(
            () => [this.status.statuses, this.checkXlsImportProcessing],
            ([statuses, isImportProcessing]) => {
                const xlsStatuses = statuses.filter((status) => {
                    return status.parseType === 'wb_xls'
                })
                if (xlsStatuses.length && !isImportProcessing) {
                    this.requestList()
                }
            }
        )
        this.disposerRecalc = reaction(
            () => [this.checkRecalcXlcImportProcessing],
            ([isImportRecalcProcessing], [prevIsImportRecalcProcessing]) => {
                if (prevIsImportRecalcProcessing && !isImportRecalcProcessing) {
                    this.requestList()
                }
            }
        )
        this.disposerErrorLegal = reaction(
            () => this.errorLegalXlsImport,
            (errorLegalXlsImport) => {
                if (
                    errorLegalXlsImport.result &&
                    errorLegalXlsImport.legalList.length
                ) {
                    this.errorLegalModalControl.open()
                }
            }
        )

        this.disposerSuccessLegal = reaction(
            () => this.successConnectedLegal,
            (successConnectedLegal) => {
                if (successConnectedLegal.result) {
                    this.setIsConnectedLegal(false)
                    this.successLegalModalControl.open()
                }
            }
        )
    }

    errorLegalModalControl = new ModalControl()
    successLegalModalControl = new ModalControl()

    isConnectedLegal = false
    legalOptions = initialLegalOptions

    status = {
        statuses: [],
    }

    statusRecalc = {
        statuses: [],
    }

    setIsConnectedLegal = (value) => {
        this.isConnectedLegal = value
    }

    setLegalOptions = (value) => {
        this.legalOptions = value
    }

    resetLegalOptions = () => {
        this.legalOptions = initialLegalOptions
    }

    get checkXlsImportProcessing() {
        const xlsStatuses = this.status.statuses.find((status) => {
            return status.parseType === 'wb_xls'
        })
        if (!xlsStatuses) return false
        return !!this.status.statuses.filter((status) => {
            return (
                status.status !== reportsStatus.success &&
                status.status !== reportsStatus.failure &&
                status.status !== reportsStatus.warning
            )
        }).length
    }

    get successConnectedLegal() {
        const response = {
            result: false,
        }
        const xlsStatuses = this.status.statuses.find((status) => {
            return status.parseType === 'wb_xls'
        })
        if (!xlsStatuses || !this.isConnectedLegal) return false
        const result = !!this.status.statuses.every(
            (status) => status.status === reportsStatus.success
        )
        if (result) {
            response.result = true
        }
        return response
    }

    get errorLegalXlsImport() {
        const response = {
            result: false,
            legalList: ['', ''],
            cabinetName: '',
        }
        const xlsStatuses = this.status.statuses.find((status) => {
            return status.parseType === 'wb_xls'
        })

        if (
            xlsStatuses?.status === reportsStatus.failure &&
            xlsStatuses?.description
        ) {
            const match = xlsStatuses.description.match(
                /\[\$wrong_company](.*)\|(.*)/
            )
            if (match) {
                const firstValue = match[1].trim()
                const secondValue = match[2].trim()
                response.result = true
                response.legalList = [firstValue, secondValue]
                response.cabinetName = this.status.cabinetName
                return response
            }
        }

        return response
    }

    get errorZipImport() {
        const response = {
            result: false,
            documentList: [],
            cabinetName: '',
        }
        const xlsStatuses = this.status.statuses.find((status) => {
            return status.parseType === 'wb_xls'
        })

        if (
            xlsStatuses?.status === reportsStatus.failure &&
            xlsStatuses?.description
        ) {
            const match = xlsStatuses.description.match(
                /\[\$not_found_report\](.*)/
            )
            if (match && match[1]) {
                const result = match[1]
                    .trim()
                    .split(',')
                    .map((item) => item.trim())
                response.result = true
                response.documentList = result
                response.cabinetName = this.status.cabinetName
                return response
            }
        }

        return response
    }

    get errorUnknownZipImport() {
        const response = {
            result: false,
            errorList: [],
            cabinetName: '',
        }
        const xlsStatuses = this.status.statuses.find((status) => {
            return status.parseType === 'wb_xls'
        })

        if (
            xlsStatuses?.status === reportsStatus.failure &&
            xlsStatuses?.description
        ) {
            const match = xlsStatuses.description.match(/\[\$unknown\](.*)/)
            if (match && match[1]) {
                const result = match[1]
                    .trim()
                    .split(',')
                    .map((item) => item.trim())
                response.result = true
                response.errorList = result
                response.cabinetName = this.status.cabinetName
                return response
            }
        }

        return response
    }

    get checkRecalcXlcImportProcessing() {
        return !!this.statusRecalc.statuses.filter((status) => {
            return (
                status.status !== reportsStatus.success &&
                status.status !== reportsStatus.failure &&
                status.status !== reportsStatus.warning
            )
        }).length
    }

    getCredentialStatuses = async (cabinet) => {
        const payload = {
            cabinets: [cabinet || this.filters.cabinet.selected.key],
        }
        try {
            const {data} = await MarketService.getCredentialStatuses(payload)
            return Promise.resolve(data.status)
        } catch (e) {
            console.log(e)
            return Promise.reject(e)
        }
    }

    onResult = (data) => {
        if (!data.length) return true
        this.status = data[0]
        return this.checkXlsImportProcessing
    }

    onResultRecalc = (data) => {
        if (!data.length) return true
        this.statusRecalc = data[0]
        return this.checkRecalcXlcImportProcessing
    }

    createImportStatusesListingPolling(delay = 5000) {
        return new PollingV2({
            name: 'importStatusesListingPolling',
            pollingFunction: this.getCredentialStatuses,
            onCheckResult: (data) => this.onResult(data),
            delay,
            isControlPolling: true,
        })
    }

    createImportStatusesRecalcPolling(delay = 5000) {
        return new PollingV2({
            name: 'importStatusesRecalcPolling',
            pollingFunction: this.getCredentialStatuses,
            onCheckResult: (data) => this.onResultRecalc(data),
            delay,
            isControlPolling: true,
        })
    }

    destroy = () => {
        this.disposer()
        this.disposerRecalc()
        this.disposerErrorLegal()
        this.disposerSuccessLegal()
    }
}
