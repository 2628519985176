import ReportsSettingsService from '@services/ReportsSettingsService/ReportsSettingsService'
import {format} from 'date-fns'
import {makeAutoObservable} from 'mobx'

export class SettingsStore {
    profitabilitySource = {}
    taxSource = {}
    activeProductSource = {}
    constructor(reportType = 'all') {
        this.reportType = reportType
        makeAutoObservable(this)
    }

    profitability = 0
    tax = 0
    activeProduct = false
    profitabilityLoading = true
    taxLoading = true
    activeProductLoading = true

    fetchSettingsData = async () => {
        this.getSettingsProfitability()
        //this.getSettingsTax()
        this.getSettingsActiveProduct()
    }

    setReportType = (value = 'all') => {
        this.reportType = value
    }

    setProfitability = (value) => {
        this.profitability = value
    }
    getSettingsTypeReports = async () => {
        try {
            const {data} = await ReportsSettingsService.getSettingsTypeReports()
        } catch (e) {
            console.log(e)
        }
    }

    getSettingsProfitability = async () => {
        this.profitabilityLoading = true
        try {
            const {data} =
                await ReportsSettingsService.getSettingsProfitability(
                    {
                        report_type: this.reportType,
                    },
                    this.profitabilitySource
                )
            this.profitabilitySource = {}
            this.profitability = data.profitabilityThreshold || 0
            this.profitabilityLoading = false
        } catch (e) {
            console.log(e)
            this.profitabilityLoading = false
        }
    }

    postSettingsProfitability = async (profitability) => {
        const payload = {
            profitability_threshold: profitability,
            report_type: this.reportType,
        }
        try {
            const {data} =
                await ReportsSettingsService.postSettingsProfitability(payload)
            this.profitability = profitability
        } catch (e) {
            console.log(e)
        }
    }

    getSettingsTax = async () => {
        this.taxLoading = true

        try {
            const {data} = await ReportsSettingsService.getSettingsTax(
                undefined,
                this.taxSource
            )
            this.taxSource = {}
            if (data.taxes.length) {
                this.tax = data.taxes[0].value
            }
            this.taxLoading = false
        } catch (e) {
            console.log(e)
            this.taxLoading = false
        }
    }

    postSettingsTax = async (actualFrom, tax) => {
        const payload = {
            actual_from: format(actualFrom, 'yyyy-MM-dd'),
            value: tax,
            report_type: this.reportType,
        }
        try {
            const {data} = await ReportsSettingsService.postSettingsTax(payload)
            if (data.result) {
                this.tax = tax
            }
        } catch (e) {
            console.log(e)
        }
    }

    deleteSettingsTax = async (taxId) => {
        try {
            const {data} = await ReportsSettingsService.deleteSettingsTax({
                tax_id: taxId,
            })
        } catch (e) {
            console.log(e)
        }
    }

    getSettingsActiveProduct = async () => {
        this.activeProductLoading = true
        try {
            const {data} =
                await ReportsSettingsService.getSettingsActiveProduct(
                    {
                        report_type: this.reportType,
                    },
                    this.activeProductSource
                )
            this.activeProductSource = {}
            this.activeProduct = data.isActive
            this.activeProductLoading = false
        } catch (e) {
            console.log(e)
            this.activeProductLoading = false
        }
    }

    postSettingsPActiveProduct = async (isActive) => {
        const payload = {
            is_active: isActive,
            report_type: this.reportType,
        }
        try {
            const {data} =
                await ReportsSettingsService.postSettingsPActiveProduct(payload)
            this.activeProduct = data.isActive
        } catch (e) {
            console.log(e)
        }
    }
}
