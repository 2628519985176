import {AuthUrl} from '@routesEnums'
import {baseConfig} from '@services/apiConfigs'
import {ApiFactory} from '@services/ApiFactory'

class AuthService {
    static instance = ApiFactory.createApi(baseConfig)
    static postLogin = async (payload) => {
        // const newConfig = {
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded',
        //     },
        // }
        return await AuthService.instance.post(AuthUrl.postLogin, payload)
    }

    static postSignUp = async (payload) => {
        return await AuthService.instance.post(AuthUrl.postSignUp, payload)
    }

    static getConfirmSms = async (payload) => {
        return await AuthService.instance.get(AuthUrl.confirmSms, payload)
    }

    static postConfirmSms = async (payload) => {
        return await AuthService.instance.post(AuthUrl.confirmSms, payload)
    }

    static getConfirmEmail = async (token) => {
        return await AuthService.instance.get(AuthUrl.confirmEmail(token))
    }

    static getProviders = async () => {
        return await AuthService.instance.get(AuthUrl.getProviders)
    }

    static getAuthByProvider = async (payload, providerName) => {
        return await AuthService.instance.get(
            AuthUrl.getCallbackProviders(providerName),
            payload
        )
    }

    static getLogout = async () => {
        return await AuthService.instance.get(AuthUrl.getLogout)
    }

    static postResetPasswordByEmail = async (payload) => {
        return await AuthService.instance.post(
            AuthUrl.postResetPasswordByEmail,
            payload
        )
    }

    static postResetPasswordByPhone = async (payload) => {
        return await AuthService.instance.post(
            AuthUrl.postResetPasswordByPhone,
            payload
        )
    }

    static getResetPasswordByPhone = async (payload) => {
        return await AuthService.instance.get(
            AuthUrl.postResetPasswordByPhone,
            payload
        )
    }

    static getResetPassword = async (resetToken) => {
        return await AuthService.instance.get(AuthUrl.resetPassword(resetToken))
    }

    static postResetPassword = async (payload, resetToken) => {
        return await AuthService.instance.post(
            AuthUrl.resetPassword(resetToken),
            payload
        )
    }

    static postRefreshToken = async (payload) => {
        const config = {
            isAuth: false,
            skipAuthRefresh: true,
        }
        return await AuthService.instance.post(
            AuthUrl.postRefresh,
            payload,
            config
        )
    }

    static getValidateToken = async (payload, userUuid) => {
        return await AuthService.instance.get(
            AuthUrl.validateToken(userUuid),
            payload
        )
    }
}

export default AuthService
