import {InformerBlock} from '@components/applicationUi/InformerBlock'
import {useAnalyticEventsHook} from '@hooks/analyticEventsHook'
import {useSelector} from '@hooks/mobxStoreHooks/useSelector'
import ProfileService from '@services/ProfileService/ProfileService'
import {ModalControl} from '@store/ModalControl'
import {CallConfirmModalService} from '@utils/callConfirmModalService'
import {CONNECTED_MP_ACTIONS} from '@utils/constants'
import {AuthorizedPartnership} from '@views/marketplaces/pages/Marketplaces/components/AuthorizedPartnership'
import {Available} from '@views/marketplaces/pages/Marketplaces/components/Available/Available'
import {ConfirmCallModal} from '@views/marketplaces/pages/Marketplaces/components/ConfirmCallModal'
import {Connected} from '@views/marketplaces/pages/Marketplaces/components/Connected/Connected'
import {FirstConnected} from '@views/marketplaces/pages/Marketplaces/components/FirstConnected'
import {HackingMp} from '@views/marketplaces/pages/Marketplaces/components/HackingMp'
import PromoSection from '@views/marketplaces/pages/Marketplaces/components/PromoSection/PromoSection'
import {observer} from 'mobx-react'
import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'

export const Marketplaces = observer(() => {
    const marketStore = useSelector((store) => store.marketStore)
    const [callModalControl] = useState(() => new ModalControl())
    const location = useLocation()
    const {pageView, connectedMpEvent} = useAnalyticEventsHook()

    useEffect(() => {
        pageView()
        checkOnOpenConfirmCall()
    }, [])

    useEffect(() => {
        if (marketStore.statuses.size && marketStore.newStatusesImport.length) {
            connectedMpEvent(CONNECTED_MP_ACTIONS.start, 'MP_import')
        }
        if (
            marketStore.statuses.size &&
            !marketStore.isNewFailedImport &&
            marketStore.isNewSuccessImport
        ) {
            connectedMpEvent(CONNECTED_MP_ACTIONS.complete, 'MP_import')
        }
        if (marketStore.isNewFailedImport) {
            connectedMpEvent(CONNECTED_MP_ACTIONS.fail, 'MP_import')
        }
    }, [
        marketStore.statuses.size,
        marketStore.isNewSuccessImport,
        marketStore.isNewFailedImport,
        marketStore.newStatusesImport,
    ])

    const updateProfile = async (second) => {
        const payload = {hit_add_contact_sec: second}
        try {
            await ProfileService.postProfile(payload)
            CallConfirmModalService.setOpenedCall()
        } catch (e) {
            console.log(e)
        }
    }

    const checkOnOpenConfirmCall = () => {
        if (location.state?.quiz && !CallConfirmModalService.getOpenedCall()) {
            callModalControl.props = {startTime: Date.now()}
            callModalControl.open()
        }
    }

    const handleCloseConfirmCall = () => {
        callModalControl.close()
        const second = Math.floor(
            (Date.now() - callModalControl.props.startTime) / 1000
        )
        updateProfile(second)
    }

    return (
        <main>
            <div className={'py-6'}>
                <div className='mx-auto mb-6 px-4 sm:px-6 md:px-8'>
                    <InformerBlock />
                </div>
                <div className='mx-auto px-4 sm:px-6 md:px-8'>
                    <Available />
                </div>
                <div className='mx-auto mb-6 px-4 sm:px-6 md:px-8'>
                    <AuthorizedPartnership />
                </div>
                {!marketStore.isRealAcc && (
                    <div className='mx-auto mb-6 px-4 sm:px-6 md:px-8'>
                        <FirstConnected />
                    </div>
                )}
                <div className='mx-auto mb-4 px-4 sm:px-6 md:px-8'>
                    <HackingMp />
                </div>
                <div className='mx-auto px-4 sm:px-6 md:px-8'>
                    <Connected />
                </div>
                <div className='mx-auto px-4 sm:px-6 md:px-8'>
                    <PromoSection />
                </div>
            </div>
            <ConfirmCallModal
                open={callModalControl.isOpen}
                onClose={handleCloseConfirmCall}
            />
        </main>
    )
})

Marketplaces.displayName = 'Marketplaces'
