import {makeObservable} from 'mobx'
import {ProfileStore} from '../Account/Settings/store/ProfileStore'

class AccountStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        this.reset()
        makeObservable(this)
    }

    accountTabs = [
        {
            id: 'tariff',
            name: 'Тариф',
            disabled: false,
            visible: true,
        },
        {
            id: 'settings',
            name: 'Настройки',
            disabled: false,
            visible: true,
        },
        {
            id: 'file_sharing',
            name: 'Ваши документы',
            disabled: false,
            visible: true,
        },
        {
            id: 'warehouses',
            name: 'Склад',
            disabled: false,
            dev: false,
            visible: false,
        },
    ]

    reset = () => {
        this.stores = {
            profileStore: new ProfileStore(this.rootStore),
        }
    }
}

export default AccountStore
