import {action, makeObservable, observable} from 'mobx'

export class SelectedStore {
    constructor(options = [], selected, identifier = 'key') {
        this.options = options
        this.identifier = identifier
        makeObservable(this, {
            selected: observable,
            options: observable,

            setSelectedItem: action,
            setOptionById: action,
            setOptions: action,
            reset: action,
        })
        this.setSelectedItem(selected)
    }

    selected = null
    options = []

    setSelectedItem = (value = null, withoutReset) => {
        // if (
        //     !withoutReset &&
        //     this.selected &&
        //     this.selected[this.identifier] === value[this.identifier]
        // ) {
        //     this.selected = null
        //     return
        // }
        this.selected = value
    }

    setOptions = (array = []) => {
        this.options = array
    }

    addOptions = (array = []) => {
        this.options.push(...array)
    }

    setOptionById = (id) => {
        this.selected =
            this.options.find((item) => item[this.identifier] === id) ||
            this.options[0]
    }

    reset = () => {
        this.selected = null
    }
}
