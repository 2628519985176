import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/outline'
import cn from 'classnames'

const ChevTrigger = ({
    className,
    classNameValue,
    classNameChev,
    opened,
    value,
}) => {
    return (
        <span className={cn(className, 'flex cursor-pointer items-center')}>
            <span className={cn(classNameValue, `truncate text-left`)}>
                {value}
            </span>
            {opened ? (
                <ChevronUpIcon
                    className={cn(
                        classNameChev,
                        'ml-1 h-4 w-4 flex-shrink-0 text-gray-500'
                    )}
                />
            ) : (
                <ChevronDownIcon
                    className={cn(
                        classNameChev,
                        'ml-1 h-4 w-4 flex-shrink-0 text-blue-500'
                    )}
                />
            )}
        </span>
    )
}

export {ChevTrigger}
