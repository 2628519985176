import AuthService from '@services/AuthService/AuthService'
import ProfileService from '@services/ProfileService/ProfileService'
import {getErrorFields, keysToCamel} from '@utils/helpers'
import {ProvidersFieldStore} from '@views/account/Account/Settings/SettingsTabs/Profile/ProvidersField/store/ProvidersFieldStore'
import {action, makeObservable, observable} from 'mobx'
import {toast} from 'react-toastify'

class ProfileStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        this.providersFieldStore = new ProvidersFieldStore(rootStore)
        makeObservable(this, {
            settingsTabs: observable,
            restoreRequestSuccess: observable,
            inProcess: observable,
            changedEmail: observable,
            changedPhone: observable,
            confirmPhoneError: observable,

            profile: observable,
            profileAvatar: observable,
            profileAvatarLoader: observable,
            profileLoader: observable,
            userUuid: observable,
            errors: observable,
            setProfile: action,
            setProfileAvatarLoader: action,
            setProfileAvatar: action,
            setProfileLoader: action,
            updateProfile: action,
            addValueProfile: action,
            setUserUuid: action,
            setChangedEmail: action,
            setChangedPhone: action,
            setConfirmPhoneError: action,
            setErrors: action,
            addErrors: action,

            setRestoreRequestState: action,
            setInProcess: action,
        })
    }

    settingsTabs = [
        {
            id: 'profile',
            name: 'Профиль',
            active: true,
            visible: true,
        },
        {
            id: 'password',
            name: 'Пароль',
            active: false,
            visible: true,
        },
    ]
    restoreRequestSuccess = false
    inProcess = false

    profile = {}
    profileAvatar = ''
    errors = {message: '', fields: {}}
    profileLoader = false
    changedEmail = false
    changedPhone = false
    confirmPhoneError = false
    profileAvatarLoader = false
    userUuid = ''

    setRestoreRequestState = (value = false) => {
        this.restoreRequestSuccess = value
    }

    setInProcess = (value = false) => {
        this.inProcess = value
    }

    setConfirmPhoneError = (value = '') => {
        this.confirmPhoneError = value
    }

    setChangedEmail = (value = false) => {
        this.changedEmail = value
    }

    setChangedPhone = (value = false) => {
        this.changedPhone = value
    }

    setProfileAvatarLoader = (value = false) => {
        this.profileAvatarLoader = value
    }

    setProfile = (value = {}) => {
        this.profile = value
    }

    setProfileLoader = (value = false) => {
        this.profileLoader = value
    }

    updateProfile = (value = {}) => {
        this.profile = Object.assign({}, this.profile, value)
    }

    addValueProfile = (key, value) => {
        this.profile[key] = value
    }

    setUserUuid = (value = '') => {
        this.userUuid = value
    }

    setProfileAvatar = (value = '') => {
        this.profileAvatar = value
    }

    setErrors = (value = {message: '', fields: {}}) => {
        this.errors = value
    }
    addErrors = (key, value) => {
        this.errors[key] = value
    }

    sendInitRestoreProcess = async (payload) => {
        try {
            this.setInProcess(true)
            const resp =
                await this.rootStore.authStore.restorePasswordByEmail(payload)
            this.setRestoreRequestState(true)
        } catch (e) {
            this.setRestoreRequestState(false)
        } finally {
            this.setInProcess(false)
        }
    }

    getProfile = async () => {
        this.setProfileLoader(true)
        try {
            const {data} = await ProfileService.getProfile()
            this.setProfile(data)
            this.providersFieldStore.matchingLinkProviders(data.providers)
        } catch (e) {
            this.setProfile(keysToCamel(this.rootStore.userStore.userData))
            console.log(e)
        } finally {
            this.setProfileLoader(false)
        }
    }

    postProfile = async (payload, onSuccess = () => {}) => {
        this.setErrors()
        try {
            const {data} = await ProfileService.postProfile(payload)
            if (data.result) {
                const {isChangeEmail, isChangePhone, userUuid} = data
                this.setUserUuid(userUuid)
                toast('Данные успешно сохранены', {type: 'success'})

                await this.rootStore.authStore.sendRefreshToken()
                await this.getProfile()
                if (isChangeEmail) {
                    this.setChangedEmail(isChangeEmail)
                }
                if (isChangePhone) this.setChangedPhone(isChangePhone)
                onSuccess(data)
            }
        } catch (e) {
            toast(e.response.data.message, {type: 'error'})

            if (e.response.data.result === false) {
                const errors = {
                    message: e.response.data.message,
                    fields: getErrorFields(e.response.data.formError),
                }
                return this.setErrors(errors)
            }
            this.addErrors(
                'message',
                'Что-то пошло не так... Попробуйте снова или обратитесь в поддержку'
            )
        }
    }

    postAvatar = async (file) => {
        const fileObj = {
            name: 'image',
            file,
        }
        try {
            const {data} = await ProfileService.postAvatar(fileObj)
            if (data.result) {
                this.getAvatar()
            }
        } catch (e) {
            console.log(e)
        }
    }

    getAvatar = async () => {
        this.setProfileAvatarLoader(true)
        try {
            const {data} = await ProfileService.getAvatar()
            const image = URL.createObjectURL(data)
            this.setProfileAvatar(image)
        } catch (e) {
            console.log(e)
        } finally {
            this.setProfileAvatarLoader(false)
        }
    }

    postConfirmChangePhone = async () => {
        this.setConfirmPhoneError('')
        const payload = {
            user_uuid:
                this.userUuid || this.rootStore.userStore.userData.user_uuid,
        }
        try {
            const {data} = await ProfileService.postConfirmChangePhone(payload)
            if (data.result) {
                this.setUserUuid(data.userUuid)
            }
        } catch (e) {
            console.log(e)
            this.setConfirmPhoneError(e.response.data.message)
        }
    }

    getConfirmChangePhone = async (code, userUuid, onSuccess) => {
        this.setConfirmPhoneError('')
        const payload = {
            user_uuid:
                userUuid ||
                this.userUuid ||
                this.rootStore.userStore.userData.user_uuid,
            sms_code: code,
        }
        try {
            const {data} = await ProfileService.getConfirmChangePhone(payload)
            if (data.result) {
                onSuccess(data.result)
                this.setUserUuid(data.userUuid)
                await this.rootStore.authStore.sendRefreshToken()
                await this.getProfile()
            }
        } catch (e) {
            console.log(e)
            this.setConfirmPhoneError(e.response.data.message)
        }
    }

    getConfirmChangeEmail = async (token, onSuccess) => {
        try {
            const {data} = await ProfileService.getConfirmChangeEmail(token)
            if (data.result) {
                await this.rootStore.authStore.sendRefreshToken()
                onSuccess(data)
                toast(data.message, {type: 'success'})
            }
        } catch (e) {
            console.log(e)
            toast(e.response.data.message, {type: 'error'})
        }
    }

    postConfirmEmailRepeat = async (email, onSuccess) => {
        const payload = {email}
        try {
            const {data} = await ProfileService.postConfirmEmailRepeat(payload)
            if (data.result) {
                this.setChangedEmail(true)
            }
        } catch (e) {
            console.log(e)
            toast(e.response.data.message, {type: 'error'})
        }
    }

    postConfirmSMSCode = async (phone) => {
        this.setConfirmPhoneError()
        const payload = {
            phone_number: phone,
        }
        try {
            const {data} = await AuthService.postConfirmSms(payload)
            if (data.result) {
                this.setUserUuid(data.userUuid)
            }
        } catch (e) {
            console.log(e)
            this.setConfirmPhoneError(
                e.response?.data?.message ||
                    'Что-то пошло не так... Попробуйте снова или обратитесь в поддержку'
            )
        }
    }

    getConfirmSMSCode = async (code, userUuid, onSuccess) => {
        this.setConfirmPhoneError()
        const payload = {
            sms_code: code,
            user_uuid: userUuid || this.userUuid,
        }

        try {
            const {data} = await AuthService.getConfirmSms(payload)
            if (data.result) {
                onSuccess(data.result)
                await this.rootStore.authStore.appendToken(data, true)
            }
        } catch (e) {
            this.setConfirmPhoneError(e.response.data.message)
        }
    }
}

export {ProfileStore}
