import {TooltipLite} from '@components/elements/TooltipLite'
import {QuestionMarkCircleIcon} from '@heroicons/react/outline'
import classNames from 'classnames'

export const SettingsHelp = ({content, size, className}) => {
    return (
        <TooltipLite
            offset={[0, 10]}
            content={content}
            classNameBody={classNames(className, 'md:p-2 max-w-[250px]')}
        >
            {(ref) => (
                <span ref={ref}>
                    <QuestionMarkCircleIcon
                        className={classNames(
                            'cursor-pointer text-gray-500 dark:text-gray-400',
                            size,
                            {
                                'h-6 w-6': !size,
                            }
                        )}
                    />
                </span>
            )}
        </TooltipLite>
    )
}
