import {MarketIcon} from '@components/elements'
import {Loader} from '@components/elements/Loader'
import {marketplaceNameById} from '@utils/constants'

export const NewImports = ({credentials}) => {
    return (
        <div className={'flex flex-col space-y-4'}>
            <div className={'flex items-center space-x-4'}>
                <Loader sm overlayed={false} />
                <span>
                    Запущен импорт данных. Для корректного отображения отчетов
                    необходимо дождаться завершения импорта.
                </span>
            </div>
            {credentials.map((credential) => {
                return (
                    <div key={credential.id} className={'flex space-x-4'}>
                        <div className={'flex items-center space-x-4'}>
                            <MarketIcon
                                iconClassName={'w-4x5 h-4x5 '}
                                size={20}
                                name={
                                    marketplaceNameById[
                                        credential.marketplaceId
                                    ]
                                }
                                className={'flex-shrink-0'}
                            />
                            <span className={'truncate'}>
                                {credential.name} :
                            </span>
                        </div>
                        <span>идет первоначальный импорт</span>
                    </div>
                )
            })}
        </div>
    )
}
