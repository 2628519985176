import {SortModeDropdown} from '@components/applicationUi/Lists/Table/SortModeDropdown.jsx'
import {
    AscIcon,
    ChevronUpDownIcon,
    DescIcon,
} from '@components/elements/Icon/index.js'
import {LockedWrapperWithoutIcon} from '@components/elements/index.js'
import classNames from 'classnames'

export const SortIndicators = ({
    column,
    onChangeMode,
    sortBy,
    isBlocked,
    isTrial,
    exceedProducts,
    exceedCabinets,
    changeSortBy,
}) => {
    const sortedItem = sortBy && sortBy.find((item) => item.id === column.id)

    const getIconClassName = (isActive) => {
        return classNames(
            'h-5 w-5 flex-shrink-0 cursor-pointer transition-all',
            {
                'text-blue-500 dark:text-blue-400': isActive,
                'text-gray-300 dark:text-gray-600': !isActive,
            }
        )
    }

    const renderWrapper = (children, isHasDropdown) => {
        const className = 'ml-1 flex'

        if (!isHasDropdown)
            return (
                <div className={className} onClick={onChangeMode}>
                    {children}
                </div>
            )
        return (
            <SortModeDropdown
                sortedItem={sortedItem}
                onChangeMode={(mode, dir) => {
                    onChangeMode()
                    changeSortBy(column.id, {
                        ...sortedItem,
                        dir,
                        currentMode: mode,
                    })
                }}
                className={className}
            >
                {children}
            </SortModeDropdown>
        )
    }

    const renderSortDir = () => {
        const wrap = (element) => (
            <div
                className={classNames(getIconClassName(sortedItem.active), {
                    'translate-x-[-5px]':
                        sortedItem.active && !!sortedItem.currentMode,
                })}
            >
                {element}
            </div>
        )
        if (
            sortedItem.sortModes &&
            (!sortedItem.currentMode || sortedItem.currentMode === 'default')
        )
            return wrap(<ChevronUpDownIcon />)
        if (sortedItem.dir === 'asc') return wrap(<AscIcon />)
        return wrap(<DescIcon />)
    }

    const renderSortMode = () => {
        if (!sortedItem.sortModes) return null
        if (sortedItem.currentMode && sortedItem.currentMode !== 'default') {
            const currentSortModeInfo =
                sortedItem.currentMode === 'default'
                    ? sortedItem.key
                    : sortedItem.sortModes.find(
                          (mode) => mode.key === sortedItem.currentMode
                      )
            if (currentSortModeInfo) {
                const Icon = currentSortModeInfo.config.icon
                return (
                    <Icon
                        className={classNames(
                            getIconClassName(sortedItem.active),
                            'fill-current'
                        )}
                    />
                )
            }
        }
        return null
    }

    if (!sortedItem || !sortedItem.visible) return null
    return (
        <LockedWrapperWithoutIcon
            isBlocked={isBlocked && sortedItem.blockedTooltip}
            isTrial={isTrial && sortedItem.blockedTooltip}
            exceedProducts={exceedProducts && sortedItem.blockedTooltip}
            exceedCabinets={exceedCabinets && sortedItem.blockedTooltip}
        >
            {renderWrapper(
                <>
                    {renderSortMode()}
                    {renderSortDir()}
                </>,
                !!sortedItem.sortModes
            )}
        </LockedWrapperWithoutIcon>
    )
}
