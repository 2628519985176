import {MarketUrl} from '@routesEnums'
import {baseConfig} from '@services/apiConfigs'
import {ApiFactory} from '@services/ApiFactory'

class MarketService {
    static instance = ApiFactory.createApi(baseConfig)
    static postLoginOzon = async (payload, id) => {
        return await MarketService.instance.post(
            id ? MarketUrl.editOzonId(id) : MarketUrl.loginOzon,
            payload
        )
    }
    static postLoginWb = async (payload, id) => {
        return await MarketService.instance.post(
            id ? MarketUrl.editWbId(id) : MarketUrl.loginWb,
            payload
        )
    }
    static postSsoYandex = async () => {
        return await MarketService.instance.post(MarketUrl.ssoYandex)
    }
    static getCheckSsoYandex = async (query) => {
        return await MarketService.instance.get(MarketUrl.checkSsoYandex, query)
    }
    static postCampaignsYandex = async (payload) => {
        return await MarketService.instance.post(
            MarketUrl.getCampaignsYandex,
            payload
        )
    }
    static postLoginYandex = async (payload, id) => {
        return await MarketService.instance.post(
            id ? MarketUrl.editYandexId(id) : MarketUrl.loginYandex,
            payload
        )
    }
    static getCredentialList = async () => {
        return await MarketService.instance.get(MarketUrl.credentialList)
    }
    static deleteCredentialPim = async (query) => {
        const config = {
            params: query,
        }
        return await MarketService.instance.delete(
            MarketUrl.deleteCredential,
            undefined,
            config
        )
    }
    static deleteUnsubscribe = async (id) => {
        return await MarketService.instance.delete(
            MarketUrl.deleteUnsubscribe(id)
        )
    }
    static getCredentialStatuses = async (query) => {
        return await MarketService.instance.get(
            MarketUrl.credentialsStatuses,
            query
        )
    }

    static deleteUnsubscribeByIds = async (query) => {
        const config = {
            params: query,
        }
        return await MarketService.instance.delete(
            MarketUrl.deleteUnsubscribes,
            undefined,
            config
        )
    }
}

export default MarketService
