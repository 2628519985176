import {phoneRegexes, regexes} from '@utils/regexes'

const ERROR_MAPPER = {
    'Invalid user credentials': 'Вы ввели неверный пароль',
    'User exists with same username':
        'Пользователь с таким же Email уже существует',
    'Request failed with status code 401': 'Некорректный логин/пароль',
}

export const getError = (error) => ERROR_MAPPER[error]

export const getEmailPhoneField = (emailPhone) => {
    const field = {
        email: '',
        phone: '',
        isValid: false,
    }
    if (regexes.email.test(emailPhone)) {
        field.email = emailPhone
        field.isValid = true
    } else if (phoneRegexes.startPhone.test(emailPhone)) {
        const formatPhone = emailPhone.replace(phoneRegexes.startPhone, '+7')
        if (
            phoneRegexes.russia.test(formatPhone) ||
            phoneRegexes.kazakhstan.test(formatPhone)
        ) {
            field.phone = formatPhone
            field.isValid = true
        }
    } else if (phoneRegexes.startPhoneBy.test(emailPhone)) {
        const formatPhone = emailPhone.replace(
            phoneRegexes.startPhoneBy,
            '+375'
        )
        if (phoneRegexes.belarus.test(formatPhone)) {
            field.phone = formatPhone
            field.isValid = true
        }
    }

    return field
}

export const getErrorAuthFields = (formError = {}) => {
    const errors = {}
    Object.entries(formError).forEach(([key, value]) => {
        if (key === 'email' || key === 'phoneNumber') {
            errors['emailPhone'] = value.join(', ')
        } else {
            errors[key] = value.join(', ')
        }
    })

    return errors
}
