export function registerServiceWorker() {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker
                .register(`/sw.js`)
                .then((registration) => {
                    console.log('Service Worker зарегистрирован')

                    // Проверяем, есть ли новый SW в состоянии "waiting"
                    if (registration.waiting) {
                        registration.waiting.postMessage({type: 'SKIP_WAITING'})
                    }

                    // Если SW обновляется, ждем, пока он установится
                    registration.addEventListener('updatefound', () => {
                        const newSW = registration.installing
                        if (newSW) {
                            newSW.addEventListener('statechange', () => {
                                if (
                                    newSW.state === 'installed' &&
                                    navigator.serviceWorker.controller
                                ) {
                                    newSW.postMessage({type: 'SKIP_WAITING'})
                                }
                            })
                        }
                    })
                })
                .catch((error) =>
                    console.log('Ошибка при регистрации SW:', error)
                )
        })
    }
}
