import {createContext, useContext, useMemo} from 'react'

export const TrialBlockedContext = createContext()

export const useTrialContext = () => {
    const userStore = useContext(TrialBlockedContext)

    return useMemo(() => {
        if (userStore.isEmulation) return false
        return userStore.tariff?.slug === 'trial'
    }, [userStore.tariff, userStore.isEmulation])
}
