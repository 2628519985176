import {useSelector} from '@hooks/mobxStoreHooks/useSelector'
import FeedbackService from '@services/FeedbackService/FeedbackService'
import {observer} from 'mobx-react'
import {useEffect, useState} from 'react'
import {CompletionPage} from './CompletionPage'
import {IndicatePhoneNumber} from './IndicatePhoneNumber'

export const EnrollPage = observer(({onClose}) => {
    const userStore = useSelector((store) => store.userStore)
    const [isIndicatePhoneNumber, setIsIndicatePhoneNumber] = useState(
        !userStore.userData.phone_number
    )
    useEffect(() => {
        if (userStore.userData.phone_number) {
            fetchEnroll()
        }
    }, [])
    const fetchEnroll = async () => {
        const payload = {
            to: 'support@seller24.ru',
            request_type: 'request_audit',
            title: `Запрос аудита`,
            fields: [
                {
                    title: 'name',
                    value: userStore.userData.name || '',
                },
                {
                    title: 'phone',
                    value: userStore.userData.phone_number?.replace(
                        /[ \-()]/g,
                        ''
                    ),
                },
                {
                    title: 'email',
                    value: userStore.userData?.email || '',
                },
            ],
        }
        await FeedbackService.postMessage(payload)
    }
    return isIndicatePhoneNumber ? (
        <IndicatePhoneNumber
            setIsIndicatePhoneNumber={setIsIndicatePhoneNumber}
        />
    ) : (
        <CompletionPage onClose={onClose} />
    )
})

EnrollPage.displayName = 'EnrollPage'
