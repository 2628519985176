import {Button, MarketIcon, TooltipLite} from '@components/elements'
import {Card} from '@components/layout'
import {Modal} from '@components/overlays'
import {XIcon} from '@heroicons/react/outline'
import {useResponsive} from '@hooks'
import {useAnalyticEventsHook} from '@hooks/analyticEventsHook'
import {useSelector} from '@hooks/mobxStoreHooks/useSelector'
import {AppPath} from '@routesEnums'
import {CONNECTED_MP_ACTIONS} from '@utils/constants'
import GoogleTagManager from '@utils/googleUtils/googleTagManager'
import {AvailableTitle} from '@views/marketplaces/pages/Marketplaces/components/Available/AvailableTitle'
import {MpConnectedDialog} from '@views/marketplaces/pages/Marketplaces/components/Available/MpConnectedDialog'
import {Partnership} from '@views/marketplaces/pages/Marketplaces/components/Available/Partnership'
import {useState} from 'react'
import {useHistory} from 'react-router-dom'

export const AvailableCard = ({
    marketName,
    label,
    connectorsLabel,
    disabled,
    connector,
    tooltip,
    connectionAllowed = true,
}) => {
    const {sm} = useResponsive()
    const history = useHistory()
    const {connectedMpEvent} = useAnalyticEventsHook()
    const {userEmail, userData} = useSelector((store) => store.userStore)
    const {getList} = useSelector((store) => store.marketStore)

    const [connectorOpen, setConnectorOpen] = useState(false)
    const [success, setSuccess] = useState(false)

    const onCancel = () => {
        setConnectorOpen(false)
        setSuccess(false)
    }

    const {sendRefreshToken} = useSelector((store) => store.authStore)

    const onSuccess = async (id, credential) => {
        if (!id) {
            GoogleTagManager.dataLayer({
                event: 'addMarket',
                marketplace: marketName,
                email: userEmail,
                user_id: userData.user_id,
            })
        }
        setSuccess(true)
        await sendRefreshToken()
        await getList()
    }

    const onError = () => {
        GoogleTagManager.dataLayer({
            event: 'mainEvent',
            eventCategory: 'Message - Front',
            eventAction: 'message',
            eventLabel: marketName,
            email: userEmail,
            user_id: userData.user_id,
        })
    }

    const handleClickConnected = () => {
        connectedMpEvent(CONNECTED_MP_ACTIONS.open, `MP_form${marketName}`)
        setConnectorOpen(true)
    }

    return (
        <Card
            innerClassName={
                'flex sm:flex-col items-center justify-between overflow-hidden'
            }
        >
            <div className={'flex items-center overflow-hidden sm:flex-col'}>
                <MarketIcon
                    name={marketName}
                    size={sm ? 80 : 30}
                    className={'mr-3 sm:mb-6 sm:mr-0'}
                />
                {tooltip ? (
                    <TooltipLite
                        offset={[0, 10]}
                        content={tooltip}
                        classNameBody={'md:p-2 max-w-[250px]'}
                    >
                        {(ref) => (
                            <AvailableTitle
                                className={'cursor-pointer'}
                                ref={ref}
                                title={label}
                            />
                        )}
                    </TooltipLite>
                ) : (
                    <AvailableTitle title={label} />
                )}
            </div>
            {connectionAllowed ? (
                <Button
                    size={sm ? 'base' : 'sm'}
                    disabled={disabled}
                    onClick={handleClickConnected}
                >
                    {connectorsLabel ? connectorsLabel : 'Подключить'}
                </Button>
            ) : (
                <Button
                    color={'white'}
                    size={sm ? 'base' : 'sm'}
                    onClick={() => history.push(AppPath.accountTariff)}
                >
                    Лимит тарифа исчерпан
                </Button>
            )}
            {!!connector && (
                <Modal
                    open={connectorOpen}
                    onClose={onCancel}
                    className={'sm:max-w-md'}
                >
                    {!success ? (
                        <div className={'flex flex-col items-center'}>
                            <div
                                className={
                                    'mb-6 flex w-full items-center justify-between border-b border-gray-200 pb-4 dark:border-gray-600'
                                }
                            >
                                {tooltip ? (
                                    <TooltipLite
                                        offset={[0, 10]}
                                        content={tooltip}
                                        classNameBody={'md:p-2 max-w-[250px]'}
                                    >
                                        {(ref) => (
                                            <AvailableTitle
                                                className={'cursor-pointer'}
                                                ref={ref}
                                                title={
                                                    <div
                                                        className={
                                                            'flex space-x-2'
                                                        }
                                                    >
                                                        <span>Вход в</span>
                                                        <span>{label}</span>
                                                    </div>
                                                }
                                            />
                                        )}
                                    </TooltipLite>
                                ) : (
                                    <AvailableTitle
                                        title={
                                            <div className={'flex space-x-2'}>
                                                <span>Вход в</span>
                                                <span>{label}</span>
                                            </div>
                                        }
                                    />
                                )}
                                <XIcon
                                    className={
                                        'h-6 w-6 cursor-pointer text-gray-600 dark:text-gray-400'
                                    }
                                    onClick={onCancel}
                                />
                            </div>
                            <MarketIcon
                                name={marketName}
                                size={100}
                                className={'mb-6'}
                            />
                            <Partnership marketName={marketName} />
                            {connector(onCancel, onSuccess, onError)}
                        </div>
                    ) : (
                        <MpConnectedDialog onClose={onCancel} />
                    )}
                </Modal>
            )}
        </Card>
    )
}
