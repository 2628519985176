import ym from 'react-yandex-metrika'

class YandexAnalytics {
    static checkIsProd = () => {
        if (import.meta.env.MODE === 'production') {
            const parts = window.location.host.split('.')
            if (parts[0] === 'app') return true
            // if (parts[0] === 'dev') {
            //     YandexAnalytics.gtmId = import.meta.env.VITE_APP_GTM_DEMO_KEY
            //     return true
            // }
        }
        return false
    }

    static ym = (method, args = []) => {
        if (YandexAnalytics.checkIsProd()) {
            ym(method, ...args)
        }
    }
}

export default YandexAnalytics
