import {Button} from '@components/elements/Buttons'
import {Loader} from '@components/elements/Loader'
import {SearchIcon} from '@heroicons/react/outline'
import {useFetch} from '@hooks'
import classNames from 'classnames'

export const EmptyDataStub = ({
    className,
    mountMethodAsync = async () => {},
    mountMethod = () => {},
    onClick,
    title = 'Данных для формирования отчета недостаточно',
    description = 'Выберите другие условия для фильтра',
    buttonLabel = 'Отправить обращение в поддержку',
    icon,
    img,
}) => {
    const {loading} = useFetch(mountMethodAsync, mountMethod)

    return (
        <div
            className={classNames(
                className,
                'flex w-full flex-col py-8 lg:flex-row lg:items-center'
            )}
        >
            <div className={'flex-1'}>
                <div className={'mb-8 flex w-full justify-center'}>
                    {icon || (
                        <SearchIcon
                            className={
                                'h-full max-h-[300px] w-1/2 max-w-[300px] text-gray-300 dark:text-gray-500'
                            }
                        />
                    )}
                </div>
                <div
                    className={
                        'flex flex-col items-center space-y-4 text-center'
                    }
                >
                    <p className={'text-2xl font-semibold dark:text-gray-100'}>
                        {title}
                    </p>
                    <p className={'text-md text-gray-600 dark:text-gray-400'}>
                        {description}
                    </p>
                    {onClick && typeof onClick === 'function' ? (
                        <Button disabled={loading} onClick={onClick}>
                            {loading ? (
                                <>
                                    <Loader
                                        sm
                                        overlayed={false}
                                        className={'mr-2'}
                                    />
                                    Идёт обновление данных
                                </>
                            ) : (
                                buttonLabel
                            )}
                        </Button>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            {img && (
                <div className={'flex-1 px-8 py-4'}>
                    <img
                        className={
                            'h-full w-full object-contain object-right-top'
                        }
                        src={img}
                        alt={''}
                    />
                </div>
            )}
        </div>
    )
}
