import {EmptyOrdersIcon} from '@components/elements/Icon/Icon'
import {observer} from 'mobx-react'

export const EmptyTableResult = observer(({icon, label}) => {
    return (
        <div className={'flex h-full w-full flex-col justify-center pb-16'}>
            <div className={'mb-12 flex w-full items-center justify-center'}>
                {icon ? icon : <EmptyOrdersIcon />}
            </div>
            <div
                className={
                    'mb-10 flex flex-col items-center text-gray-600 dark:text-gray-400'
                }
            >
                {label ? label : 'Список пуст'}
            </div>
        </div>
    )
})
