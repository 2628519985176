import {FileDropper} from '@components/elements'
import {FilesImport} from '@components/modules/File/FilesImport'
import {ModalCaller, ModalConfirm} from '@components/overlays'
import {STATUSES_FILE_MAP} from '@utils/constants'
import {maxSizeFile} from '@views/marketplaces/constants'
import {RejectedFiles} from '@views/marketplaces/pages/ImportWb/components/ImportWbOverlay/components/RejectedFiles'
import {observer} from 'mobx-react'
import {useMemo, useState} from 'react'
import {toast} from 'react-toastify'

export const ImportWbFileUpload = observer(
    ({uploadFileStore, extension = 'xlsx', accept}) => {
        const [rejectedFiles, setRejectedFiles] = useState([])
        const handleLoad = (files) => {
            for (let i = 0; i < files.length; i++) {
                let newFile = files[i]
                uploadFileStore.addFileToImport(newFile, () => {
                    toast(
                        `Файл "${
                            newFile.name
                        }" не в формате ${extension.toUpperCase()}!`,
                        {
                            type: 'error',
                        }
                    )
                })
            }
        }

        const onUpload = async () => {
            await uploadFileStore.postFile(extension)
        }

        const handleDeleteFile = (file, idx) => () => {
            if (file === undefined) {
                uploadFileStore.clearFiles()
            } else {
                uploadFileStore.deleteFile(idx)
            }
            if (!uploadFileStore.files.length) {
                uploadFileStore.setStatuses(STATUSES_FILE_MAP.fileDrop)
            }
        }

        const confirmDelete = (file, idx) => () => {
            new ModalCaller({
                bodyClass: 'pb-0 mb-0',
                children: (
                    <ModalConfirm
                        isModal={false}
                        title={`Вы действительно хотите удалить файл?`}
                        description={'Это действие необратимо!'}
                        onAccept={handleDeleteFile(file, idx)}
                    />
                ),
            })
        }
        const renderDropFileStep = useMemo(() => {
            switch (uploadFileStore.statuses) {
                case STATUSES_FILE_MAP.fileAdded: {
                    return (
                        <FilesImport
                            files={uploadFileStore.files}
                            onUpload={onUpload}
                            onDelete={confirmDelete}
                        />
                    )
                }
                default: {
                    return (
                        <div className={'mb-10 max-w-[730px]'}>
                            <FileDropper
                                dropzoneText={`Перетащите сюда заполненный ${extension.toUpperCase()} файл`}
                                acceptedFiles={accept}
                                maxSize={maxSizeFile}
                                onChange={handleLoad}
                                setRejectedFiles={setRejectedFiles}
                            />
                            {rejectedFiles.length ? (
                                <RejectedFiles
                                    rejectedFiles={rejectedFiles}
                                    onClear={() => setRejectedFiles([])}
                                />
                            ) : null}
                        </div>
                    )
                }
            }
        }, [uploadFileStore.statuses, rejectedFiles])
        return (
            <div className={'flex flex-col space-y-4'}>
                {renderDropFileStep}
            </div>
        )
    }
)

ImportWbFileUpload.displayName = 'ImportWbFileUpload'
