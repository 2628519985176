import {Button} from '@components/elements'
import {TextField, TextFieldMask} from '@components/forms/TextFields'
import {ModalConfirm} from '@components/overlays'
import {yupResolver} from '@hookform/resolvers/yup'
import {regexes} from '@utils/regexes'
import {useAccountStore} from '@views/account/Account/hooks/useAccountStore'
import {ConfirmCode} from '@views/auth/components/ConfirmCode'
import {observer} from 'mobx-react'
import {useMemo, useRef, useState} from 'react'
import {useForm} from 'react-hook-form'
import * as yup from 'yup'

export const FillingCredsDialog = observer(({onClose}) => {
    const [phone, setPhone] = useState('')
    const formRef = useRef(null)
    const {userStore, profileStore} = useAccountStore()
    const {
        errors,
        setErrors,
        userUuid,
        confirmPhoneError,
        changedEmail,
        changedPhone,
        setChangedPhone,
        setChangedEmail,
        postProfile,
        postConfirmChangePhone,
        getConfirmChangePhone,
    } = profileStore

    const schema = useMemo(() => {
        const newSchema = {}
        if (!userStore.userData.email) {
            newSchema.email = yup
                .string()
                .matches(regexes.email, 'Неверный формат Email')
                .required('Email не должен быть пустым')
        }
        if (!userStore.userData.phone_number) {
            newSchema.phoneNumber = yup
                .string()
                .matches(regexes.telRu, 'Неверный формат номера телефона')
                .required('Номер телефона не должен быть пустым')
        }
        return yup.object().shape(newSchema)
    }, [])

    const {
        clearErrors,
        register,
        handleSubmit,
        formState: {errors: formErrors},
    } = useForm({
        resolver: yupResolver(schema),
    })

    const handleClickConfirmAction = () => {
        formRef.current?.click()
    }

    const onSubmit = ({email, phoneNumber}) => {
        const payload = {
            phone_number: phoneNumber?.replace(/[ \-()]/g, ''),
            email,
        }
        clearErrors()
        setErrors()
        postProfile(payload)
    }

    const {onChange: onChangePhoneNumber, ...phoneNumberRegister} =
        register('phoneNumber')

    const handleChangePhoneNumber = (e) => {
        setErrors()
        onChangePhoneNumber(e)
        setPhone(e.target.value)
    }

    const handleConfirmSuccess = () => {
        setChangedPhone(false)
    }

    const handlerClickToBack = () => {
        setChangedPhone(false)
    }

    return (
        <>
            <div className={'flex flex-col'}>
                {changedPhone ||
                (!userStore.userData.phone_number &&
                    userStore.userData.new_phone_number) ? (
                    <ConfirmCode
                        phone={phone || userStore.userData.new_phone_number}
                        userUuid={userUuid || userStore.userData.user_uuid}
                        error={confirmPhoneError}
                        onCheckSMSCode={getConfirmChangePhone}
                        onSendSMSCode={postConfirmChangePhone}
                        onClickToBack={handlerClickToBack}
                        onConfirmSuccess={handleConfirmSuccess}
                    />
                ) : (
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className={'space-y-2'}
                    >
                        {!userStore.userData.email ? (
                            <TextField
                                {...register('email')}
                                label={'Email'}
                                type='email'
                                autoComplete='email'
                                error={
                                    !!formErrors.email || !!errors.fields?.email
                                }
                                helperText={
                                    formErrors?.email?.message ||
                                    errors?.fields?.email
                                }
                            />
                        ) : null}

                        {!userStore.userData.phone_number ? (
                            <TextFieldMask
                                {...phoneNumberRegister}
                                mask={'+7 999 999-99-99'}
                                label={'Номер телефона'}
                                autoComplete='phoneNumber'
                                onChange={handleChangePhoneNumber}
                                error={
                                    !!formErrors.phoneNumber ||
                                    !!errors.fields?.phoneNumber
                                }
                                helperText={
                                    formErrors?.phoneNumber?.message ||
                                    errors?.fields?.phoneNumber
                                }
                            />
                        ) : null}
                        <input
                            ref={formRef}
                            className={'hidden'}
                            type={'submit'}
                        />
                    </form>
                )}
                <div
                    className={
                        'mt-5 flex w-full items-center border-t border-gray-300 px-4 pt-4 dark:border-gray-600'
                    }
                >
                    <Button
                        className={'mr-4 w-full'}
                        color={'white'}
                        onClick={() => onClose()}
                    >
                        Отменить
                    </Button>
                    <Button
                        className={'w-full'}
                        disabled={changedPhone}
                        onClick={handleClickConfirmAction}
                    >
                        Подтвердить
                    </Button>
                </div>
            </div>
            <ModalConfirm
                type={'info'}
                open={changedEmail}
                title={`Для успешного сохранения Email необходимо подтвердить почту`}
                description={
                    'Мы отправили вам письмо с ссылкой подтверждения Email. Перейдите по ссылке для завершения.'
                }
                onClose={() => setChangedEmail(false)}
                onAccept={() => setChangedEmail(false)}
            />
        </>
    )
})
