import {NotificationsUrl} from '@routesEnums'
import {baseConfig} from '@services/apiConfigs'
import {ApiFactory} from '@services/ApiFactory'

export class NotificationsService {
    static instance = ApiFactory.createApi(baseConfig)
    static getNotifications = async (payload) => {
        return await NotificationsService.instance.get(
            NotificationsUrl.notifications,
            payload
        )
    }

    static patchNotification = async (payload) => {
        const config = {
            params: payload,
        }
        return await NotificationsService.instance.patch(
            NotificationsUrl.notifications,
            undefined,
            config
        )
    }

    static postNotification = async (payload) => {
        return await NotificationsService.instance.post(
            NotificationsUrl.notifications,
            payload
        )
    }

    static postNotificationSelf = async (payload) => {
        return await NotificationsService.instance.post(
            NotificationsUrl.notificationsSelf,
            payload
        )
    }

    static patchNotificationView = async (payload) => {
        return await NotificationsService.instance.patch(
            NotificationsUrl.notificationView,
            payload
        )
    }

    static deleteNotification = async (payload) => {
        const config = {
            params: payload,
        }
        return await NotificationsService.instance.delete(
            NotificationsUrl.notifications,
            undefined,
            config
        )
    }

    static getAdminNotifyList = async (query) => {
        return await NotificationsService.instance.get(
            NotificationsUrl.notificationsAdmin,
            query
        )
    }

    static deleteAdminNotify = async (id) => {
        return await NotificationsService.instance.delete(
            NotificationsUrl.notifyAdminId(id)
        )
    }
}
