import {Switch} from '@headlessui/react'
import cn from 'classnames'

const Toggle = ({checked, onChange, className, disabled, togglerClassName}) => {
    return (
        <Switch
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            className={cn(
                checked
                    ? 'bg-blue-500 dark:bg-blue-400'
                    : 'bg-gray-200 dark:bg-gray-900',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
                className
            )}
        >
            <span
                aria-hidden='true'
                className={cn(
                    checked ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    togglerClassName
                )}
            />
        </Switch>
    )
}

export {Toggle}
