import {Switch} from '@headlessui/react'

const Labeled = ({label, description}) => {
    if (!label && !description) return null
    return (
        <span className='flex flex-grow flex-col'>
            {!!label && (
                <Switch.Label
                    as='span'
                    className='text-sm font-medium text-gray-900 dark:text-gray-100'
                    passive
                >
                    {label}
                </Switch.Label>
            )}
            {!!description && (
                <Switch.Description
                    as='span'
                    className='text-sm text-gray-500 dark:text-gray-400'
                >
                    {description}
                </Switch.Description>
            )}
        </span>
    )
}

export {Labeled}
