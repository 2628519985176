import classNames from 'classnames'
import {AnimatePresence, motion} from 'framer-motion'
import {Portal} from '../Portal'
import {DropdownProvider} from './DropdownContext'
import {useDropdownConfig} from './useDropdownConfig'

export const Dropdown = ({
    trigger,
    content,
    popperOptions,
    className,
    containerId = 'dropdown',
    active = false,
    zIndex = 999999,
}) => {
    const {
        setReferenceElement,
        isOpen,
        ANIMATION_DURATION,
        onTriggerClick,
        setIsOpen,
        attributes,
        setPopperElement,
        styles,
    } = useDropdownConfig({active, popperOptions})

    return (
        <>
            <div
                onClick={() => {
                    setIsOpen((prev) => !prev)
                }}
            >
                {trigger(setReferenceElement, isOpen)}
            </div>

            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{opacity: 0, scale: 0.95}}
                        animate={{opacity: 1, scale: 1}}
                        exit={{opacity: 0, scale: 0.95}}
                        transition={{duration: ANIMATION_DURATION / 1000}}
                    >
                        <Portal portalContainerId={containerId}>
                            <DropdownProvider
                                value={{
                                    triggerClick: onTriggerClick,
                                    opened: isOpen,
                                    setOpened: setIsOpen,
                                }}
                            >
                                <div
                                    ref={setPopperElement}
                                    style={{
                                        ...styles.popper,
                                        zIndex,
                                    }}
                                    {...attributes.popper}
                                    className={classNames(
                                        'absolute overflow-hidden rounded-lg border border-gray-100 bg-white shadow-lg',
                                        'dark:border-gray-600 dark:bg-gray-700',
                                        className
                                    )}
                                >
                                    {content}
                                </div>
                            </DropdownProvider>
                        </Portal>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    )
}
