import {useRef, useState} from 'react'

export const usePopoverLite = () => {
    const buttonRef = useRef(null)
    const [isActive, setIsActive] = useState(false)

    return {
        isActive,
        setIsActive,
        buttonRef,
    }
}
