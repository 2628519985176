import {ChevTrigger, Dropdown} from '@components/elements'
import {TabsMobileItem} from '@components/navigation/header/HeaderTabsMobile/TabsMobileItem'
import {useEffect, useState} from 'react'
import {useLocation, useRouteMatch} from 'react-router-dom'

export const HeaderTabsMobile = ({tabs, onClickTab}) => {
    const [activeTab, setActiveTab] = useState()
    const match = useRouteMatch()
    const location = useLocation()
    useEffect(() => {
        searchActiveTab()
    }, [])

    const searchActiveTab = () => {
        let tab = tabs.find((tab) => {
            return `${match.url}/${tab.id}/` === location.pathname
            //return location.pathname.includes(tab.id)
        })
        if (!tab) tab = tabs.find((tab) => !tab.disabled)
        setActiveTab(tab?.name || 'Меню')
    }

    const handleClickTab = (id) => (name) => {
        setActiveTab(name)
        if (typeof onClickTab === 'function') onClickTab(id)
    }

    return (
        <div className={'ml-4 flex items-center'}>
            <Dropdown
                trigger={(ref, opened) => (
                    <span ref={ref}>
                        <ChevTrigger
                            className={'dark:text-gray-300'}
                            value={activeTab}
                            opened={opened}
                        />
                    </span>
                )}
                content={
                    <div className='relative grid gap-2 p-5 md:gap-4'>
                        {tabs.map((tab) => {
                            return (
                                <TabsMobileItem
                                    key={tab.id}
                                    changeActiveTab={handleClickTab(tab.id)}
                                    tab={tab}
                                />
                            )
                        })}
                    </div>
                }
            />
        </div>
    )
}
