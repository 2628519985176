import AuthService from '@services/AuthService/AuthService'
import {action, makeObservable, observable} from 'mobx'

export class ProvidersStore {
    constructor(authStore) {
        this.authStore = authStore
        makeObservable(this, {
            providers: observable,
            code: observable,

            setCode: action,
            setProviders: action,
        })
    }
    providers = []
    code = ''

    wnd = ''
    wndInterval = ''

    setProviders = (value = []) => {
        this.providers = value
    }

    setCode = (code) => {
        this.code = code
    }

    onLocationChange = (providerName) => {
        const code = localStorage.getItem('provider_code')
        const name = localStorage.getItem('provider_name')
        if (code) {
            clearInterval(this.wndInterval)
            this.getAuthByProvider(providerName || name, code)

            setTimeout(() => {
                localStorage.removeItem('provider_code')
                localStorage.removeItem('provider_name')
                this.wnd.close()
            }, 10)
        }
    }

    openProvidersAuth = (authLink, providerName) => {
        this.wnd = window.open(
            authLink,
            'Login',
            'width=600,height=700 top=' +
                (window.screen.height - 700) / 2 +
                ',left=' +
                (window.screen.width - 600) / 2 +
                ', resizable=no'
        )
        //window.location.href = authLink

        if (this.wnd) {
            this.wndInterval = setInterval(
                () => this.onLocationChange(providerName),
                1000
            )
        }
    }

    getProviders = async () => {
        try {
            const {data} = await AuthService.getProviders()
            this.setProviders(data)
            return Promise.resolve({data})
        } catch (e) {
            console.log(e)
            return Promise.reject(e)
        }
    }

    getAuthByProvider = async (providerName, code) => {
        const payload = {
            code,
        }
        try {
            const {data} = await AuthService.getAuthByProvider(
                payload,
                providerName
            )
            await this.authStore.appendToken(data, true, true)
        } catch (e) {
            console.log(e)
            this.authStore.logout()
        }
    }
}
