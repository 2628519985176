import {Portal} from '@components/elements/Portal'
import {Popover, Transition} from '@headlessui/react'
import {BriefcaseIcon} from '@heroicons/react/outline'
import ChevronDownIcon from '@heroicons/react/solid/ChevronDownIcon'
import {useResponsive} from '@hooks'
import classNames from 'classnames'
import {useState} from 'react'
import {usePopper} from 'react-popper'
import {ManagerCard} from './ManagerCard'

export const ManagerMenu = ({
    className = '',
    label = 'Ваш персональный менеджер',
    header = false,
    options = [],
    isEmulations,
    user,
    manager,
}) => {
    const {lg} = useResponsive()
    const [referenceElement, setReferenceElement] = useState(null)
    const [popperElement, setPopperElement] = useState(null)
    const {styles, attributes} = usePopper(referenceElement, popperElement, {})

    return (
        <Popover className='relative'>
            {({open}) => (
                <>
                    <div>
                        {!lg ? (
                            <Popover.Button
                                ref={setReferenceElement}
                                className={
                                    'flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none'
                                }
                            >
                                <span className='sr-only'>
                                    {manager.firstName
                                        ? `Ваш менеджер: ${manager.firstName}`
                                        : 'Ваш персональный менеджер'}
                                </span>
                                <BriefcaseIcon
                                    className='h-6 w-6'
                                    aria-hidden='true'
                                />
                            </Popover.Button>
                        ) : (
                            <Popover.Button
                                ref={setReferenceElement}
                                className={classNames(
                                    'inline-flex w-full items-center justify-center space-x-2 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm',
                                    'hover:bg-gray-50 focus:outline-none',
                                    'dark:border-gray-800 dark:bg-gray-700 dark:text-gray-300'
                                )}
                            >
                                <span>
                                    {manager.firstName
                                        ? `Ваш менеджер: ${manager.firstName}`
                                        : 'Ваш персональный менеджер'}
                                </span>
                                <ChevronDownIcon
                                    className='-mr-1 ml-2 h-5 w-5'
                                    aria-hidden='true'
                                />
                            </Popover.Button>
                        )}
                    </div>

                    <Transition
                        show={open}
                        as={'div'}
                        leave='transition ease-in duration-100'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <Portal>
                            <Popover.Panel
                                className='lg:max-w-lgl absolute z-10 mt-1 w-full px-4 sm:max-w-sm sm:px-0'
                                ref={setPopperElement}
                                style={styles.popper}
                                {...attributes.popper}
                            >
                                <ManagerCard manager={manager} user={user} />
                            </Popover.Panel>
                        </Portal>
                    </Transition>
                </>
            )}
        </Popover>
    )
}
