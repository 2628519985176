import {RepostSettingsUrl} from '@routesEnums'
import {baseConfig} from '@services/apiConfigs'
import {ApiFactory} from '@services/ApiFactory'
import {updateCancelToken} from '@services/apiService'

class ReportsSettingsService {
    static instance = ApiFactory.createApi(baseConfig)
    static getSettingsTypeReports = async (query) => {
        return await ReportsSettingsService.instance.get(
            RepostSettingsUrl.settingsTypeReports,
            query
        )
    }

    static getSettingsProfitability = async (query, source) => {
        const config = {
            cancelToken: updateCancelToken(source),
        }
        return await ReportsSettingsService.instance.get(
            RepostSettingsUrl.settingsProfitability,
            query,
            config
        )
    }
    static postSettingsProfitability = async (payload) => {
        return await ReportsSettingsService.instance.post(
            RepostSettingsUrl.settingsProfitability,
            payload
        )
    }

    static getSettingsTax = async (query, source) => {
        const config = {
            cancelToken: updateCancelToken(source),
        }
        return await ReportsSettingsService.instance.get(
            RepostSettingsUrl.settingsTax,
            query,
            config
        )
    }
    static postSettingsTax = async (payload) => {
        return await ReportsSettingsService.instance.post(
            RepostSettingsUrl.settingsTax,
            payload
        )
    }

    static deleteSettingsTax = async (query) => {
        const config = {
            params: query,
        }
        return await ReportsSettingsService.instance.delete(
            RepostSettingsUrl.settingsTax,
            undefined,
            config
        )
    }

    static getSettingsActiveProduct = async (query, source) => {
        const config = {
            cancelToken: updateCancelToken(source),
        }
        return await ReportsSettingsService.instance.get(
            RepostSettingsUrl.settingsActiveProduct,
            query,
            config
        )
    }
    static postSettingsPActiveProduct = async (payload) => {
        return await ReportsSettingsService.instance.post(
            RepostSettingsUrl.settingsActiveProduct,
            payload
        )
    }
}

export default ReportsSettingsService
