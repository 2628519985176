import {services} from '@assets/texts/services/services'
import {ChevTrigger} from '@components/elements/ChevTrigger'
import {CollapsePanel} from '@components/elements/Collapse'
import {useState} from 'react'
import ServiceCard from './ServiceCard'

const ServicesSection = ({servicesSection, isRollUp = true}) => {
    const [openState, setOpenState] = useState(servicesSection)

    const renderCollapseHeader = (isActive) => {
        return (
            <ChevTrigger
                className={'flex-start mb-4 text-gray-800 dark:text-gray-300'}
                classNameValue={'text-2xl font-medium w-auto'}
                classNameChev={'w-7 h-7 ml-3'}
                value={'Наши услуги'}
                opened={isActive}
            />
        )
    }

    const handleClick = () => {
        localStorage.setItem('servicesSection', !openState)
        setOpenState(!openState)
    }

    return (
        <>
            <CollapsePanel
                isRollUp={isRollUp}
                renderHeader={renderCollapseHeader}
                isOpen={openState}
                onClick={handleClick}
            >
                <div
                    className={
                        'grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3'
                    }
                >
                    {services.map((service, idx) => {
                        return <ServiceCard key={idx} data={service} />
                    })}
                </div>
            </CollapsePanel>
        </>
    )
}

export default ServicesSection
