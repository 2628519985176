import {useModalContext} from '@components/overlays'
import {XIcon} from '@heroicons/react/outline'

import {Viewer, ViewerWrapper} from '@components/applicationUi/Editor'

export const NotificationDialog = ({body, title}) => {
    const {onClose} = useModalContext()

    return (
        <div
            className={
                'relative flex flex-col gap-4 text-gray-800 dark:text-gray-200'
            }
        >
            <span onClick={onClose} className={'absolute -right-1 -top-2'}>
                <XIcon
                    className={
                        'h-6 w-6 cursor-pointer text-gray-600 dark:text-gray-400'
                    }
                />
            </span>
            <h2 className={'mt-6 text-2xl font-medium dark:text-gray-100'}>
                {title}
            </h2>
            <div className={'flex max-h-[550px] w-full flex-col overflow-auto'}>
                <Viewer wrapper={ViewerWrapper}>{body}</Viewer>
            </div>
        </div>
    )
}
