import {useStore} from '@hooks'
import {AppPath} from '@routesEnums'
import {TokenService} from '@services/TokenService'
import {AccessControlService} from '@utils/access/AccessControlService'
import {dynamicImport, DYNAMIC_PATHS} from '@utils/dynamicImport'
import BlockedAccessContent from '@views/blocked'
import Confirm from '@views/confirm'
import AuthNewLayouts from '@views/layouts/AuthNewLayouts'
import SidebarLayout from '@views/layouts/SidebarLayout'
import Marketplaces from '@views/marketplaces'
import OAuthCallback from '@views/oAuthCallback'
import PageNotFound from '@views/pageNotFound'
import Promotion from '@views/promotion/Promotion'
import {observer} from 'mobx-react'
import {Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

// Lazy loading
const Account = dynamicImport(DYNAMIC_PATHS.account)
const Quiz = dynamicImport(DYNAMIC_PATHS.quiz)
const Billing = dynamicImport(DYNAMIC_PATHS.billing)
const Main = dynamicImport(DYNAMIC_PATHS.main)
const ProductAnalytics = dynamicImport(DYNAMIC_PATHS.productAnalytics)
const DeliveriesRegions = dynamicImport(DYNAMIC_PATHS.deliveriesRegions)
const Profit = dynamicImport(DYNAMIC_PATHS.profit)
const Telegram = dynamicImport(DYNAMIC_PATHS.telegram)
const Auth = dynamicImport(DYNAMIC_PATHS.auth)
const HelpList = dynamicImport(DYNAMIC_PATHS.helpList)
const AdminUsers = dynamicImport(DYNAMIC_PATHS.adminUsers)
const AdminPartners = dynamicImport(DYNAMIC_PATHS.adminPartners)
const Academy = dynamicImport(DYNAMIC_PATHS.academy)
const Finance = dynamicImport(DYNAMIC_PATHS.finance)
const Delegate = dynamicImport(DYNAMIC_PATHS.delegate)
const Notifications = dynamicImport(DYNAMIC_PATHS.notifications)
const Supply = dynamicImport(DYNAMIC_PATHS.supply)
const Adv = dynamicImport(DYNAMIC_PATHS.adv)

export const ROUTES = [
    {
        path: AppPath.account,
        component: Account,
        protected: true,
    },
    {
        path: AppPath.promotion,
        component: Promotion,
        protected: true,
    },
    {
        path: AppPath.oauth,
        component: OAuthCallback,
        protected: true,
        withoutLayout: true,
    },
    {
        path: AppPath.confirm,
        component: Confirm,
        withoutLayout: true,
    },
    {
        path: AppPath.quiz,
        component: Quiz,
        withoutLayout: true,
    },
    {
        path: AppPath.billing,
        component: Billing,
        protected: true,
    },
    {
        path: AppPath.main,
        component: Main,
        protected: true,
    },
    {
        path: AppPath.productAnalytics,
        component: ProductAnalytics,
        protected: true,
    },
    {
        path: AppPath.deliveriesRegions,
        component: DeliveriesRegions,
        protected: true,
    },
    {
        path: AppPath.profit,
        component: Profit,
        dontBlocked: true,
        protected: true,
    },

    /**Отключен*/
    {
        path: AppPath.notificationTelegram,
        component: Telegram,
        protected: true,
    },
    {
        path: AppPath.marketplaces,
        component: Marketplaces,
        protected: true,
    },
    {
        path: AppPath.authUrl,
        authNew: true,
        component: Auth,
    },
    {
        path: AppPath.helpList,
        component: HelpList,
        protected: true,
    },
    {
        path: AppPath.adminUsers,
        component: AdminUsers,
        protected: true,
    },
    {
        path: AppPath.adminPartners,
        component: AdminPartners,
        protected: true,
    },
    {
        path: AppPath.academy,
        component: Academy,
        protected: true,
    },
    {
        path: AppPath.finance,
        component: Finance,
        protected: true,
    },
    {
        path: AppPath.notifications,
        component: Notifications,
        protected: true,
    },
    {
        path: AppPath.delegateUsers,
        component: Delegate,
        protected: true,
    },
    {
        path: AppPath.supply,
        component: Supply,
        protected: true,
    },
    {
        path: AppPath.adv,
        component: Adv,
        protected: true,
    },
]

const RouteComponent = observer(({rootStore, ...route}) => {
    if (route.protected && !TokenService.isTokenExists()) {
        return <Redirect to={AppPath.signIn} />
    }

    if (Array.isArray(route.path) && TokenService.isTokenExists()) {
        return <Redirect to={rootStore.userStore.homePage} />
    }

    if (route.withoutLayout) {
        return (
            <Route
                path={route.path}
                exact={route.exact}
                render={(props) => (
                    <Suspense fallback={''}>
                        <route.component {...props} />
                    </Suspense>
                )}
            />
        )
    }

    if (Array.isArray(route.path) && route.authNew) {
        return (
            <Route
                path={route.path}
                exact={route.exact}
                render={(props) => (
                    <AuthNewLayouts>
                        <route.component {...props} />
                    </AuthNewLayouts>
                )}
            />
        )
    }

    let RouteCmp = route.component
    if (!route.dontBlocked) {
        RouteCmp = AccessControlService.isRouteAllowed(route.path)
            ? route.component
            : BlockedAccessContent
    }

    return (
        <Route
            path={route.path}
            exact={route.exact}
            render={(props) => (
                <SidebarLayout
                    isBlocked={!AccessControlService.isRouteAllowed(route.path)}
                >
                    {(layoutProps) => <RouteCmp {...layoutProps} {...props} />}
                </SidebarLayout>
            )}
        />
    )
})

export const RenderRoutes = ({routes}) => {
    const rootStore = useStore()
    return (
        <Switch>
            <Redirect from={'/'} to={rootStore.userStore.homePage} exact />
            {routes.map((route) => (
                <RouteComponent
                    key={route.path}
                    rootStore={rootStore}
                    {...route}
                />
            ))}
            <Route>
                <SidebarLayout>
                    {(layoutProps) => <PageNotFound {...layoutProps} />}
                </SidebarLayout>
            </Route>
        </Switch>
    )
}
