import {FeedbackUrl} from '@routesEnums'
import {baseConfig} from '@services/apiConfigs'
import {ApiFactory} from '@services/ApiFactory'

class FeedbackService {
    static instance = ApiFactory.createApi(baseConfig)
    static postMessage = async (payload) => {
        return await FeedbackService.instance.post(FeedbackUrl.message, payload)
    }
}

export default FeedbackService
