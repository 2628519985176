import {InformerUrl} from '@routesEnums'
import {sellerConfig} from '@services/apiConfigs'
import {ApiFactory} from '@services/ApiFactory'
import {getRandomNumber} from '@utils/helpers'

class InformerService {
    static instance = ApiFactory.createApi(sellerConfig, 'informer')
    static getInformerData = async () => {
        return await InformerService.instance.get(
            InformerUrl.informerData(getRandomNumber(10, 9999))
        )
    }
}

export default InformerService
