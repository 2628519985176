import {Loader} from '@components/elements/Loader'
import {CheckIcon} from '@heroicons/react/outline'
import {XCircleIcon} from '@heroicons/react/solid'
import * as qs from 'qs'
import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'

export const YandexOauth = () => {
    const [code, setCode] = useState('')
    const [error, setError] = useState(false)

    const location = useLocation()

    useEffect(() => {
        if (location.search) {
            getStateFromUrl()
        }
        // else if (location.hash) {
        //     getTokenFromUrl()
        // }
        else {
            setError(true)
        }
    }, [])

    const getStateFromUrl = () => {
        try {
            const {code} = qs.parse(location.search, {
                ignoreQueryPrefix: true,
            })
            setCode(code)
            localStorage.setItem('yandex_code', code)
        } catch (e) {
            setError(true)
        }
    }
    //
    // const getTokenFromUrl = () => {
    //     const token = /access_token=([^&]+)/.exec(location.hash)[1]
    //     setCode(token)
    //     localStorage.setItem('yandex_token', token)
    // }

    return (
        <div className={'flex w-full flex-col items-center text-green-500'}>
            {!code && !error && <Loader overlayed={false} />}
            {code && error && (
                <>
                    <XCircleIcon
                        className='h-20 w-20 text-red-400 dark:text-red-300'
                        aria-hidden='true'
                    />
                    <span>{`Авторизация прошла с ошибкой. Закройте страницу и попробуйте снова`}</span>
                </>
            )}{' '}
            {code && !error && (
                <>
                    <CheckIcon className={'h-20 w-20'} />
                    <span>{`Аккаунт yandex подключен успешно`}</span>
                </>
            )}
        </div>
    )
}
