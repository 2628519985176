import {observer} from 'mobx-react'
import {ImportWbFilters} from '../ImportWbFilters'
import {ImportWbInfoFilters} from '../ImportWbInfoFilters'
import {ImportWbInfoSettings} from '../ImportWbInfoSettings'

export const ImportWbInfo = observer(
    ({
        filterStore,
        impostWbStore,
        importWbOverlayStore,
        importWbStatusesStore,
    }) => {
        return (
            <div className={'flex flex-col space-y-4'}>
                <div
                    className={
                        'grid grid-flow-row grid-cols-1 grid-rows-2 gap-4 xl:grid-cols-2 xl:grid-rows-1'
                    }
                >
                    <ImportWbInfoFilters filterStore={filterStore} />
                    <ImportWbInfoSettings filterStore={filterStore} />
                </div>
                <ImportWbFilters
                    filterStore={filterStore}
                    impostWbStore={impostWbStore}
                    importWbOverlayStore={importWbOverlayStore}
                    importWbStatusesStore={importWbStatusesStore}
                />
            </div>
        )
    }
)

ImportWbInfo.displayName = 'ImportWbInfo'
