import {useSelector} from '@hooks/mobxStoreHooks/useSelector'

export const useAccountStore = () => {
    const accountStore = useSelector((store) => store.accountStore)
    const authStore = useSelector((store) => store.authStore)
    const userStore = useSelector((store) => store.userStore)
    const {profileStore} = accountStore.stores
    const {providersFieldStore} = profileStore
    return {
        authStore,
        userStore,
        accountStore,
        profileStore,
        providersFieldStore,
    }
}
