import {AppPath} from '@routesEnums'
import classNames from 'classnames'
import {Link} from 'react-router-dom'

export const LockedTooltipContent = ({
    title = 'Полная версия отчета доступна при покупке тарифа.',
}) => {
    return (
        <span className={classNames('space-x-1')}>
            <span>{title}</span>
            <Link
                to={AppPath.accountTariff}
                className={
                    'cursor-pointer truncate whitespace-normal text-blue-500 hover:opacity-80 dark:text-blue-400'
                }
            >
                Перейти к выбору тарифа
            </Link>
        </span>
    )
}
