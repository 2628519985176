import {NotificationsService} from '@services/NotificationsService'
import {LoaderStore} from '@store/LoaderStore'
import {ModalControl} from '@store/ModalControl'
import {PaginationStore} from '@store/PaginationStore'
import {makeAutoObservable} from 'mobx'

//'b4e39cea-13dc-4cd3-bc49-7ad43dc6b810',

export class NotificationsStore {
    constructor() {
        makeAutoObservable(this)
    }

    notifyControl = new ModalControl()
    loaderStore = new LoaderStore()
    paginationStore = new PaginationStore({
        page: 1,
        total: 0,
        limit: 15,
    })

    notifications = []
    deletionPending = false

    reset = () => {
        this.clearNotifications()
    }

    get credentials() {
        return this.rootStore.marketStore.credentials
    }

    get totalLength() {
        return this.paginationStore.pagination.total
    }

    get unreadCount() {
        return this.notifications.filter((item) => !item.read).length
    }

    clearNotifications = () => {
        this.notifications = []
    }

    setNotifications = (array = []) => {
        this.notifications = array
    }

    pushNotifications = (array = []) => {
        this.notifications.push(...array)
    }

    setTotalLength = (value = 0) => {
        this.totalLength = value
    }

    findIndexById = (id) => {
        return this.notifications.findIndex((item) => item.id === id)
    }

    setNotificationRead = (id) => {
        const index = this.findIndexById(id)
        this.notifications[index].read = true
    }

    setNotificationView = (id) => {
        const index = this.findIndexById(id)
        this.notifications[index].view = true
    }

    setDeletionPending = (value = true) => {
        this.deletionPending = value
    }

    sliceNotification = (id) => {
        const index = this.findIndexById(id)
        this.notifications.splice(index, 1)
    }

    getMoreNotifications = (page = 0) => {
        const pageNumber = page ? page : this.paginationStore.page + 1
        const totalPages = Math.ceil(
            this.paginationStore.total / this.paginationStore.limit
        )
        if (pageNumber > totalPages) return
        this.getNotifications(pageNumber)
    }

    collectNotificationsPayload = (page = 0) => {
        return {
            page: page ? page : this.paginationStore.pagination.page,
            limit: this.paginationStore.pagination.limit,
        }
    }

    parseNotificationsData = (data) => {
        const results = []
        if (Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
                const source = data[i]
                const {id, notification, read, view} = source
                const {body, sentAt, title, type} = notification

                const obj = {
                    id,
                    title,
                    body,
                    read,
                    dateTime: sentAt,
                    view,
                    type,
                }
                results.push(obj)
            }
        }
        return results
    }

    afterDeleteNotification = (data) => {
        data.forEach((item) => {
            this.sliceNotification(item.userNotificationId)
        })
    }

    afterReadNotification = (data) => {
        data.forEach((item) => {
            this.setNotificationRead(item.userNotificationId)
        })
    }

    afterViewNotification = (data) => {
        data.forEach((item) => {
            this.setNotificationView(item.userNotificationId)
        })
    }

    mergePagination = (pagination) => {
        this.paginationStore.setPagination({
            page: pagination.page,
            total: pagination.totalItems,
        })
    }

    patchNotification = async (ids) => {
        const payload = {
            user_notification_ids: ids,
        }
        try {
            const {data} = await NotificationsService.patchNotification(payload)
            if (data?.result) {
                this.afterReadNotification(data.success)
                //this.setUnreadCount(this.unreadCount - 1)
            }
        } catch (error) {
            console.log(error)
        }
    }

    readAllNotifications = async (cb) => {
        try {
            const resp = await this.patchNotification(
                this.notifications.map((item) => item.id)
            )
            // this.notifications.forEach((el) => {
            //     el.read = true
            // })
            //this.setUnreadCount(0)
            if (cb) cb()
        } catch (error) {
            console.log(error)
        }
    }

    deleteNotification = async (ids) => {
        const payload = {
            user_notification_ids: ids,
        }
        try {
            const {data} =
                await NotificationsService.deleteNotification(payload)
            if (data.result) {
                this.afterDeleteNotification(data.success)
            }
            return Promise.resolve({data})
        } catch (error) {
            return Promise.reject(error)
        }
    }

    deleteAllNotifications = async (cb) => {
        this.setDeletionPending(true)
        try {
            const {data} = await this.deleteNotification(
                this.notifications.map((item) => item.id)
            )
            this.clearNotifications()
            this.setTotalLength(0)
            this.setDeletionPending(false)
            if (cb) cb()
        } catch (error) {
            console.log(error)
        }
    }

    getNotifications = async (page = 1, replace) => {
        const payload = this.collectNotificationsPayload(page)
        this.loaderStore.start()

        try {
            const {data} = await NotificationsService.getNotifications(payload)
            const notifications = this.parseNotificationsData(data.results)

            if (replace || !this.notifications.length) {
                this.setNotifications(notifications)
            } else {
                this.pushNotifications(notifications)
            }
            this.mergePagination(data.pagination)
        } catch (error) {
            console.log(error)
        } finally {
            this.loaderStore.stop()
        }
    }

    postNotifications = async (payload) => {
        return await NotificationsService.postNotification(payload)
    }

    postNotificationsSelf = async (body, title) => {
        const payload = {
            body,
            title,
            type: 'text',
        }
        return await NotificationsService.postNotificationSelf(payload)
    }

    patchNotificationsView = async (ids) => {
        const payload = {
            user_notification_ids: ids,
        }
        try {
            const {data} =
                await NotificationsService.patchNotificationView(payload)
            if (data?.result) {
                this.afterViewNotification(data.success)
            }
        } catch (e) {}
    }

    createNotification = async () => {}
}
