import {makeObservable, observable} from 'mobx'

class HelpStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            articles: observable,
        })
    }

    articles = [
        {
            id: 'margin',
            name: 'Маржинальная прибыль',
            description:
                'Оценивайте рентабельность по каждому SKU и транзакции',
            href: 'https://rutube.ru/video/841b0e1c94b141d4cdac52e718834aac/',
        },
        {
            id: 'unit',
            name: 'Юнит - экономика',
            description: 'Корректируйте цены, чтобы зарабатывать больше',
            href: 'https://rutube.ru/video/7a611b930366554477621052f2029b9a/',
        },
        {
            id: 'promotion',
            name: 'Расчет акций',
            description: 'Оценивайте прибыль от возможного участия в акциях',
            href: 'https://rutube.ru/video/97478a8451435139d40070f49b9912ae/',
        },
        {
            id: 'dashboard',
            name: 'Рабочий стол',
            description:
                'Получайте общую сводку о всех кабинетах Озон, ВБ и Яндекс.Маркет в 1 окне',
            href: 'https://rutube.ru/video/ab56c1a84172b6527177a85fb37021d1/',
        },
        {
            id: 'abc',
            name: 'ABC - анализ',
            description:
                'Сегментируйте ассортимент, фокусируйтесь на категории А, избавляйтесь  от неликвида и улучшайте оборачиваемость',
            href: 'https://rutube.ru/video/a15b904043dbb0d73ae6721f2afc491c/',
        },
        {
            id: 'commodity',
            name: 'Товарная аналитика',
            description:
                'Узнайте % выкупа по товарам, проанализируйте ТОПы по заказам и продажам',
            href: 'https://rutube.ru/video/304cc37d4ee8a52ff821195769ed4fb1/',
        },
        {
            id: 'sales_regions',
            name: 'Региональные продажи',
            description:
                'Узнайте, в каких регионах лучше продаются Ваши товары и улучшайте индекс локализации',
            href: 'https://rutube.ru/video/5bcf4448756a48e58fe8ee9e6cd6fd5c/',
        },
        {
            id: 'supply_forecast',
            name: 'Прогноз поставки',
            description:
                'Сформируйте поставку вовремя и в нужном объеме, чтобы не попасть в Out-of-stock и при этом не ухудшать оборачиваемость',
            href: 'https://rutube.ru/video/31f4a84afe568b2aa73f63e2b32a1901/',
        },
    ]
}

export default HelpStore
