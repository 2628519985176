import {
    Amount,
    Locked,
    Skeleton,
    StatusArrow,
    StatusRate,
} from '@components/elements'
import {SIGNS_KEY} from '@components/elements/Amount/convertAmount'
import {WithTooltipWrapper} from '@components/elements/WithTooltipWrapper'
import {Card} from '@components/layout'
import {ExclamationIcon} from '@heroicons/react/outline'
import {useDynamic, useRateStatus} from '@hooks'
import classNames from 'classnames'
import {observer} from 'mobx-react'

export const StructureTotalCard = observer(
    ({
        value,
        currency,
        loader,
        diff,
        title,
        isBlocked,
        isWarn,
        tooltip,
        className,
        count,
        color,
        isDiffColor = true,
    }) => {
        const {colors} = useDynamic()
        const {percent, status} = useRateStatus(diff)

        const colorVariant = colors(status, !isDiffColor)
        return (
            <Card
                defaultPadding={false}
                className={className}
                innerClassName='p-4'
            >
                <div className='flex flex-col space-y-2'>
                    {loader ? (
                        <Skeleton />
                    ) : (
                        <div className='flex items-center justify-between'>
                            <span className='text-gray-600 dark:text-gray-400'>
                                {title}
                            </span>
                            {isWarn ? (
                                <WithTooltipWrapper tooltip={tooltip}>
                                    <ExclamationIcon className='h-6 w-6 flex-shrink-0 text-yellow-400' />
                                </WithTooltipWrapper>
                            ) : null}
                        </div>
                    )}

                    {loader ? (
                        <Skeleton />
                    ) : isBlocked ? (
                        <Locked />
                    ) : (
                        <div className={'flex flex-col gap-1'}>
                            <div className='flex justify-between'>
                                <div
                                    className={classNames(
                                        'flex items-center space-x-2 text-gray-800 dark:text-gray-100',
                                        color
                                    )}
                                >
                                    <span>
                                        <Amount
                                            value={value}
                                            currency={currency}
                                            decimalLength={0}
                                        />
                                    </span>
                                </div>

                                <div
                                    className={
                                        'flex items-center justify-end space-x-2 text-xs text-gray-600 dark:text-gray-400'
                                    }
                                >
                                    <div
                                        className={`flex items-center text-${colorVariant.colorLabel}-500 dark:text-${colorVariant.colorLabel}-300`}
                                    >
                                        <StatusArrow status={status} />
                                        <StatusRate
                                            status={status}
                                            percent={percent}
                                        />
                                    </div>
                                    <span>К прошлому периоду</span>
                                </div>
                            </div>
                            {count ? (
                                <span
                                    className={
                                        'text-sm text-gray-800 dark:text-gray-100'
                                    }
                                >
                                    <Amount
                                        value={count}
                                        currency={SIGNS_KEY.PCS}
                                        decimalLength={0}
                                    />
                                </span>
                            ) : null}
                        </div>
                    )}
                </div>
            </Card>
        )
    }
)

StructureTotalCard.displayName = 'StructureTotalCard'
