import classNames from 'classnames'
import {ru} from 'date-fns/locale'
import {observer} from 'mobx-react'
import {useEffect, useState} from 'react'
import {Calendar} from 'react-date-range'

import {useSelector} from '@hooks/mobxStoreHooks/useSelector'

import './themes/darkTheme.scss'
import './themes/lightTheme.scss'

export const CustomCalendar = observer(({onChange, months = 1, date}) => {
    const [isScroll, setIsScroll] = useState(false)
    const {isDarkTheme} = useSelector((store) => store.themeStore)

    useEffect(() => {
        setIsScroll(true)
    }, [])
    return (
        <div
            className={classNames({
                'dark-calendar': isDarkTheme,
            })}
        >
            {isScroll && (
                <Calendar
                    date={date}
                    onChange={onChange}
                    months={months}
                    direction='vertical'
                    scroll={{enabled: isScroll}}
                    locale={ru}
                    weekdayDisplayFormat={'EEEEEE'}
                    dateDisplayFormat={'d MMM yyyy'}
                />
            )}
        </div>
    )
})
