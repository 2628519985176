import {createContext} from 'react'
import {usePopoverLite} from './usePopoverLite.js'

export const PopoverLiteContext = createContext(null)

export const PopoverLiteProvider = ({children, withinPortal}) => {
    const popoverLite = usePopoverLite()

    return (
        <PopoverLiteContext.Provider value={{...popoverLite, withinPortal}}>
            {children}
        </PopoverLiteContext.Provider>
    )
}
