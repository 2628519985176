import {PopoverLiteButton} from './PopoverLiteButton.jsx'
import {PopoverLitePanel} from './PopoverLitePanel.jsx'
import {PopoverLiteProvider} from './PopoverLiteProvider.jsx'

export const PopoverLite = ({children, withinPortal}) => {
    const renderWrapper = (elem) => {
        if (!withinPortal) return elem
        return <div className={'relative'}>{elem}</div>
    }

    return (
        <PopoverLiteProvider withinPortal={withinPortal}>
            {renderWrapper(children)}
        </PopoverLiteProvider>
    )
}

PopoverLite.Button = PopoverLiteButton
PopoverLite.Panel = PopoverLitePanel
