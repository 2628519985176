import cn from 'classnames'

const TooltipBody = ({
    setPopperElement,
    classNameBody,
    styles,
    zIndex,
    attributes,
    content,
    isArrow,
    setArrowElement,
    side,
}) => {
    return (
        <div
            ref={setPopperElement}
            className={cn(
                'min-w-[170px] rounded-md border border-transparent bg-white p-2 text-sm text-gray-500 shadow-lg md:p-4',
                'dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300',
                classNameBody
            )}
            style={{
                ...styles.popper,
                zIndex,
            }}
            {...attributes.popper}
        >
            {content}
            {isArrow && (
                <div
                    ref={setArrowElement}
                    className={cn(
                        `tooltip-lite__arrow-container`,
                        `tooltip-lite__arrow-container--${side}`
                    )}
                    style={{...styles.arrow}}
                >
                    <div
                        className={cn(
                            `h-4 w-4 rotate-45 border-b border-l border-transparent bg-white`,
                            'dark:border-gray-600 dark:bg-gray-700'
                        )}
                    />
                </div>
            )}
        </div>
    )
}

export {TooltipBody}
