import {ExclamationIcon} from '@heroicons/react/outline'
import {useBlocked} from '@hooks'
import {useSelector} from '@hooks/mobxStoreHooks/useSelector'
import {AppPath} from '@routesEnums'
import {AccessControlService} from '@utils/access/AccessControlService'
import {observer} from 'mobx-react'
import {useMemo} from 'react'
import {CustomModuleWarn} from '../CustomModuleWarn'
import {DisconnectDemoAcc} from './DisconnectDemoAcc'
import {LinkData} from './LinkData'

export const InformerBlockDemo = observer(
    ({children, icon, onlyChild, className, ...props}) => {
        const isBlocked = useBlocked()
        const {informer} = useSelector((store) => store.informerStore)
        const marketStore = useSelector((store) => store.marketStore)

        const {informerText, isInformer} = useMemo(() => {
            const informerObj = {
                informerText: [],
                isInformer: false,
            }
            if (
                !marketStore.isDemoAndRealAcc &&
                marketStore.isOnlyDemoAcc &&
                !AccessControlService.isTestUser()
            ) {
                const informer = (
                    <LinkData
                        title={
                            'В отчете представлены демо-данные. Для начала работы с вашими данными'
                        }
                        to={AppPath.marketplaces}
                        linkText={'подключите кабинеты маркетплейсов'}
                    />
                )
                informerObj.isInformer = true
                informerObj.informerText.push(informer)
            }
            if (
                marketStore.isDemoAndRealAcc &&
                !AccessControlService.isTestUser()
            ) {
                const informer = <DisconnectDemoAcc />
                informerObj.isInformer = true
                informerObj.informerText.push(informer)
            }
            return informerObj
        }, [informer, marketStore.isDemoAndRealAcc, marketStore.isOnlyDemoAcc])

        if (isBlocked) return null
        return (
            isInformer && (
                <CustomModuleWarn
                    {...props}
                    icon={
                        icon ? (
                            icon
                        ) : (
                            <ExclamationIcon
                                className={
                                    'h-10 w-10 flex-shrink-0 text-yellow-400'
                                }
                            />
                        )
                    }
                >
                    {!!informerText.length && (
                        <div className={'flex w-full flex-col space-y-2'}>
                            {informerText.map((informer, idx) => (
                                <div
                                    key={idx}
                                    className={
                                        'text-gray-800 dark:text-gray-300'
                                    }
                                >
                                    {informer}
                                </div>
                            ))}
                        </div>
                    )}
                </CustomModuleWarn>
            )
        )
    }
)

InformerBlockDemo.displayName = 'InformerBlockDemo'
