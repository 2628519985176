import {forwardRef, useCallback, useImperativeHandle, useRef} from 'react'
import {useDynamicSizeList} from './hooks/useDynamicSizeList'

export const InfiniteList = forwardRef(
    (
        {
            rowHeight,
            estimateRowHeight,
            onLoadMore,
            hasMore,
            rowsCount,
            rowRenderer,
            loaderItem,
            headerItem,
            getRowKey,
            scrollingDelay,
            overscan,
            loadMoreCount,
            isItemLoaded,
            isLoaderUp,
            isLoaderDown,
        },
        ref
    ) => {
        const scrollElementRef = useRef(null)

        // useScrollPagination(scrollElementRef, hasMore, onLoadMore, isLoading)

        const {
            visibility,
            bottomRef,
            scrollToBottom,
            measureElement,
            scrollToItem,
            startItem,
            items,
            innerSize,
            innerMargin,
            listHeight,
        } = useDynamicSizeList({
            rowHeight,
            overscan,
            getRowKey,
            estimateRowHeight,
            rowsCount,
            scrollingDelay,
            loadMoreCount,
            isItemLoaded,
            loadMore: onLoadMore,
            getScrollElement: useCallback(
                () => scrollElementRef.current,
                [scrollElementRef]
            ),
        })
        const getListRowProps = useCallback((virtualRow) => {
            const attributes = {
                'data-index': virtualRow.index,
            }
            const style = {
                // position: 'absolute',
                // width: '100%',
                // top: 0,
                // transform: `translateY(${virtualRow.offsetTop}px)`,
            }
            return {
                attributes,
                style,
            }
        }, [])

        useImperativeHandle(
            ref,
            () => ({
                getContainer: () => scrollElementRef.current,
            }),
            []
        )

        return (
            <div
                id='scroller'
                ref={scrollElementRef}
                style={{
                    overflow: 'auto',
                    outline: 'none',
                    position: 'relative',
                    height: '100%',
                }}
            >
                <div
                    style={{
                        marginTop: `${innerMargin}px`,
                        height: `${innerSize}px`,
                        // visibility: !listHeight ? 'hidden' : 'visible',
                    }}
                >
                    {headerItem}
                    {items.map((virtualRow) =>
                        rowRenderer(
                            getListRowProps(virtualRow),
                            measureElement,
                            virtualRow.index
                        )
                    )}
                    {isLoaderDown && loaderItem}
                    <div ref={bottomRef}></div>
                </div>
            </div>
        )
    }
)
