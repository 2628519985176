import {ModalSuccess} from '@components/overlays'
import {AppPath} from '@routesEnums'
import {observer} from 'mobx-react'
import {useState} from 'react'
import {useHistory} from 'react-router-dom'

export const DisconnectDemoAcc = observer(() => {
    const history = useHistory()

    const [successUnsubscribeModal, setSuccessUnsubscribeModal] =
        useState(false)
    const handleClickDisconnectDemo = () => {
        history.push(AppPath.marketplaces)
    }

    return (
        <span className={'space-x-2 lg:flex lg:flex-nowrap'}>
            <span>В отчете представлены демо-данные.</span>
            <span
                onClick={handleClickDisconnectDemo}
                className={
                    'cursor-pointer truncate whitespace-normal text-blue-500 hover:opacity-80 dark:text-blue-400'
                }
            >
                Начать работать с реальными данными
            </span>
            <ModalSuccess
                open={successUnsubscribeModal}
                onClose={() => setSuccessUnsubscribeModal(false)}
                title={`Демо-Кабинет успешно удалён`}
            />
        </span>
    )
})
