import {Button} from '@components/elements'
import {useEffect, useState} from 'react'

export const ConfirmCookies = ({children}) => {
    const [isConfirmPolicy, setIsConfirmPolicy] = useState(true)
    useEffect(() => {
        const isConfirm = JSON.parse(localStorage.getItem('confirm_policy'))
        if (!isConfirm) {
            setIsConfirmPolicy(false)
        }
    }, [])

    const handleClickPolicy = () => {
        localStorage.setItem('confirm_policy', JSON.stringify(true))
        setIsConfirmPolicy(true)
    }

    return (
        <>
            {children}
            {!isConfirmPolicy && (
                <div className='fixed bottom-4 left-1/2 z-[9999] w-full max-w-xl -translate-x-1/2 transform px-4'>
                    <div
                        className={
                            'flex rounded-md border-2 border-gray-300 bg-white p-4 text-gray-800 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-200'
                        }
                    >
                        <div
                            className={
                                'flex flex-col items-center justify-between gap-4 sm:flex-row'
                            }
                        >
                            <p className={'w-full text-sm md:text-base'}>
                                Мы используем cookies для корректной работы
                                сайта в соответствии с{' '}
                                <a
                                    href={'https://seller24.ru/policy'}
                                    target={'_blank'}
                                    className={'cursor-pointer underline'}
                                >
                                    политикой
                                </a>
                            </p>
                            <Button
                                color={'white'}
                                className={'w-full sm:w-auto'}
                                onClick={handleClickPolicy}
                            >
                                Принять
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
