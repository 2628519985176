import {TooltipLite} from '@components/elements'
import {NameField} from './NameField'

export const NameTooltip = ({className, name, onClick}) => {
    return (
        <TooltipLite
            classNameBody={'max-w-[250px]'}
            offset={[0, 10]}
            content={`Название не получено от маркетплейса, обратитесь в поддержку Seller24`}
        >
            {(ref) => (
                <NameField
                    ref={ref}
                    className={className}
                    name={name}
                    onClick={onClick}
                />
            )}
        </TooltipLite>
    )
}
