import {AnimatePresence, motion} from 'framer-motion'
import PropTypes from 'prop-types'
import '../../../styles/components/tooltip-lite.scss'
import {TooltipBody} from './TooltipBody'
import {TooltipPortal} from './TooltipPortal'
import useTooltipLite from './useTooltipLite'

const TooltipLite = ({
    content = '',
    children,
    classNameBody = '',
    portalContainerId = 'overlays',
    zIndex = 999999,
    isArrow = false,
    ...props
}) => {
    const {setReferenceElement, isOpen, ANIMATION_DURATION, ...tooltipProps} =
        useTooltipLite({...props, isArrow})

    const tooltipBodyProps = {
        classNameBody,
        zIndex,
        content,
        ...tooltipProps,
    }

    return (
        <>
            {children(setReferenceElement)}
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{opacity: 0, scale: 0.95}}
                        animate={{opacity: 1, scale: 1}}
                        exit={{opacity: 0, scale: 0.95}}
                        transition={{duration: ANIMATION_DURATION / 1000}}
                        style={{position: 'absolute', top: 0, left: 0}}
                    >
                        {portalContainerId ? (
                            <TooltipPortal
                                {...tooltipBodyProps}
                                portalContainerId={portalContainerId}
                                // state={state}
                            />
                        ) : (
                            <TooltipBody
                                {...tooltipBodyProps}
                                // state={state}
                            />
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    )
}

TooltipLite.propTypes = {
    content: PropTypes.any,
    classNameBody: PropTypes.string,
    portalContainerId: PropTypes.string,
    zIndex: PropTypes.number,
    placement: PropTypes.oneOf([
        'top',
        'bottom',
        'left',
        'right',
        'top-start',
        'bottom-start',
        'left-start',
        'right-start',
        'top-end',
        'bottom-end',
        'left-end',
        'right-end',
    ]),
    active: PropTypes.bool,
    offset: PropTypes.array,
}

export {TooltipLite}
