import {observer} from 'mobx-react'

import {NotificationsList} from '../NotificationsList'

export const Notifications = observer(() => {
    return (
        <div className={'flex h-full max-h-[1180px] flex-col'}>
            <NotificationsList className={'max-h-[1180px]'} />
        </div>
    )
})

Notifications.displayName = 'Notifications'
