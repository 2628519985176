//libs
import {useState} from 'react'

//hooks
import {useSelector} from '@hooks/mobxStoreHooks/useSelector'

//services
import FeedbackService from '@services/FeedbackService/FeedbackService'

//components
import {Button} from '@components/elements'
import {Loader} from '@components/elements/Loader'
import {Card} from '@components/layout'
import {CheckIcon} from '@heroicons/react/outline'
import {SUCCESS_SUPPORT_MESSAGE_SEND} from '@utils/constants'
import {toast} from 'react-toastify'

const MPSuggestion = () => {
    const {userData} = useSelector((store) => store.userStore)

    const [status, setStatus] = useState('')

    const sendMessage = async () => {
        const payload = {
            to: 'support@seller24.ru',
            request_type: 'request_help',
            title: 'Сложности с подключением кабинета' || '',
            fields: [
                {
                    title: 'phone',
                    value: userData?.phone_number || '',
                },
                {
                    title: 'email',
                    value: userData?.email || '',
                },
            ],
        }
        setStatus('pending')

        try {
            await FeedbackService.postMessage(payload) //postMessage(body)
            setStatus('success')
            toast(SUCCESS_SUPPORT_MESSAGE_SEND, {
                type: 'success',
                autoClose: 10000,
            })
        } catch (error) {
            setStatus('error')
        }
    }

    return (
        <Card innerClassName={'flex items-center justify-between'}>
            <div>
                <h2
                    className={
                        'mb-4 text-2xl font-medium text-gray-800 dark:text-gray-200'
                    }
                >
                    Есть сложности с подключением кабинетов?
                </h2>
                <p
                    className={
                        'mb-5 text-base text-gray-600 dark:text-gray-400'
                    }
                >
                    Если у Вас возникли сложности с подключением кабинетов, наши
                    специалисты будут рады помочь.
                </p>
                <Button
                    disabled={status === 'pending' || status === 'success'}
                    onClick={sendMessage}
                >
                    Нужна помощь с подключением кабинетов
                </Button>
                {status === 'pending' && (
                    <p
                        className={
                            'mt-5 flex items-center text-sm dark:text-gray-400'
                        }
                    >
                        <Loader sm overlayed={false} className={'mr-2'} />
                        Идёт отправка сообщения...
                    </p>
                )}
                {status === 'success' && (
                    <p
                        className={
                            'mt-5 flex items-center text-sm text-green-500'
                        }
                    >
                        <CheckIcon className={'mr-2 h-6 w-6'} />
                        Заявка успешно отправлена!
                    </p>
                )}
                {status === 'error' && (
                    <p
                        className={
                            'mt-5 text-sm text-red-500 dark:text-red-300'
                        }
                    >
                        Что-то пошло не так, попробуйте ещё раз...
                    </p>
                )}
            </div>
        </Card>
    )
}

export default MPSuggestion
