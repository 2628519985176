import {createContext, useContext, useMemo} from 'react'
import {useLocation} from 'react-router-dom'

export const PathBlockedContext = createContext()

export const usePathBlockedContext = () => {
    const {userStore, pathBlockedStore} = useContext(PathBlockedContext)
    const location = useLocation()

    return useMemo(() => {
        if (userStore.isEmulation) return [false, {}, false]
        const urlDisabled = !!userStore.urlDisable.find(
            (url) => url === location.pathname
        )
        const pathData = pathBlockedStore.pathBlocked.get(location.pathname)
        return [urlDisabled, pathData, pathBlockedStore.loaderStore.loading]
    }, [
        location.pathname,
        userStore.urlDisable,
        userStore.isEmulation,
        pathBlockedStore.pathBlocked,
        pathBlockedStore.loaderStore.loading,
    ])
}
