import {useDebouncedFunction} from '@hooks/index.js'
import {useEffect, useRef, useState} from 'react'

export const useTableScrollTop = (tableRef) => {
    const headerScrollRef = useRef(null)
    const [offsetWidth, setOffsetWidth] = useState(0)
    const [showHeaderScroll, setShowHeaderScroll] = useState(false)

    // Слушатель события скролла для синхронизации скроллбаров
    const syncScroll = (e) => {
        if (
            headerScrollRef.current &&
            tableRef.current.scrollWidth === headerScrollRef.current.scrollWidth
        ) {
            tableRef.current.scrollLeft = e.target.scrollLeft
            headerScrollRef.current.scrollLeft = e.target.scrollLeft
        }
    }

    const checkForResizeHeaderScrollDebounce = useDebouncedFunction(() => {
        headerScrollRef.current.scrollLeft = tableRef.current.scrollLeft
    }, 50)

    useEffect(() => {
        const mo = new MutationObserver(checkForResizeHeaderScrollDebounce)
        if (headerScrollRef.current)
            mo.observe(headerScrollRef.current, {
                childList: true,
                subtree: true,
                attributes: true,
            })

        return () => mo.disconnect()
    }, [showHeaderScroll])

    useEffect(() => {
        setOffsetWidth(tableRef.current?.scrollWidth)
    }, [tableRef.current?.scrollWidth])

    return {
        syncScroll,
        offsetWidth,
        tableRef,
        headerScrollRef,
        showHeaderScroll,
        setShowHeaderScroll,
    }
}
