import {BillingUrl} from '@routesEnums'
import {baseConfig} from '@services/apiConfigs'
import {ApiFactory} from '@services/ApiFactory'

class BillingService {
    static instance = ApiFactory.createApi(baseConfig)

    static getTariffsDirectory = async () => {
        return await BillingService.instance.get(BillingUrl.tariffsDirectory)
    }

    static getPurchaseCallback = async (query) => {
        return await BillingService.instance.get(
            BillingUrl.purchaseCallback,
            query
        )
    }

    static postBillingDict = async (payload) => {
        return await BillingService.instance.post(
            BillingUrl.billingDict,
            payload
        )
    }

    static postBillingBundles = async (payload) => {
        return await BillingService.instance.post(
            BillingUrl.billingBundles,
            payload
        )
    }

    static postBillingPurchaseTink = async (payload) => {
        if (!payload.bundle_id) {
            return console.error('API: Create order errors. bundle_id is empty')
        }

        return await BillingService.instance.post(
            BillingUrl.purchaseTink,
            payload
        )
    }

    static getBillingCallbackTink = async (query) => {
        return await BillingService.instance.get(BillingUrl.callbackTink, query)
    }

    static getTariff = async () => {
        return await BillingService.instance.get(BillingUrl.subscriptionTariff)
    }
}

export default BillingService
