import FeedbackService from '@services/FeedbackService/FeedbackService'
import {TokenService} from '@services/TokenService'
import {ModalControl} from '@store/ModalControl'
import {regexes} from '@utils/regexes'
import {action, computed, makeObservable, observable} from 'mobx'
import {toast} from 'react-toastify'

class SupportOverlayStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            destination: observable,
            email: observable,
            emailError: observable,
            setDestination: action,
            topic: observable,
            setTopic: action,
            topicLabel: observable,
            setTopicLabel: action,
            supportQuery: observable,
            setSupportQuery: action,
            queryLength: computed,
            invalidLength: computed,
            queryStatus: observable,
            setQueryStatus: action,
            setEmail: action,
            setEmailError: action,
        })
        this.setTopic(this.topic.options[0])
        this.overlayControl = new ModalControl(false)
    }

    destination = 'support@seller24.ru'
    email = ''
    emailError = false

    setDestination = (value = 'support@seller24.ru') => {
        this.destination = value
    }
    setEmail = (value = '') => {
        this.email = value
    }

    setEmailError = (value = false) => {
        this.emailError = value
    }

    topic = {
        options: [
            {
                name: 'Сообщить об ошибке',
                id: 'consult',
                destination: 'support@seller24.ru',
            },
            {
                name: 'Вопрос по тарифам',
                id: 'tariff',
                destination: 'support@seller24.ru',
            },
            {
                name: 'Предложение по доработке',
                id: 'offer',
                destination: 'support@seller24.ru',
            },
        ],
        selected: undefined,
    }

    setTopic = (value = undefined) => {
        this.topic.selected = value
        this.setDestination(value.destination)
    }

    topicLabel = ''

    setTopicLabel = (value = '') => {
        this.topicLabel = value
    }

    supportQuery = ''

    setSupportQuery = (value = '') => {
        this.supportQuery = value
        if (value.length > 0) this.setQueryStatus('ready')
    }

    MAX_QUERY_LENGTH = 1000

    MIN_QUERY_LENGTH = 30

    get queryLength() {
        return this.supportQuery.length
    }

    get invalidLength() {
        return (
            this.queryLength < this.MIN_QUERY_LENGTH ||
            this.queryLength > this.MAX_QUERY_LENGTH
        )
    }

    queryStatus = 'ready'

    setQueryStatus = (value = 'ready') => {
        this.queryStatus = value
    }

    sendSupportToEmptyData = () => {
        this.setSupportQuery('Не отображаются данные')
        this.submitForm()
        toast('Обращение в поддержку отправлено', {type: 'success'})
    }

    sendSupportToBlockedOrdersData = () => {
        this.setTopicLabel(
            'Запрос на функционал "Управление заказами FBS (OMS)"'
        )
        this.submitForm()
        toast('Обращение в поддержку отправлено', {type: 'success'})
    }

    submitForm = async (cb) => {
        if (!TokenService.isTokenExists() && !regexes.email.test(this.email)) {
            return this.setEmailError(true)
        }
        const user = this.rootStore.userStore.userData
        const body = {
            to: this.destination || 'support@seller24.ru',
            title: this.topicLabel || '',
            fields: [
                {
                    title: 'phone',
                    value: user?.phone_number || '',
                },
                {
                    title: 'email',
                    value: user?.email || this.email,
                },
                {
                    title: 'message',
                    value: this.supportQuery || '',
                },
            ],
        }
        this.setQueryStatus('pending')
        this.setEmailError(false)
        try {
            await FeedbackService.postMessage(body)
            this.setQueryStatus('success')
            this.setSupportQuery()
            this.setTopicLabel()
            if (cb) cb()
        } catch (error) {
            this.setQueryStatus('failed')
        }
    }
}

export default SupportOverlayStore
