export const dynamicRoutes = {
    account: () => import('../../views/account/Account/Account.jsx'),
    quiz: () => import('../../views/quiz/Quiz.jsx'),
    billing: () => import('../../views/billing/Billing/Billing.jsx'),
    main: () => import('../../views/main/Main.jsx'),
    productAnalytics: () =>
        import('../../views/productAnalytics/ProductAnalytics.jsx'),
    deliveriesRegions: () =>
        import('../../views/deliveriesRegions/DeliveriesRegions.jsx'),
    profit: () => import('../../views/profit/Profit.jsx'),
    telegram: () => import('../../views/telegram/Telegram/Telegram.jsx'),
    auth: () => import('../../views/auth/Auth.jsx'),
    helpList: () => import('../../views/help/HelpList/HelpList.jsx'),
    adminUsers: () => import('../../views/adminUsers/AdminUsers.jsx'),
    adminPartners: () =>
        import('../../views/adminPartners/AdminPartners/AdminPartners.jsx'),
    academy: () => import('../../views/academy/Academy/Academy.jsx'),
    finance: () => import('../../views/finance/Finance.jsx'),
    delegate: () => import('../../views/delegate/Delegate.jsx'),
    notifications: () => import('../../views/notifications/Notifications.jsx'),
    supply: () => import('../../views/supply/Supply.jsx'),
    adv: () => import('../../views/adv/Adv.jsx'),
}
