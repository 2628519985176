import {useLayoutEffect} from 'react'

let scrollLockCount = 0

export function useScrollLock(isLocked) {
    useLayoutEffect(() => {
        if (isLocked) {
            if (scrollLockCount === 0) {
                const scrollBarWidth =
                    window.innerWidth - document.documentElement.clientWidth
                document.body.style.overflow = 'hidden'
                document.body.style.paddingRight = `${scrollBarWidth}px`
            }
            scrollLockCount++
        }

        return () => {
            if (isLocked) {
                scrollLockCount--
                if (scrollLockCount === 0) {
                    document.body.style.overflow = ''
                    document.body.style.paddingRight = ''
                }
            }
        }
    }, [isLocked])
}
