import {observer} from 'mobx-react'

export const HeaderTitle = observer(({title}) => {
    return (
        <span
            className={
                'ml-4 flex items-center text-lg font-medium text-gray-800 dark:text-gray-200'
            }
        >
            {title}
        </span>
    )
})
