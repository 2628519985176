import {Button} from '@components/elements'
import {useModalContext} from '@components/overlays'
import {ExclamationIcon} from '@heroicons/react/outline'

export const ErrorLegalDialog = ({
    onOpenImportOverlay,
    legalList,
    cabinetName,
    isConnectedLegal,
}) => {
    const {onClose} = useModalContext()
    const handleClick = () => {
        onOpenImportOverlay && onOpenImportOverlay()
        onClose()
    }
    return (
        <div
            className={
                'flex flex-col items-center gap-4 text-gray-800 dark:text-gray-200'
            }
        >
            <ExclamationIcon
                className={'h-10 w-10 flex-shrink-0 text-yellow-400'}
            />

            <p className={'text-center'}>Данные не были загружены в систему!</p>
            <p className={'text-center'}>
                {`Вы загрузили список финансовых отчетов от кабинета ${cabinetName}, юр. лицо
                    ${legalList[0]}. В платформе вы указали юр. лицо ${legalList[1]}. Юр. лица
                    не совпадают, поэтому список отчетов не был сформирован.`}
            </p>
            {isConnectedLegal ? (
                <p className={'text-center'}>
                    Убедитесь, что вы загружаете список финансовых отчетов из
                    правильного кабинета
                </p>
            ) : (
                <p className={'text-center'}>
                    Убедитесь, что вы загружаете список финансовых отчетов из
                    правильного кабинета или отредактируйте юр. лицо,
                    привязанное к магазину (п.1)
                </p>
            )}
            <div className={'flex justify-center'}>
                <Button onClick={handleClick}>
                    <span>Вернуться</span>
                </Button>
            </div>
        </div>
    )
}
