import {AppPath} from '@routesEnums'
import classNames from 'classnames'
import {Link} from 'react-router-dom'

export const LockedLimitTooltipContent = ({isProducts}) => {
    return (
        <span className={classNames('space-x-1')}>
            <span>{`Внимание! Вы превысили лимит текущего тарифа по ${
                isProducts ? 'товарам' : 'кабинетам'
            }. Пожалуйста,`}</span>
            <Link
                to={AppPath.accountTariff}
                className={
                    'cursor-pointer truncate whitespace-normal text-blue-500 hover:opacity-80 dark:text-blue-400'
                }
            >
                смените тариф,
            </Link>
            <span>чтобы полноценно работать с отчетами.</span>
        </span>
    )
}
