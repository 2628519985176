import {Labeled} from '@components/forms/Toggle/Labeled'
import {Switch} from '@headlessui/react'
import cn from 'classnames'
import {Toggle} from './Toggle'

const ToggleLabeled = ({
    disabled,
    checked,
    onChange,
    labelRight,
    descriptionRight,
    labelLeft,
    descriptionLeft,
    className,
}) => {
    return (
        <Switch.Group
            as='div'
            disabled={disabled}
            className={cn(
                className,
                'flex items-center justify-between space-x-4',
                {
                    'pointer-events-none opacity-50': disabled,
                }
            )}
        >
            <Labeled label={labelLeft} description={descriptionLeft} />
            <Toggle checked={checked} onChange={onChange} />
            <Labeled label={labelRight} description={descriptionRight} />
        </Switch.Group>
    )
}

export {ToggleLabeled}
