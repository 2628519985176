import PropTypes from 'prop-types'
import {convertAmountValue} from './convertAmount'

const Amount = (props) => {
    const {
        currency = 'RUR',
        showCurrency = true,
        decimalLength = 2,
        value = 0,
        nospace = false,
        isPlus,
        fixedLength = null,
    } = props

    return (
        <>
            {convertAmountValue({
                value: fixedLength
                    ? Number(value).toFixed(fixedLength)
                    : Number(value),
                showCurrency,
                currency,
                decimalLength,
                isPlus,
                nospace,
                withFixed: !!fixedLength,
            })}
        </>
    )
}

Amount.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currency: PropTypes.string,
    showCurrency: PropTypes.bool,
    decimalLength: PropTypes.number,
    nospace: PropTypes.bool,
    isPlus: PropTypes.bool,
}

export {Amount}
