import {useEffect, useState} from 'react'
export const useFetch = (fetchAsync, fetch, manual = false) => {
    const [loading, setLoading] = useState()
    const [error, setError] = useState()
    const [data, setData] = useState()

    useEffect(() => {
        if (!manual) {
            startFetch()
            fetch()
        }
    }, [manual])

    const startFetch = async () => {
        setLoading(true)
        try {
            const res = await fetchAsync()
            setData(res.data)
        } catch (e) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }

    return {
        loading,
        error,
        data,
        startFetch,
    }
}
