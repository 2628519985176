import ReactDOM from 'react-dom'
import {Modal} from '../Modal/Modal'

function ModalOverride({children, ...props}) {
    return <Modal {...props}>{children}</Modal>
}

class ModalCaller {
    constructor(options) {
        this.modalsContainer = document.getElementById('overlays')
        this.overlay = document.createElement('div')

        this.openModal = true
        this.modalProps = {
            id: options?.id || 'overlays',
            children: options.children,
            className: options.className,
        }
        this._render()
    }

    _render = () => {
        this.modalsContainer.appendChild(this.overlay)
        this.setContent()
    }

    setContent = () => {
        ReactDOM.render(
            <ModalOverride
                className={this.modalProps.className}
                open={this.openModal}
                onClose={this.destroy}
            >
                {React.cloneElement(this.modalProps.children, {
                    open: this.openModal,
                    onClose: this.destroy,
                })}
            </ModalOverride>,
            this.overlay
        )
    }

    destroy = () => {
        this.openModal = false
        ReactDOM.unmountComponentAtNode(this.overlay)
    }
}

export {ModalCaller}
