import {Listbox} from '@headlessui/react'
import {SearchIcon} from '@heroicons/react/outline'
import {CheckIcon} from '@heroicons/react/solid'
import {default as classNames, default as cn} from 'classnames'
import {useEffect, useMemo, useRef, useState} from 'react'
import {SelectSearch} from './SelectSearch'
const LIST_EDGE_THRESHOLD = 50 // px

const SelectBody = ({
    setPopperElement,
    referenceElement,
    styles,
    attributes,
    options,
    size,
    childElement,
    portal,
    fetchSearch,
    onFetchSearch,
    isLoadingData,
    isNextLoadData,
    optionLabelKey,
    defaultSearch,
    zIndex = 999999,
    emptySearchTitle = 'К сожалению мы ничего не нашли',
}) => {
    const [searchValue, setSearchValue] = useState('')
    const counter = useRef(1)

    const filteredOptions = useMemo(() => {
        if (searchValue.trim() && !fetchSearch) {
            return options.filter((option) => {
                const label = option[optionLabelKey]
                return label.toLowerCase().includes(searchValue)
            })
        }
        return [...options]
    }, [options, searchValue])

    useEffect(() => {
        if (fetchSearch && onFetchSearch) {
            onFetchSearch(searchValue, 1)
        }
    }, [searchValue, fetchSearch])

    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
    }

    const getScrollBottom = (target) => {
        const {scrollTop, scrollHeight, offsetHeight} = target
        return scrollHeight - (scrollTop + offsetHeight)
    }

    const checkLoadCondition = ({target}) => {
        const scrollBottom = getScrollBottom(target)
        return scrollBottom < LIST_EDGE_THRESHOLD
    }

    const handleOnScroll = (e) => {
        if (
            !isLoadingData &&
            typeof onFetchSearch === 'function' &&
            isNextLoadData &&
            checkLoadCondition(e)
        ) {
            onFetchSearch(searchValue, counter.current + 1)
            counter.current += 1
        }
    }

    const renderSearch = () => {
        const input = (
            <SelectSearch
                value={searchValue}
                onChange={handleChangeSearchValue}
            />
        )
        if (fetchSearch && defaultSearch) return input

        if (!fetchSearch && defaultSearch && options.length > 9) return input
    }

    return (
        <Listbox.Options
            static
            as={'div'}
            className={cn(
                'absolute z-10 max-h-60 bg-white shadow-lg',
                'overflow-auto rounded-md pb-1 text-base ring-1 ring-black ring-opacity-5',
                'focus:outline-none sm:text-sm',
                'dark:bg-gray-700',
                {
                    'w-full': !portal,
                    'h-60': filteredOptions.length === 0,
                }
            )}
            ref={setPopperElement}
            style={{
                ...styles.popper,
                zIndex,
                width:
                    portal && referenceElement
                        ? `${referenceElement?.offsetWidth || 80}px`
                        : undefined,
            }}
            {...attributes.popper}
            onScroll={
                fetchSearch && handleOnScroll ? handleOnScroll : undefined
            }
        >
            <div className={'relative flex h-full flex-col'}>
                {renderSearch()}
                {defaultSearch && filteredOptions.length === 0 && (
                    <div
                        className={
                            'flex h-full flex-1 flex-col items-center justify-center space-y-4'
                        }
                    >
                        <SearchIcon
                            className={
                                'h-10 w-10 text-gray-500 dark:text-gray-300'
                            }
                        />
                        <p className={'text-gray-700 dark:text-gray-300'}>
                            {emptySearchTitle}
                        </p>
                    </div>
                )}
                {filteredOptions.map((option, index) => (
                    <Listbox.Option
                        key={index}
                        disabled={option.disabled}
                        className={({active}) =>
                            classNames(
                                active
                                    ? 'bg-blue-500 text-white dark:bg-blue-400'
                                    : 'text-gray-900 dark:text-gray-300',
                                'relative cursor-pointer select-none list-none py-2 pl-3 pr-9',
                                size === 'sm' && 'py-1.5 pl-3 pr-9 text-xs',
                                size === 'md' &&
                                    'py-2 pl-4 pr-9 text-sm font-medium leading-4',
                                size === 'base' &&
                                    'py-2 pl-5 pr-9 text-sm font-medium',
                                size === 'lg' &&
                                    'py-2 pl-5 pr-9 text-base font-medium',
                                size === 'xl' &&
                                    'py-3 pl-7 pr-9 text-base font-medium',
                                {
                                    'opacity-50': option.disabled,
                                }
                            )
                        }
                        value={option}
                    >
                        {childElement
                            ? ({selected, active}) =>
                                  childElement({
                                      option,
                                      selected,
                                      active,
                                  })
                            : ({selected, active}) => (
                                  <>
                                      <span
                                          className={classNames(
                                              selected
                                                  ? 'font-semibold'
                                                  : 'font-normal',
                                              'block truncate'
                                          )}
                                      >
                                          {option.name}
                                      </span>

                                      {selected ? (
                                          <span
                                              className={classNames(
                                                  active
                                                      ? 'text-white'
                                                      : 'text-blue-500 dark:text-blue-400',
                                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                              )}
                                          >
                                              <CheckIcon
                                                  className='h-5 w-5'
                                                  aria-hidden='true'
                                              />
                                          </span>
                                      ) : null}
                                  </>
                              )}
                    </Listbox.Option>
                ))}
            </div>
        </Listbox.Options>
    )
}

export default SelectBody
