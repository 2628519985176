import {Suspense, useCallback, useMemo, useState} from 'react'

//components
import {Document} from '@components/applicationUi'

//styles
import 'swiper/scss'
import 'swiper/scss/autoplay'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'

//icons
import {Seller24Icon} from '@components/elements/Icon/Icon'

//hooks
import {LoaderFull} from '@components/elements/Loader'
import {useResponsive} from '@hooks'
import {useSelector} from '@hooks/mobxStoreHooks/useSelector'
import {AuthLayoutContext} from '@views/layouts/context/AuthLayoutContext'
import classNames from 'classnames'
import {observer} from 'mobx-react'

const AuthNewLayouts = observer(({children}) => {
    const {isDarkTheme} = useSelector((store) => store.themeStore)
    const {loginLoader} = useSelector((store) => store.authStore)
    const {md} = useResponsive()

    const [documentOpen, setDocumentOpen] = useState(false)
    const [documentName, setDocumentName] = useState('')

    const openDocument = useCallback(
        (doc) => {
            setDocumentName(doc)
            setDocumentOpen(true)
        },
        [setDocumentName, setDocumentOpen]
    )

    return (
        <div
            className={classNames('relative flex', {
                'h-screen': md,
                'h-full': !md,
            })}
        >
            <div className={'h-screen bg-white'} />
            {loginLoader ? (
                <LoaderFull absolute />
            ) : (
                <>
                    <div className='flex flex-1 flex-col justify-center bg-white px-4 py-4 dark:bg-gray-800 sm:px-6 lg:w-1/2 lg:px-20 xl:px-24'>
                        <div className='mx-auto flex h-full min-h-[580px] w-full max-w-sm flex-col justify-start md:justify-center lg:w-96'>
                            <div>
                                <Seller24Icon className='h-12 w-auto fill-current text-blue-500 dark:text-white' />
                            </div>
                            <AuthLayoutContext.Provider
                                value={useMemo(
                                    () => ({openDocument}),
                                    [openDocument]
                                )}
                            >
                                <Suspense fallback={''}>{children}</Suspense>
                            </AuthLayoutContext.Provider>
                        </div>
                        <div
                            className={
                                'mt-4 flex w-full flex-col justify-center text-center text-sm text-gray-600 sm:flex-row'
                            }
                        >
                            <a
                                className={
                                    'mx-4 cursor-pointer transition-all hover:text-gray-800'
                                }
                                target={'_blank'}
                                href={'//files.seller24.ru/offer.pdf'}
                            >
                                Оферта
                            </a>
                            <span
                                className={
                                    'mx-4 cursor-pointer transition-all hover:text-gray-800'
                                }
                                onClick={() => openDocument('policy')}
                            >
                                Политика конфиденциальности
                            </span>
                            <a
                                className={
                                    'mx-4 cursor-pointer transition-all hover:text-gray-800'
                                }
                                href={'//seller24.ru/platform-s24'}
                                target={'_blank'}
                            >
                                О сервисе
                            </a>
                            <a
                                className={
                                    'mx-4 cursor-pointer transition-all hover:text-gray-800'
                                }
                                href={'//files.seller24.ru/user-manual.pdf'}
                                target={'_blank'}
                            >
                                Руководство пользователя
                            </a>
                        </div>
                    </div>
                    <Document
                        documentName={documentName}
                        open={documentOpen}
                        setOpen={setDocumentOpen}
                    />
                </>
            )}
        </div>
    )
})

AuthNewLayouts.displayName = 'AuthNewLayouts'

export default AuthNewLayouts
