import {Button} from '@components/elements/Buttons'
import {Modal} from './Modal'

import {
    ExclamationIcon,
    InformationCircleIcon,
    QuestionMarkCircleIcon,
} from '@heroicons/react/outline'

const ModalConfirm = (props) => {
    const {
        isModal = true,
        open,
        onClose,
        onAccept,
        type = 'warn',
        iconNode,
        title,
        description,
        acceptDisabled,
        accept = 'Да',
        decline = 'Отмена',
    } = props

    const renderIcon = () => {
        if (iconNode) return iconNode
        switch (type) {
            case 'warn':
            default:
                return (
                    <ExclamationIcon
                        className='h-12 w-12 text-red-600 dark:text-red-400'
                        aria-hidden='true'
                    />
                )
            case 'question':
                return (
                    <QuestionMarkCircleIcon
                        className='h-12 w-12 text-blue-500 dark:text-blue-400'
                        aria-hidden='true'
                    />
                )
            case 'info':
                return (
                    <InformationCircleIcon
                        className='h-12 w-12 text-blue-500 dark:text-blue-400'
                        aria-hidden='true'
                    />
                )
        }
    }

    const modalBody = (
        <div className={'flex flex-col items-center'}>
            <div>
                <div className='mx-auto flex items-center justify-center'>
                    {renderIcon()}
                </div>
                {(title || description) && (
                    <div className='mt-3 text-center sm:mt-5'>
                        {title && (
                            <h3 className='whitespace-pre-wrap text-lg font-medium leading-6 text-gray-900 dark:text-gray-100'>
                                {title}
                            </h3>
                        )}
                        {description && (
                            <div className='mt-2'>
                                <p className='whitespace-pre-wrap text-sm text-gray-500 dark:text-gray-300'>
                                    {description}
                                </p>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className='mt-5 flex w-full items-center space-x-4 sm:mt-6'>
                <Button
                    className={'w-1/2'}
                    color={type === 'warn' ? 'primary' : 'white'}
                    onClick={onClose}
                >
                    {decline}
                </Button>
                <Button
                    className={'w-1/2'}
                    color={type === 'warn' ? 'white' : 'primary'}
                    disabled={acceptDisabled}
                    onClick={() => {
                        if (onAccept) onAccept()
                        onClose()
                    }}
                >
                    {accept}
                </Button>
            </div>
        </div>
    )

    return isModal ? (
        <Modal className={'sm:max-w-[450px]'} open={open} onClose={onClose}>
            {modalBody}
        </Modal>
    ) : (
        modalBody
    )
}

export {ModalConfirm}
