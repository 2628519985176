export const PARTNERSHIP_TOOLTIP = {
    wildberries:
        'Авторизация Wildberries подтверждает, что Seller24 удовлетворяет требованиям информационной безопасности, предъявляемым Wildberries',
    ozon: 'Seller24 был золотым технологическим партнером Ozon с 2019 до 2023 года. В 2023 году Ozon закрыл данную программу.',
    yandex: (
        <span>
            Сертификация от Я.Маркет означает, что Я.Маркет следит за качеством
            работы Seller24.{' '}
            <a
                className={
                    'cursor-pointer whitespace-pre text-sm font-medium text-blue-500 hover:text-blue-400 dark:text-blue-300 dark:hover:text-blue-200'
                }
                target={'_blank'}
                href={
                    'https://partner.market.yandex.ru/blog/start-on-marketplace/texpartner-na-markete/'
                }
            >
                Подробнее
            </a>
        </span>
    ),
}

export const PARTNERSHIP_TITLE = {
    wildberries: 'авторизованный сервис Wildberries',
    ozon: 'золотой техпартнер Ozon',
    yandex: 'технологический партнер Яндекс. Маркет',
}
