import {ArrowLeftIcon, ArrowRightIcon} from '@heroicons/react/outline'
import cn from 'classnames'

const SwitchControl = ({toggleSidebar, isSlim}) => {
    return (
        <div
            className={'sticky bottom-0 my-2 bg-gray-800 px-2 dark:bg-gray-700'}
            onClick={toggleSidebar}
        >
            <div
                className={cn(
                    'cursor-pointer rounded-md px-2 py-2',
                    'hover:bg-gray-600 dark:hover:bg-gray-800',
                    {
                        'flex items-center justify-center': isSlim,
                    }
                )}
            >
                {isSlim ? (
                    <ArrowRightIcon
                        className={
                            'h-6 w-6 flex-shrink-0 stroke-current text-white'
                        }
                    />
                ) : (
                    <ArrowLeftIcon
                        className={
                            'h-6 w-6 flex-shrink-0 stroke-current text-white'
                        }
                    />
                )}
            </div>
        </div>
    )
}

export {SwitchControl}
