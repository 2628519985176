import {Button, ButtonGroup} from '@components/elements/Buttons'
import {LinkUnderline} from '@components/elements/LinkUnderline'
import {
    forwardRef,
    useImperativeHandle,
    useLayoutEffect,
    useRef,
    useState,
} from 'react'
import {Viewer} from './Viewer'

// Компонент редактора
export const Editor = forwardRef((_, ref) => {
    const editorRef = useRef(null)
    const [isViewer, setIsViewer] = useState(false)
    const [content, setContent] = useState('')

    useLayoutEffect(() => {
        if (editorRef.current && !editorRef.current.innerText && !isViewer) {
            editorRef.current.innerText = content
        }
    }, [isViewer])

    const handleCommand = (command) => {
        if (!editorRef.current) return
        const editor = editorRef.current
        const selection = window.getSelection()
        const selectedText = selection.toString()

        let markdown = ''
        switch (command) {
            case 'header':
                markdown = `# ${selectedText}`
                break
            case 'bold':
                markdown = `**${selectedText}**`
                break
            case 'italic':
                markdown = `*${selectedText}*`
                break
            case 'underline':
                markdown = `<u>${selectedText}</u>`
                break
            case 'strikethrough':
                markdown = `~~${selectedText}~~`
                break
            case 'link':
                const url = prompt('Enter the URL')
                if (url) {
                    markdown = `[${selectedText}](${url})`
                }
                break
            case 'paragraph':
                markdown = `\n\n${selectedText}`
                break
            default:
                break
        }

        if (selectedText) {
            document.execCommand('insertText', false, markdown)
        }
    }

    const handleInput = () => {
        setContent(editorRef.current.innerText)
    }

    useImperativeHandle(ref, () => {
        return {
            getContent: () => content,
        }
    }, [content])

    return (
        <div className='flex flex-col gap-5'>
            <div className='flex gap-2'>
                <ButtonGroup>
                    <Button
                        color={'white'}
                        className='rounded bg-gray-200 px-4 py-2'
                        onClick={() => handleCommand('header')}
                    >
                        H
                    </Button>
                    <Button
                        color={'white'}
                        className='rounded bg-gray-200 px-4 py-2'
                        onClick={() => handleCommand('bold')}
                    >
                        B
                    </Button>
                    <Button
                        color={'white'}
                        className='rounded bg-gray-200 px-4 py-2'
                        onClick={() => handleCommand('italic')}
                    >
                        I
                    </Button>
                    <Button
                        color={'white'}
                        className='rounded bg-gray-200 px-4 py-2'
                        onClick={() => handleCommand('underline')}
                    >
                        U
                    </Button>
                    <Button
                        color={'white'}
                        className='rounded bg-gray-200 px-4 py-2'
                        onClick={() => handleCommand('strikethrough')}
                    >
                        S
                    </Button>
                    <Button
                        color={'white'}
                        className='rounded bg-gray-200 px-4 py-2'
                        onClick={() => handleCommand('link')}
                    >
                        L
                    </Button>
                </ButtonGroup>
            </div>
            {!isViewer ? (
                <div
                    className='max-h-[400px] min-h-[150px] overflow-auto border border-gray-300 p-4 text-gray-800 dark:border-gray-600 dark:text-gray-200'
                    contentEditable
                    ref={editorRef}
                    onInput={handleInput}
                />
            ) : (
                <Viewer>{content}</Viewer>
            )}
            <LinkUnderline
                onClick={() => setIsViewer((prev) => !prev)}
                label={isViewer ? 'Назад в редактор' : 'Посмотреть превью'}
            />
        </div>
    )
})

Editor.displayName = 'Editor'
