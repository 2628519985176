let serverDomain = ''

// Проверяем, работаем ли мы на localhost
if (
    window.location.hostname === 'localhost' &&
    window.location.port === '3000'
) {
    if (
        import.meta.env.VITE_APP_API_URL &&
        import.meta.env.VITE_APP_API_URL.length
    ) {
        serverDomain = import.meta.env.VITE_APP_API_URL
        console.log('Using VITE_APP_API_URL:', serverDomain)
    } else {
        console.warn('VITE_APP_API_URL is not defined or empty')
    }
} else {
    // Если не localhost, выбираем VITE_APP_AUTH_BASE_URL или динамически определяем домен
    if (
        import.meta.env.VITE_APP_AUTH_BASE_URL &&
        import.meta.env.VITE_APP_AUTH_BASE_URL.length
    ) {
        serverDomain = import.meta.env.VITE_APP_AUTH_BASE_URL
        console.log('Using VITE_APP_AUTH_BASE_URL:', serverDomain)
    } else {
        console.warn('VITE_APP_AUTH_BASE_URL is not defined or empty')
        const parts = window.location.host.split('.')
        serverDomain = 'https://dev.seller24.ru'
        if (parts[0] === 'app') serverDomain = 'https://app.seller24.ru'
        console.log('Dynamically determined server domain:', serverDomain)
    }
}
console.log('Final URL:', serverDomain)

// Выводим окончательный URL
export const url = serverDomain
