import classNames from 'classnames'
import {useContext} from 'react'
import {PopoverLiteContext} from './PopoverLiteProvider.jsx'

export const PopoverLiteButton = ({children, className}) => {
    const context = useContext(PopoverLiteContext)

    return (
        <div
            onClick={() => context.setIsActive(!context.isActive)}
            className={classNames('cursor-pointer', className)}
            ref={context.buttonRef}
        >
            {children}
        </div>
    )
}
