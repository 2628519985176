import {useEffect} from 'react'

// Hook
function useOnClickOutside(refs, handler, current = true) {
    const refsCurrentCheck = (ref, event) => {
        return (
            ref.current === event.target || ref.current.contains(event.target)
        )
    }

    const refsWithoutCurrentCheck = (ref, event) => {
        return ref && (ref === event.target || ref.contains(event.target))
    }

    useEffect(
        () => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (Array.isArray(refs)) {
                    if (
                        refs.some((ref) =>
                            ref?.current
                                ? refsCurrentCheck(ref, event)
                                : refsWithoutCurrentCheck(ref, event)
                        )
                    ) {
                        return
                    }
                } else if (current) {
                    if (!refs.current || refs.current.contains(event.target)) {
                        return
                    }
                } else {
                    if (!refs || refs.contains(event.target)) {
                        return
                    }
                }

                handler(event)
            }
            document.addEventListener('mousedown', listener)
            document.addEventListener('touchstart', listener)
            return () => {
                document.removeEventListener('mousedown', listener)
                document.removeEventListener('touchstart', listener)
            }
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [refs, handler]
    )
}

export {useOnClickOutside}
