import {useDebouncedFunction, useResponsive} from '@hooks'
import {useEffect, useRef} from 'react'

export const useHiddenConfirmCookies = () => {
    const isCookiesHiddenRef = useRef(null)
    const {md} = useResponsive()

    const checkMutationsList = (mutationsList, visibility) => {
        if (visibleCookies(visibility)) return
        for (const mutation of mutationsList) {
            if (mutation.type === 'childList') {
                const bumperCookiesDiv = mutation.target.querySelector(
                    '.carrotquest-css-reset-frame'
                )
                if (bumperCookiesDiv) {
                    bumperCookiesDiv.style.visibility = visibility
                    isCookiesHiddenRef.current = visibility === 'hidden'
                }
            }
        }
    }
    const visibleCookies = (visibility) => {
        const bumperCookiesDiv = document.body.querySelector(
            '.carrotquest-css-reset-frame'
        )
        if (bumperCookiesDiv) {
            bumperCookiesDiv.style.visibility = visibility
            isCookiesHiddenRef.current = visibility === 'hidden'
            return true
        }
        return false
    }
    const onHiddenCookies = (mutationsList, observer) => {
        if (
            (!md && isCookiesHiddenRef.current) ||
            (md && !isCookiesHiddenRef.current)
        )
            return

        if (md && isCookiesHiddenRef.current) {
            return visibleCookies('visible')
        }
        checkMutationsList(mutationsList, 'hidden')
    }

    const onHiddenCookiesDebounce = useDebouncedFunction(onHiddenCookies, 100)
    useEffect(() => {
        const observer = new MutationObserver(onHiddenCookiesDebounce)
        observer.observe(document.body, {
            childList: true,
            subtree: true,
        })
        return () => {
            observer.disconnect()
        }
    }, [md])
}
