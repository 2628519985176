import {format} from 'date-fns'
import {ru} from 'date-fns/locale'
import {observer} from 'mobx-react'

export const ExpiredWbAcc = observer(({credentials}) => {
    return (
        <div className={'flex flex-col'}>
            {credentials.map((cred) => {
                const expiredDate = cred.credInfo?.expired
                return (
                    <span key={cred.id}>
                        {`Необходимо обновить API - ключ кабинета ${cred.name.trim()}, чтобы получать актуальные данные. Ключ перестанет действовать  ${format(
                            new Date(expiredDate),
                            `dd.MM.yyyy`,
                            {
                                locale: ru,
                            }
                        )}.`}
                    </span>
                )
            })}
        </div>
    )
})
