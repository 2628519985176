import {useSelector} from '@hooks/mobxStoreHooks/useSelector'
import {observer} from 'mobx-react'
import {NoCredentials} from '../NoCredentials'

export const NoCredentialsWrapper = observer(({children}) => {
    const marketStore = useSelector((store) => store.marketStore)
    if (!marketStore.credentials?.length)
        return (
            <NoCredentials
                className={'overflow-y-auto py-8'}
                title={
                    'Чтобы увидеть здесь статистику, \n\
                подключите маркетплейсы'
                }
                summary={
                    'На их основе тут будут отображаться \n\
                красивые графики и полезные данные'
                }
            />
        )
    return children
})

NoCredentialsWrapper.displayName = 'NoCredentialsWrapper'
