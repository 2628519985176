import {Badge, MarketIcon, TooltipLite} from '@components/elements'
import {LockClosedIcon} from '@heroicons/react/outline'
import {default as classNames, default as cn} from 'classnames'
import {forwardRef, useRef} from 'react'
import {NavLink} from 'react-router-dom'

const Content = ({row}) => {
    return (
        <div className={'flex items-center space-x-4'}>
            <row.icon
                className={cn('mr-3 h-6 w-6 flex-shrink-0 stroke-current', {
                    'text-white': !row.blocked,
                    'text-gray-500': row.blocked,
                })}
                aria-hidden='true'
            />
            {row.name}
            {row.marketName && <MarketIcon name={row.marketName} size={20} />}
            {row.blocked && (
                <LockClosedIcon className='h-5 w-5 flex-shrink-0' />
            )}
        </div>
    )
}

const TooltipContentItem = ({row, onClick}) => {
    const {visible, disabled, blocked, name, count, warn} = row

    if (visible !== undefined && visible === false) return null

    return (
        <NavLink
            id={row.id}
            to={{
                pathname: row.href,
                //search: checkParams('godmode') ? '?godmode' : ''
            }}
            onClick={onClick}
            className={(isActive) =>
                classNames('hover:text-gray-500 dark:hover:text-gray-200', {
                    'pointer-events-none text-gray-400': disabled || blocked,
                    'text-gray-800 dark:text-gray-400':
                        !isActive && !disabled && !blocked,
                    'font-bold text-gray-900 dark:text-white':
                        isActive && !disabled && !blocked,
                })
            }
        >
            {name}
            {!!count && (
                <Badge
                    className={'ml-3'}
                    rounded
                    bright
                    color={warn ? 'red' : 'gray'}
                >
                    {count}
                </Badge>
            )}
        </NavLink>
    )
}

const NavigationSlimNavLink = forwardRef(({row, onClick}, ref) => {
    const isActiveTab = useRef(false)

    if (row.blocked) {
        return (
            <div
                ref={ref}
                className={
                    'group flex cursor-default items-center justify-center px-2 py-2 text-sm font-medium text-gray-600'
                }
            >
                <row.icon
                    className={'h-6 w-6 flex-shrink-0 stroke-current'}
                    aria-hidden='true'
                />
            </div>
        )
    }

    if (row.button) {
        return (
            <div
                id={row.id}
                ref={ref}
                onClick={onClick}
                className={classNames(
                    'group flex cursor-pointer items-center justify-center rounded-md px-2 py-2 text-sm font-medium',
                    'bg-carrotQuestBtn text-gray-300 dark:text-gray-200',
                    'hover:opacity-90'
                )}
            >
                <row.icon
                    className={classNames(
                        'h-6 w-6 flex-shrink-0 stroke-current text-white'
                    )}
                    aria-hidden='true'
                />
            </div>
        )
    }

    return (
        <NavLink
            id={row.id}
            ref={ref}
            to={{
                pathname: row.href,
                //search: checkParams('godmode') ? '?godmode' : ''
            }}
            className={(isActive) => {
                isActiveTab.current = isActive
                return classNames(
                    'group flex items-center justify-center rounded-md px-2 py-2 text-sm font-medium text-gray-500',
                    'hover:bg-gray-600 dark:hover:bg-gray-800'
                )
            }}
            activeClassName={'bg-gray-700 dark:bg-gray-600 text-white'}
            onClick={onClick}
        >
            <row.icon
                className={classNames(
                    'h-6 w-6 flex-shrink-0 stroke-current dark:group-hover:text-gray-400',
                    {
                        'text-white': isActiveTab.current,
                        'text-gray-500': !isActiveTab.current,
                    }
                )}
                aria-hidden='true'
            />
        </NavLink>
    )
})

const TooltipContent = ({row, onClick}) => {
    if (row.tooltip) {
        return `${row.name}. ${row.tooltip}`
    }
    if (!row.options) {
        return <TooltipContentItem row={row} onClick={onClick} />
    }
    return (
        <div className={'flex flex-col space-y-2'}>
            {row.options.map((option) => (
                <TooltipContentItem
                    key={option.name}
                    row={option}
                    onClick={onClick}
                />
            ))}
        </div>
    )
}

const NavigationRowSlim = ({row, onClick}) => {
    const {href, external, visible, blocked} = row

    if (!visible) return null

    if (external) {
        return (
            <a
                href={href}
                target='_blank'
                className={
                    'group flex items-center justify-center rounded-md px-2 py-2 text-sm font-medium text-gray-300 hover:bg-gray-600 dark:hover:bg-gray-800'
                }
            >
                <row.icon
                    className={'h-6 w-6 flex-shrink-0'}
                    aria-hidden='true'
                />
            </a>
        )
    }

    return (
        <TooltipLite
            offset={[0, 20]}
            isArrow={false}
            portalContainerId={'overlays'}
            content={<TooltipContent row={row} onClick={onClick} />}
        >
            {(ref) => {
                return (
                    <NavigationSlimNavLink
                        ref={ref}
                        row={row}
                        onClick={onClick}
                    />
                )
            }}
        </TooltipLite>
    )
}

export default NavigationRowSlim
