import {useState} from 'react'
import {Controller, useForm} from 'react-hook-form'

//hooks
import {useDebouncedFunction} from '@hooks/customHooks'

import {Button} from '@components/elements'
import {Loader} from '@components/elements/Loader'
import {Alert} from '@components/feedback'
import {TextField} from '@components/forms/TextFields'
import {useOnboardingContext} from '@components/navigation/header/Onboarding/context/OnboardingContext'
import {Overlay} from '@components/overlays'
import {yupResolver} from '@hookform/resolvers/yup'
import {useAnalyticEventsHook} from '@hooks/analyticEventsHook'
import {useStartField} from '@hooks/customHooks/useStartField'
import MarketService from '@services/MarketService/MarketService'
import {CONNECTED_MP_ACTIONS} from '@utils/constants'
import {geErrorMessage} from '@utils/errors/marketpalcesError'
import {keysToSnake} from '@utils/helpers'
import {regexes} from '@utils/regexes'
import * as yup from 'yup'
import FooterButtons from '../../components/FooterButtons'
import HelperLabel from '../../components/HelperLabel'
import OzonAdvHelp from './OzonAdvHelp'
import OzonHelp from './OzonHelp'

const ozonSchema = yup.object().shape({
    name: yup
        .string()
        .max(50, 'Имя личного кабинета не должно превышать 50 символов')
        .required('Имя личного кабинета не должно быть пустым'),
    clientId: yup.string().trim().required('Client Id не должен быть пустым'),
    apiKey: yup
        .string()
        .required('API key не должен быть пустым')
        .matches(regexes.withoutSpaces, 'Api-key не должен содержать пробелы'),
    advClientId: yup.string(),
    advClientSecret: yup.string(),
})

const OzonConnector = ({onCancel, onSuccess, onError, connectorData}) => {
    const [responseError, setResponseError] = useState('')
    const [helpOpen, setHelpOpen] = useState(false)
    const [advHelpOpen, setAdvHelpOpen] = useState(false)
    const [pending, setPending] = useState(false)

    const {connectedMpEvent} = useAnalyticEventsHook()
    const {onboardingStepStore} = useOnboardingContext()

    const defaultValues = {
        name: connectorData ? connectorData.name : '',
        clientId: connectorData ? connectorData.credentials.clientId : '',
        apiKey: connectorData ? connectorData.credentials.apiKey : '',
        advClientId: connectorData ? connectorData.credentials.advClientId : '',
        advClientSecret: connectorData
            ? connectorData.credentials.advClientSecret
            : '',
    }
    const {
        control,
        register,
        handleSubmit,
        formState: {dirtyFields, errors},
    } = useForm({
        resolver: yupResolver(ozonSchema),
        defaultValues: defaultValues,
    })

    const onSubmit = async (data) => {
        setPending(true)
        const id = connectorData?.id
        const body = {
            ...keysToSnake(data),
            verify: JSON.parse(import.meta.env.VITE_APP_OZON_VERYFY),
        }
        try {
            const {data} = await MarketService.postLoginOzon(body, id)
            if (onboardingStepStore.isOnboarding) {
                onboardingStepStore.setIsConnectedCabinet(true)
            }
            if (onSuccess) onSuccess(id, data)
            setPending(false)
        } catch (e) {
            if (!e?.response.data?.result && e?.response.data?.message) {
                setResponseError(geErrorMessage(e?.response.data))
            } else {
                setResponseError(geErrorMessage({code: -1}))
            }
            onError()
            setPending(false)
        }
    }

    const onSubmitDebounced = useDebouncedFunction(onSubmit, 200)

    const handleClickHelp = (open) => {
        connectedMpEvent(CONNECTED_MP_ACTIONS.open, 'Instruction')
        setHelpOpen(open)
    }

    const handleClickAdvHelp = (open) => {
        connectedMpEvent(CONNECTED_MP_ACTIONS.open, 'Instruction')
        setAdvHelpOpen(open)
    }

    const handleClickField = () => {
        connectedMpEvent(CONNECTED_MP_ACTIONS.fill, 'MP_formOzon')
    }

    const onClickField = useStartField(handleClickField)

    return (
        <form className={'w-full'}>
            {responseError && (
                <Alert error title={responseError} className={'mb-6'} />
            )}
            <Controller
                name={'name'}
                control={control}
                defaultValue={''}
                render={({field}) => (
                    <TextField
                        className={'mb-4'}
                        label={'Имя личного кабинета'}
                        {...field}
                        onClick={onClickField}
                        error={!!errors.name}
                        helperText={errors?.name?.message}
                    />
                )}
            />
            <Controller
                name={'clientId'}
                control={control}
                defaultValue={''}
                render={({field}) => (
                    <TextField
                        className={'mb-4'}
                        label={'Client Id'}
                        {...field}
                        error={!!errors.clientId}
                        helperText={errors?.clientId?.message}
                    />
                )}
            />
            <Controller
                name={'apiKey'}
                control={control}
                defaultValue={''}
                render={({field}) => (
                    <TextField
                        className={'mb-4'}
                        label={'API key'}
                        {...field}
                        onClick={onClickField}
                        error={!!errors.apiKey}
                        helperText={errors?.apiKey?.message}
                    />
                )}
            />
            <HelperLabel
                label={'Где взять Client Id и API key для Ozon?'}
                onOpen={handleClickHelp}
            />
            <div
                className={
                    'mb-6 text-blue-500 hover:text-blue-400 dark:text-blue-400 dark:hover:text-blue-300'
                }
            >
                <span className={'text-gray-800 dark:text-gray-200'}>
                    Подключить рекламный доступ
                </span>
                <div className={'mt-2 flex flex-col space-y-4'}>
                    <TextField
                        {...register('advClientId')}
                        label={'Рекламный Client ID'}
                        error={!!errors.advClientId}
                        helperText={errors?.advClientId?.message}
                    />
                    <TextField
                        {...register('advClientSecret')}
                        label={'Рекламный Client Secret'}
                        error={!!errors.advClientSecret}
                        helperText={errors?.advClientSecret?.message}
                    />
                    <HelperLabel
                        label={
                            'Где найти рекламный Client ID и рекламный Client Secret для Ozon?'
                        }
                        onOpen={handleClickAdvHelp}
                    />
                </div>
            </div>
            <FooterButtons
                connectedButton={(style) => (
                    <Button
                        className={style}
                        disabled={pending}
                        onClick={handleSubmit(onSubmitDebounced)}
                    >
                        {connectorData ? 'Сохранить' : 'Войти в аккаунт'}
                        {!!pending && <Loader sm />}
                    </Button>
                )}
                onCancel={onCancel}
            />
            <Overlay
                zIndex={1002}
                open={helpOpen}
                setOpen={setHelpOpen}
                title={'Где взять Client Id и API key для Ozon?'}
                size={'lg'}
            >
                <OzonHelp />
            </Overlay>
            <Overlay
                zIndex={1002}
                open={advHelpOpen}
                setOpen={setAdvHelpOpen}
                title={
                    'Где найти рекламный Client ID и рекламный Client Secret для Ozon?'
                }
                size={'lg'}
            >
                <OzonAdvHelp />
            </Overlay>
        </form>
    )
}

export {OzonConnector}
