import {useDropdown} from './DropdownContext'

const DropdownItem = ({
    className,
    children,
    isClosed = false,
    onClick,
    ...rest
}) => {
    const {triggerClick} = useDropdown()

    const handleOverrideClick = (e) => {
        if (isClosed) triggerClick()
        onClick(e)
    }

    return (
        <div {...rest} className={className} onClick={handleOverrideClick}>
            {children}
        </div>
    )
}

export {DropdownItem}
