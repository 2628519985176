import {Button} from '@components/elements'
import {Loader} from '@components/elements/Loader'
import {Alert} from '@components/feedback'
import {TextField} from '@components/forms/TextFields'
import {useOnboardingContext} from '@components/navigation/header/Onboarding/context/OnboardingContext'
import {Overlay} from '@components/overlays'
import {yupResolver} from '@hookform/resolvers/yup'
import {useAnalyticEventsHook} from '@hooks/analyticEventsHook'
import {useDebouncedFunction} from '@hooks/customHooks'
import {useStartField} from '@hooks/customHooks/useStartField'
import MarketService from '@services/MarketService/MarketService'
import {CONNECTED_MP_ACTIONS} from '@utils/constants'
import {regexes} from '@utils/regexes'
import {observer} from 'mobx-react'
import {useState} from 'react'
import {useForm, useWatch} from 'react-hook-form'
import * as yup from 'yup'
import FooterButtons from '../../../components/FooterButtons'
import HelperLabel from '../../../components/HelperLabel'
import WildberriesHelp from './WildberriesHelp'

const wbSchema = yup.object().shape({
    name: yup
        .string()
        .max(50, 'Имя личного кабинета не должно превышать 50 символов')
        .required('Имя личного кабинета не должно быть пустым'),
    apiKeyAnl: yup
        .string()
        .required('Api-key статистики не должен быть пустым')
        .matches(
            regexes.withoutSpaces,
            'Api-key статистики не должен содержать пробелы'
        ),
})

export const WildberriesConnector = observer(
    ({onCancel, onSuccess, onError, connectorData}) => {
        const [responseError, setResponseError] = useState('')
        const [helpOpen, setHelpOpen] = useState(false)
        const [advHelpOpen, setAdvHelpOpen] = useState(false)
        const [pending, setPending] = useState(false)
        const {connectedMpEvent} = useAnalyticEventsHook()

        const {onboardingStepStore} = useOnboardingContext()

        const defaultValues = {
            name: connectorData ? connectorData.name : '',
            apiKeyAnl: connectorData ? connectorData.credentials.apiKeyAnl : '',
        }

        const {
            register,
            clearErrors,
            handleSubmit,
            control,
            setError,
            formState: {errors},
        } = useForm({
            defaultValues,
            mode: 'all',
            resolver: yupResolver(wbSchema),
        })

        const name = useWatch({
            control,
            name: 'name',
            defaultValue: defaultValues.name,
        })

        const onSubmit = async (data) => {
            setPending(true)
            const id = connectorData?.id
            const payload = {
                name: data.name,
                api_key_anl: data.apiKeyAnl,
                api_key_oper: data.apiKeyAnl,
                api_key_adv: data.apiKeyAnl,
                verify: JSON.parse(import.meta.env.VITE_APP_WB_VERYFY),
            }
            clearErrors()
            setResponseError('')
            try {
                const {data} = await MarketService.postLoginWb(payload, id)
                if (onboardingStepStore.isOnboarding) {
                    onboardingStepStore.setIsConnectedCabinet(true)
                }
                if (onSuccess) onSuccess(connectorData?.id)
                setPending(false)
            } catch (e) {
                if (!e?.response.data?.result && e?.response.data?.message) {
                    // setResponseError(geErrorMessage(e?.response.data))
                    setError('apiKeyAnl', {message: e.response.data.message})
                } else {
                    // setResponseError(geErrorMessage({code: -1}))
                }
                if (onError) onError()
                setPending(false)
            }
        }

        const onSubmitDebounced = useDebouncedFunction(onSubmit, 200)

        const handleClickHelp = (open) => {
            connectedMpEvent(CONNECTED_MP_ACTIONS.open, 'Instruction')
            setHelpOpen(open)
        }

        const handleClickField = () => {
            connectedMpEvent(CONNECTED_MP_ACTIONS.fill, 'MP_formWildberries')
        }

        const onClickField = useStartField(handleClickField)

        return (
            <>
                <form className={'w-full'}>
                    {responseError && (
                        <Alert error title={responseError} className={'mb-6'} />
                    )}
                    <TextField
                        {...register('name')}
                        className={'mb-4'}
                        label={'Имя личного кабинета'}
                        error={!!errors.name}
                        onClick={onClickField}
                        helperText={errors?.name?.message}
                    />
                    <TextField
                        {...register('apiKeyAnl')}
                        className={'mb-4'}
                        label={'Api-key'}
                        error={!!errors.apiKeyAnl}
                        onClick={onClickField}
                        helperText={errors?.apiKeyAnl?.message}
                    />
                    {/*<TextField*/}
                    {/*    {...register('apiKeyOper')}*/}
                    {/*    className={'mb-4'}*/}
                    {/*    label={'Api-key стандартный'}*/}
                    {/*    error={!!errors.apiKeyOper}*/}
                    {/*    helperText={errors?.apiKeyOper?.message}*/}
                    {/*/>*/}
                    <HelperLabel
                        label={'Где взять API ключ для Wildberries?'}
                        onOpen={handleClickHelp}
                    />
                    {/*<div*/}
                    {/*    className={*/}
                    {/*        'mb-6 text-blue-500 hover:text-blue-400 dark:text-blue-400 dark:hover:text-blue-300'*/}
                    {/*    }*/}
                    {/*>*/}
                    {/*    <Accordion*/}
                    {/*        opened={connectorData?.credentials?.apiKeyAdv}*/}
                    {/*        triggerContent={'Подключить рекламный доступ'}*/}
                    {/*    >*/}
                    {/*        <div className={'flex flex-col space-y-4 mt-2'}>*/}
                    {/*            <TextField*/}
                    {/*                {...register('apiKeyAdv')}*/}
                    {/*                label={'Рекламный API токен'}*/}
                    {/*                error={!!errors.apiKeyAdv}*/}
                    {/*                helperText={errors?.apiKeyAdv?.message}*/}
                    {/*            />*/}
                    {/*            <HelperLabel*/}
                    {/*                label={*/}
                    {/*                    'Где найти рекламный API-ключ для Wildberries?'*/}
                    {/*                }*/}
                    {/*                onOpen={setAdvHelpOpen}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </Accordion>*/}
                    {/*</div>*/}
                    <FooterButtons
                        connectedButton={(style) => (
                            <Button
                                className={style}
                                disabled={pending}
                                onClick={handleSubmit(onSubmitDebounced)}
                            >
                                {connectorData
                                    ? 'Сохранить'
                                    : 'Войти в аккаунт'}
                                {!!pending && <Loader sm />}
                            </Button>
                        )}
                        onCancel={onCancel}
                    />
                </form>
                <Overlay
                    zIndex={1002}
                    open={helpOpen}
                    setOpen={setHelpOpen}
                    title={'Где найти API-ключ для Wildberries?'}
                    size={'lg'}
                >
                    <WildberriesHelp />
                </Overlay>
                {/*<Overlay*/}
                {/*    open={advHelpOpen}*/}
                {/*    setOpen={setAdvHelpOpen}*/}
                {/*    title={'Где найти рекламный API-ключ для Wildberries?'}*/}
                {/*    size={'lg'}*/}
                {/*>*/}
                {/*    <WildberriesAdvHelp />*/}
                {/*</Overlay>*/}
            </>
        )
    }
)

WildberriesConnector.displayName = 'WildberriesConnector'
