import {Mdash} from '@components/elements'

import {format, isValid} from 'date-fns'
import {ru} from 'date-fns/locale'

export const ImportWbDateCreated = ({date, isExpanded}) => {
    return (
        <span>
            {date && isValid(new Date(date)) ? (
                format(
                    new Date(date),
                    isExpanded ? 'yyyy-MM-dd' : "yyyy-MM-dd kk':'mm':'ss",
                    {
                        locale: ru,
                    }
                )
            ) : (
                <Mdash />
            )}
        </span>
    )
}
