import {getMonthAgo} from '@components/forms/DatePicker/DateRangePicker'
import {differenceInDays, format, parseISO} from 'date-fns'

export const requestDateFormat = (date) => {
    return format(date, 'yyyy-MM-dd')
}

export const isExpiringSoon = (expiredDate) => {
    const currentDate = new Date()
    const expired = parseISO(expiredDate)

    const daysDifference = differenceInDays(expired, currentDate)

    return daysDifference <= 10
}

export const initDateRange = {
    startDate: getMonthAgo(),
    endDate: new Date(),
}
