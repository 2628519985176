import {ImagesPreview} from '@components/elements'
import Screen1 from './images/screen-1.jpg'
import Screen2 from './images/screen-2.png'
import Screen3 from './images/screen-3.jpg'

const OzonHelp = () => {
    return (
        <ImagesPreview>
            <div className={'w-full'}>
                <h2 className={'mb-4 text-xl font-bold dark:text-gray-100'}>
                    1. Client Id
                </h2>
                <p className={'mb-4 dark:text-gray-200'}>
                    Его вы найдете, перейдя по ссылке:
                    <br />
                    <a
                        className={'text-blue-500 hover:text-blue-400'}
                        href={'//seller.ozon.ru/app/settings/api-keys'}
                        target={'_blank'}
                    >
                        seller.ozon.ru/app/settings/api-keys
                    </a>{' '}
                    <br />
                    Скопируйте client id
                </p>
                <img src={Screen1} className={'mb-6'} />
                <h2 className={'mb-4 text-xl font-bold dark:text-gray-100'}>
                    2. API key
                </h2>
                <p className={'mb-4 dark:text-gray-200'}>
                    Его вы найдете, перейдя по ссылке:
                    <br />
                    <a
                        className={'text-blue-500 hover:text-blue-400'}
                        href={'//seller.ozon.ru/app/settings/api-keys'}
                        target={'_blank'}
                    >
                        seller.ozon.ru/app/settings/api-keys
                    </a>{' '}
                    <br />
                    Выберите Тип токена - Admin read only и введите Название
                    ключа. Например: Новый-ключ
                </p>
                <p className={'mb-4 dark:text-gray-200'}>
                    Нажмите кнопку "Сгенерировать"
                </p>
                <img src={Screen2} className={'mb-6'} />
                <h2 className={'mb-4 text-xl font-bold dark:text-gray-100'}>
                    3. Скопируйте сгенерированный ключ
                </h2>
                <img src={Screen3} className={'mb-6'} />
            </div>
        </ImagesPreview>
    )
}

export default OzonHelp
