import {OnboardingUrl} from '@routesEnums'
import {baseConfig} from '@services/apiConfigs'
import {ApiFactory} from '@services/ApiFactory'

class OnboardingService {
    static instance = ApiFactory.createApi(baseConfig)
    static postNeedAudit = async (payload) => {
        return await OnboardingService.instance.post(
            OnboardingUrl.needAudit,
            payload
        )
    }
    static putOnboardSetStep = async (payload) => {
        return await OnboardingService.instance.put(
            OnboardingUrl.onboardSetStep,
            payload
        )
    }
    static getOnboardCurrentStep = async () => {
        return await OnboardingService.instance.get(
            OnboardingUrl.onboardCurrentStep
        )
    }
}

export default OnboardingService
