import {Seller24Icon} from '@components/elements/Icon/Icon'
import {SwitchControl} from '@components/navigation/sidebar/components/SwitchControl'
import {Link} from 'react-router-dom'
import NavigationRow from '../components/NavigationRow'

const SidebarDesktop = ({navigation, toggleSidebar, homePage}) => {
    return (
        <div
            className={
                'hidden transition-all md:fixed md:inset-y-0 md:flex md:w-64 md:flex-shrink-0 md:flex-col'
            }
        >
            <div className='relative flex flex-grow flex-col overflow-y-auto bg-gray-800 dark:bg-gray-700'>
                <Link
                    to={homePage}
                    className='sticky top-0 flex flex-shrink-0 cursor-pointer items-center bg-gray-800 px-4 pb-8 pt-5 dark:bg-gray-700'
                >
                    <Seller24Icon className='h-7 w-auto fill-current text-white' />
                </Link>
                <div className='flex flex-1 flex-col'>
                    <nav className='flex-1 space-y-1 px-2 pb-4'>
                        {Object.keys(navigation).map((key, index) => (
                            <React.Fragment key={key}>
                                {navigation[key].map((row) => (
                                    <NavigationRow
                                        key={row.name}
                                        row={row}
                                        onClick={row.onClick}
                                    />
                                ))}
                                {index < Object.keys(navigation).length - 1 && (
                                    <div key={index} className={'w-full p-2'}>
                                        <div
                                            className={
                                                'border-b border-gray-900'
                                            }
                                        />
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    </nav>
                </div>
                <SwitchControl toggleSidebar={toggleSidebar} />
            </div>
        </div>
    )
}

export {SidebarDesktop}
