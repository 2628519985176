import {Loader} from '@components/elements/Loader'
import {CheckIcon} from '@heroicons/react/outline'
import {XCircleIcon} from '@heroicons/react/solid'
import {useSelector} from '@hooks/mobxStoreHooks/useSelector'
import * as qs from 'qs'
import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'

export const ProvidersConfirm = ({providerName}) => {
    const [token, setToken] = useState('')
    const [error, setError] = useState(false)
    const {onLocationChange} = useSelector(
        (store) => store.authStore.stores.providersStore
    )
    const location = useLocation()

    useEffect(() => {
        if (location.search) {
            getStateFromUrl()
        } else {
            setError(true)
        }
    }, [])

    const getStateFromUrl = () => {
        console.log(location.search)
        try {
            const {state, code} = qs.parse(location.search, {
                ignoreQueryPrefix: true,
            })
            setToken(code)
            //onLocationChange(providerName, code)
            localStorage.setItem('provider_code', code)
            localStorage.setItem('provider_name', providerName)
        } catch (e) {
            setError(true)
        }
    }

    return (
        <div className={'flex w-full flex-col items-center justify-center'}>
            {!token && !error && <Loader overlayed={false} />}
            {token && error && (
                <>
                    <XCircleIcon
                        className='h-20 w-20 text-red-400 dark:text-red-300'
                        aria-hidden='true'
                    />
                    <span>{`Авторизация прошла с ошибкой. Закройте страницу и попробуйте снова`}</span>
                </>
            )}{' '}
            {token && !error && (
                <>
                    <CheckIcon className={'h-20 w-20'} />
                    <span>{`Данные для авторизации через ${providerName} получены успешно`}</span>
                </>
            )}
        </div>
    )
}
