import {Modal} from '@components/overlays'

import {NotificationDialog} from './components/NotificationDialog'

export const NotificationModal = ({onClose, open, ...props}) => {
    return (
        <Modal className={'sm:max-w-lg'} open={open} onClose={onClose}>
            <NotificationDialog {...props} />
        </Modal>
    )
}
