import {Skeleton} from '@components/elements'
import {Loader} from '@components/elements/Loader'
import {useResponsive} from '@hooks'
import {marketplaceId} from '@utils/constants'
import classNames from 'classnames'
import {formatDistanceToNowStrict, isValid as isValidDate} from 'date-fns'
import {ru} from 'date-fns/locale'
import {observer} from 'mobx-react'

const PARSE_TYPE_MAP = {
    reports: 'Заказы',
    products: 'Товары',
    stocks: 'Остатки',
    create: 'Данные',
    adv: 'Рекламные расходы',
    agg: 'Агрегации данных',
    wb_xls: 'Загрузка данных из Excel',
}

const MarketplaceSync = observer(({isValid = false, statuses, marketId}) => {
    const {sm} = useResponsive()

    const getStatusLabel = (item) => {
        switch (item.status) {
            case 10: {
                return (
                    <span>
                        {isValidDate(new Date(item.lastRun))
                            ? `${
                                  PARSE_TYPE_MAP[item.parseType]
                              } синхронизированы ${formatDistanceToNowStrict(
                                  new Date(item.lastRun),
                                  {locale: ru}
                              )} назад`
                            : 'Неизвестно'}
                    </span>
                )
            }
            case 9: {
                if (item.parseType === 'create') {
                    return (
                        <span className={'text-red-500'}>
                            Ошибка синхронизации данных
                        </span>
                    )
                }
                return (
                    <span className={'text-red-500'}>
                        {`${
                            PARSE_TYPE_MAP[item.parseType]
                        } - ошибка синхронизации`}
                    </span>
                )
            }
            case 0: {
                return (
                    <span>{`${
                        PARSE_TYPE_MAP[item.parseType]
                    } синхронизируются`}</span>
                )
            }
        }
    }
    return (
        <>
            {sm ? (
                <div
                    className={classNames(
                        'flex min-w-[400px] flex-shrink-0 items-start',
                        {
                            'justify-end': !statuses?.statuses?.length,
                        }
                    )}
                >
                    <div className={'flex w-full flex-col'}>
                        <span
                            className={`text-md flex items-center font-medium ${
                                isValid
                                    ? 'text-green-400 dark:text-green-300'
                                    : 'text-red-400 dark:text-red-300'
                            }`}
                        >
                            <span
                                className={`mr-2 inline-block h-2 w-2 rounded-full ${
                                    isValid
                                        ? 'bg-green-400 dark:bg-green-300'
                                        : 'bg-red-400 dark:bg-red-300'
                                }`}
                            />
                            {isValid ? 'Подключён' : 'Не подключён'}
                        </span>
                        {statuses?.statuses ? (
                            statuses?.statuses?.map((item) => {
                                return (
                                    <span
                                        key={item.parseType}
                                        className={`text-xs text-gray-600 dark:text-gray-400`}
                                    >
                                        <span
                                            className={
                                                'relative flex items-center'
                                            }
                                        >
                                            <span
                                                className={
                                                    'mr-2 w-2 flex-shrink-0'
                                                }
                                            >
                                                {!item.status && (
                                                    <Loader
                                                        xs
                                                        overlayed={false}
                                                    />
                                                )}
                                            </span>
                                            {getStatusLabel(item)}
                                        </span>
                                    </span>
                                )
                            })
                        ) : marketId !== marketplaceId.Demo ? (
                            <>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </>
                        ) : null}
                    </div>
                </div>
            ) : (
                <div className={'flex flex-shrink-0 items-center'}>
                    <div
                        className={`ml-2 h-2 w-2 rounded-full ${
                            isValid
                                ? 'bg-green-400 dark:bg-green-300'
                                : 'bg-red-400 dark:bg-red-300'
                        }`}
                    />
                    {/*<ShoppingBagIcon*/}
                    {/*    className={`h-4 w-4 ml-2 ${*/}
                    {/*        productSync*/}
                    {/*            ? 'text-green-400 dark:text-green-300'*/}
                    {/*            : 'text-yellow-500 dark:text-yellow-400'*/}
                    {/*    }`}*/}
                    {/*/>*/}
                    {/*<ShoppingCartIcon*/}
                    {/*    className={`h-4 w-4 ml-1 ${*/}
                    {/*        orderSync*/}
                    {/*            ? 'text-green-400 dark:text-green-300'*/}
                    {/*            : 'text-yellow-500 dark:text-yellow-400'*/}
                    {/*    }`}*/}
                    {/*/>*/}
                </div>
            )}
        </>
    )
})

MarketplaceSync.displayName = 'MarketplaceSync'

export default MarketplaceSync
