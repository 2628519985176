import {useSelector} from '@hooks/mobxStoreHooks/useSelector'
import {observer} from 'mobx-react'
import {SkeletonTheme as SkeletonThemeWrapper} from 'react-loading-skeleton'

export const SkeletonTheme = observer(({children}) => {
    const {isDarkTheme} = useSelector((store) => store.themeStore)
    return (
        <SkeletonThemeWrapper
            baseColor={isDarkTheme ? '#4b5563' : '#e5e7eb'}
            highlightColor={isDarkTheme ? '#9ca3af' : '#fff'}
            borderRadius='0.3rem'
            duration={1.5}
        >
            {children}
        </SkeletonThemeWrapper>
    )
})
