import {wbFilter} from '@utils/helpers'
import {makeAutoObservable, reaction} from 'mobx'
import {SelectedStore} from '../../../../../../../store/SelectStore'

export class WbImportFiltersStore {
    constructor(cabinet, marketStore) {
        this.marketStore = marketStore
        this.initialCabinet(cabinet, marketStore.credentialsOptions)
        makeAutoObservable(this)

        this.disposer = reaction(
            () => this.cabinet.selected,
            () => {
                this.setIsLegalEntityError(false)
                this.setLegalEntity('')
            }
        )
    }
    cabinet = new SelectedStore()

    legalEntity = ''
    isLegalEntityError = false

    setLegalEntity = (value) => {
        this.legalEntity = value
    }

    setIsLegalEntityError = (value) => {
        this.isLegalEntityError = value
    }

    initialCabinet = (selectedCabinet, credentialsOptions) => {
        const cabinet = credentialsOptions.filter(wbFilter)
        this.cabinet.setOptions(cabinet)
        this.cabinet.setSelectedItem(selectedCabinet)
    }

    get savedLegalEntity() {
        const cabinet = this.marketStore.getCredentialByID(
            this.cabinet.selected?.key
        )
        if (!this.validLegalEntity) return this.legalEntity
        return cabinet?.companyName || this.legalEntity
    }

    get validLegalEntity() {
        const cabinet = this.marketStore.getCredentialByID(
            this.cabinet.selected?.key
        )
        return !!cabinet?.companyValid
    }

    destroy = () => {
        this.disposer()
    }
}
