import cn from 'classnames'

import {Button} from '@components/elements/Buttons'
import {NoAvatarIcon, WhatsappIcon} from '@components/elements/Icon/Icon'
import PhoneIcon from '@heroicons/react/outline/PhoneIcon'
import FeedbackService from '@services/FeedbackService/FeedbackService'
import {SUCCESS_SUPPORT_MESSAGE_SEND} from '@utils/constants'
import {getName} from '@utils/helpers'
import classNames from 'classnames'
import {toast} from 'react-toastify'

export const ManagerCard = ({manager, user}) => {
    const requestCall = async () => {
        const payload = {
            to: 'support@seller24.ru',
            request_type: 'request_manager_call',
            title: `Пользователь запросил звонок менеджера из платформы`,
            fields: [
                {
                    title: 'phone',
                    value: user?.phoneNumber?.replace(/[ \-()]/g, ''),
                },
                {
                    title: 'email',
                    value: user?.email || '',
                },
            ],
        }
        try {
            await FeedbackService.postMessage(payload)
            toast(SUCCESS_SUPPORT_MESSAGE_SEND, {
                type: 'success',
                autoClose: 10000,
            })
        } catch (e) {
            console.log(e)
            toast('Заявка на звонок отправлена с ошибкой', {type: 'error'})
        }
    }
    const handleOpenChatTg = () => {
        if (
            window.carrotquest &&
            typeof window.carrotquest.openMessenger === 'function'
        ) {
            window.carrotquest.openMessenger()
        }
    }
    const handleOpenChatWhatsapp = () => {
        if (
            window.carrotquest &&
            typeof window.carrotquest.openMessenger === 'function'
        ) {
            window.carrotquest.openMessenger()
        }
    }

    return (
        <div
            className={cn(
                'z-[9999] overflow-hidden rounded-lg border border-gray-100 bg-white p-4 shadow-lg',
                'dark:border-gray-600 dark:bg-gray-700'
            )}
        >
            <div className={'flex flex-col space-y-4 divide-y'}>
                <div className={'flex space-x-4'}>
                    {manager.photo ? (
                        <img
                            className={'h-16 w-16 rounded object-cover'}
                            src={manager.photo}
                            alt={'ава'}
                        />
                    ) : (
                        <div>
                            <NoAvatarIcon
                                className={classNames(
                                    'h-16 w-16 rounded object-cover'
                                )}
                            />
                        </div>
                    )}
                    <div
                        className={
                            'flex flex-col text-gray-700 dark:text-gray-300'
                        }
                    >
                        <span
                            className={
                                'font-medium text-gray-800 dark:text-gray-200'
                            }
                        >
                            {getName(manager.firstName, manager.lastName)}
                        </span>
                        <a
                            className={
                                'cursor-pointer whitespace-pre text-sm font-medium text-blue-500 hover:text-blue-400 dark:text-blue-300 dark:hover:text-blue-200'
                            }
                            href={`mailto:${manager.email}`}
                        >
                            {manager.email}
                        </a>
                        <div className={'mt-1 flex space-x-2'}>
                            {manager.whatsappPhone && (
                                <a
                                    href={`https://chatapp.online/wa-redirect/?phone=${manager.whatsappPhone.replace(
                                        '+',
                                        ''
                                    )}`}
                                    target={'_blank'}
                                >
                                    <WhatsappIcon
                                        className={
                                            'h-4 w-4 cursor-pointer stroke-current text-gray-800 dark:text-gray-200 dark:hover:text-green-500'
                                        }
                                    />
                                </a>
                            )}
                        </div>
                    </div>
                </div>
                <div className={'flex flex-col space-y-2 pt-2'}>
                    <div className={'flex flex-col space-y-1'}>
                        <div
                            className={
                                'flex items-center space-x-2 font-medium text-gray-800 dark:text-gray-200'
                            }
                        >
                            <PhoneIcon
                                className={
                                    'h-4 w-4 text-gray-800 dark:text-gray-200'
                                }
                            />
                            <span>{manager.workPhone || 'Отсутствует'}</span>
                        </div>
                        <div
                            className={
                                'flex space-x-2 text-xs text-gray-700 dark:text-gray-300'
                            }
                        >
                            <span>Добавочный номер:</span>
                            <span>{manager.subPhone || 'Отсутствует'}</span>
                        </div>
                    </div>
                    <Button onClick={requestCall}>Заказать звонок</Button>
                </div>
            </div>
        </div>
    )
}
