import {PopoverLite} from '@components/elements/PopoverLite'
import classNames from 'classnames'

export const SortModeDropdown = ({
    sortedItem,
    children,
    className,
    onChangeMode,
}) => {
    const renderElement = (mode, dir) => {
        const dirText = dir === 'asc' ? 'по возрастанию' : 'по убыванию'
        const isActive =
            mode === 'default'
                ? sortedItem.currentMode === 'default'
                : sortedItem.currentMode !== 'default' &&
                  sortedItem.currentMode &&
                  sortedItem.sortModes.find(
                      (otherMode) => otherMode.key === sortedItem.currentMode
                  ).config.name === mode.config.name &&
                  dir === sortedItem.dir
        return (
            <div
                className={classNames(
                    'min-h-8 text-[11px] font-normal normal-case flex items-center pl-3 pr-0.5 text-left',
                    'hover:bg-gray-300 dark:hover:bg-gray-900 cursor-pointer rounded-sm',
                    {
                        'text-blue-500 dark:text-blue-400': isActive,
                        'text-gray-700 dark:text-gray-400': !isActive,
                    }
                )}
                onClick={() =>
                    onChangeMode(
                        mode && mode !== 'default' ? mode.key : mode,
                        dir
                    )
                }
                key={`${mode} ${dir}`}
            >
                {mode !== 'default'
                    ? `${mode.config.name}: ${dirText}`
                    : 'По умолчанию'}
            </div>
        )
    }

    return (
        <PopoverLite withinPortal>
            <PopoverLite.Button className={className}>
                {children}
            </PopoverLite.Button>
            <PopoverLite.Panel
                className={classNames(
                    'absolute z-[9999] overflow-hidden rounded-lg border border-gray-100 bg-white shadow-lg',
                    'dark:border-gray-600 dark:bg-gray-700 w-[180px] p-1 select-none right-0 top-8'
                )}
            >
                {[
                    renderElement('default', 'desc'),
                    sortedItem.sortModes.map((mode) => [
                        renderElement(mode, 'asc'),
                        renderElement(mode, 'desc'),
                    ]),
                ]}
            </PopoverLite.Panel>
        </PopoverLite>
    )
}
