import classNames from 'classnames'

export const ModalFooter = ({children, className}) => {
    return (
        <div
            className={classNames(
                className,
                'mt-6 flex w-full items-center justify-between border-t border-gray-200 pt-4 dark:border-gray-600'
            )}
        >
            {children}
        </div>
    )
}
