import {Button} from '@components/elements/Buttons'
import {AppPath} from '@routesEnums'
import cn from 'classnames'
import {Link} from 'react-router-dom'

const NotificationPage = ({
    className,
    icon,
    title,
    description,
    link = AppPath.marketplaces,
    linkLabel,
    linkOnClick = () => {},
}) => {
    return (
        <div
            className={cn(
                className,
                'min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8'
            )}
        >
            <div className='mx-auto max-w-max'>
                <main className='sm:flex'>
                    {icon}
                    <div className='mt-4 sm:ml-6 sm:mt-0'>
                        <div className='text-center sm:border-l sm:border-gray-200 sm:pl-6 sm:text-left'>
                            <h2 className='text-2xl font-extrabold tracking-tight text-gray-900 dark:text-gray-100 sm:text-3xl'>
                                {title}
                            </h2>
                            <p className='mt-1 text-base text-gray-500 dark:text-gray-300'>
                                {description}
                            </p>
                        </div>
                        <div className='mt-5 flex w-full space-x-3 sm:mt-10 sm:w-[fit-content] sm:border-l sm:border-transparent sm:pl-6'>
                            <Link
                                to={link}
                                className={'w-full'}
                                onClick={linkOnClick}
                            >
                                <Button className={'w-full'} color={'primary'}>
                                    {linkLabel}
                                </Button>
                            </Link>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export {NotificationPage}
