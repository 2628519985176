import {ImagesPreview} from '@components/elements'
import Screen1 from './images/screen-1.jpg'
import Screen2 from './images/screen-2.jpg'
import Screen3 from './images/screen-3.jpg'
import Screen4 from './images/screen-4.jpg'

const YandexHelp = () => {
    return (
        <ImagesPreview>
            <div className={'w-full'}>
                <h2 className={'mb-4 text-xl font-bold dark:text-gray-100'}>
                    1. Для подключения Яндекс Маркета нажмите на кнопку
                    «Авторизоваться».
                </h2>
                <p className={'mb-4 dark:text-gray-200'}>
                    <img src={Screen1} className={'mb-6'} alt={'Screen1'} />
                </p>
                <h2 className={'mb-4 text-xl font-bold dark:text-gray-100'}>
                    2. Авторизация должна быть под почтой, у которой есть доступ
                    к магазину и настройкам API, роль владелец магазина или
                    администратор.
                </h2>
                <p className={'mb-4 dark:text-gray-200'}>
                    <img src={Screen2} className={'mb-6'} alt={'Screen2'} />
                </p>
                <h2 className={'mb-4 text-xl font-bold dark:text-gray-100'}>
                    3. В выпадающем списке выберите магазин/ модель продажи,
                    который хотите подключить. Имя личного кабинета заполнится
                    автоматически. При необходимости Вы сможете его
                    редактировать.
                </h2>
                <p className={'mb-4 dark:text-gray-200'}>
                    <img src={Screen3} className={'mb-6'} alt={'Screen3'} />
                </p>
                <h2 className={'mb-4 text-xl font-bold dark:text-gray-100'}>
                    4. Укажите ссылку на ваш магазин на Яндекс Маркете:
                    перейдите на витрину магазина, скопируйте полностью всю
                    ссылку и вставьте в платформу Seller24
                </h2>
                <p className={'mb-4 dark:text-gray-200'}>
                    <img src={Screen4} className={'mb-6'} alt={'Screen4'} />
                </p>
                <h2 className={'mb-4 text-xl font-bold dark:text-gray-100'}>
                    5. Нажмите подключить
                </h2>
                <h2 className={'mb-4 text-xl font-bold dark:text-gray-100'}>
                    6. Для каждой модели продажи необходимо производить
                    отдельное подключение.
                </h2>
            </div>
        </ImagesPreview>
    )
}

export default YandexHelp
