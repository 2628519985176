import {Button} from '@components/elements'
import {ModalFooter} from '@components/overlays/modals/ModalFooter'
import {observer} from 'mobx-react'

import {AppPath} from '@routesEnums'
import {useHistory} from 'react-router-dom'
import {useAuditContext} from '../../context/AuditContext'

export const DontAudit = observer(({onClose}) => {
    const {auditStore} = useAuditContext()
    const history = useHistory()
    const handleClose = () => {
        auditStore.modalControl.close()
        typeof onClose === 'function' && onClose()
        history.push(AppPath.marketplaces)
    }
    return (
        <>
            <div
                className={
                    'flex flex-col gap-y-4 text-gray-600 dark:text-gray-400'
                }
            >
                <p>
                    Отлично. Подключите кабинеты маркетплейсов и начните работу
                    с отчетами:
                </p>
                <ol className='list-disc space-y-2 pl-5'>
                    <li>
                        Посчитайте маржинальность и узнайте, сколько вы
                        зарабатываете или теряете на каждом товаре;
                    </li>
                    <li>
                        Сделайте ABC-анализ ассортимента и проанализируйте
                        оборачиваемость. Так вы узнаете, какие товары приносят
                        больше всего выручки, а какие неликвидны;
                    </li>
                    <li>
                        Получите список товаров, которые срочно нужно
                        допоставить. Это поможет не упустить выручку;
                    </li>
                    <li>
                        Узнайте, на какие региональные склады пора делать
                        поставки, чтобы увеличить продажи.
                    </li>
                </ol>
            </div>
            <ModalFooter>
                <Button onClick={handleClose}>Подключить кабинеты</Button>
            </ModalFooter>
        </>
    )
})

DontAudit.displayName = 'DontAudit'
