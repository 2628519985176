export const firstStepsPlatform = `
Вы успешно подключили свой кабинет и теперь готовы к работе с платформой!
Перед использованием инструментов советуем посмотреть несколько коротких
обучающих видео, которые помогут вам быстрее разобраться с функционалом

Видео 1: [Маржинальная прибыль: как её считать?](https://rutube.ru/video/841b0e1c94b141d4cdac52e718834aac/?t=0)

Видео 2: [Как посчитать Unit-экономику и получать прибыль на маркетплейсах  ](https://rutube.ru/video/7a611b930366554477621052f2029b9a/?t=0)
`

export const successRegistration = `Чтобы начать пользователься платформой,
 вам нужно подключить свой кабинет (или несколько). 
 Для этого нажмите "подключить" под нужным кабинетом и следуйте инструкциям!`
