import {useEffect, useState} from 'react'
import {usePopper} from 'react-popper'

export const useDropdownConfig = ({
    popperOptions,
    placement = 'bottom',
    active = false,
    offset = [0, 10],
}) => {
    const [isOpen, setIsOpen] = useState(active)
    const [referenceElement, setReferenceElement] = useState(null)
    const [popperElement, setPopperElement] = useState(null)
    const ANIMATION_DURATION = 100
    const {styles, attributes} = usePopper(
        referenceElement,
        popperElement,
        Object.assign(
            {
                placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset,
                        },
                    },
                    {
                        name: 'flip',
                        enabled: true,
                        options: {
                            fallbackPlacements: [
                                'top',
                                'bottom',
                                'left',
                                'right',
                            ],
                        },
                    },
                ],
            },
            popperOptions
        )
    )

    const onClickOutsideHandler = (event) => {
        if (
            referenceElement &&
            !referenceElement.contains(event.target) &&
            popperElement &&
            !popperElement.contains(event.target)
        ) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', onClickOutsideHandler)
        return () => {
            document.removeEventListener('mousedown', onClickOutsideHandler)
        }
    }, [referenceElement, popperElement])

    useEffect(() => {
        setIsOpen(active)
    }, [active])

    const onTriggerClick = () => {
        setIsOpen(!isOpen)
    }

    return {
        setIsOpen,
        onTriggerClick,
        setReferenceElement,
        isOpen,
        ANIMATION_DURATION,
        setPopperElement,
        styles,
        attributes,
    }
}
