import {XIcon} from '@heroicons/react/outline'

const ModalHeader = ({title, onCancel}) => {
    return (
        <div
            className={
                'mb-6 flex w-full items-center justify-between border-b border-gray-200 pb-4 dark:border-gray-600'
            }
        >
            <h3
                className={
                    'text-xl font-medium text-gray-800 dark:text-gray-100'
                }
            >
                {title}
            </h3>
            <XIcon
                className={
                    'h-6 w-6 cursor-pointer text-gray-600 dark:text-gray-400'
                }
                onClick={onCancel}
            />
        </div>
    )
}

export {ModalHeader}
