import {action, makeObservable, observable} from 'mobx'

class AdminPartnersStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            partners: observable,
            pagination: observable,
            pending: observable,
            tableValues: observable,
            setTableValues: action,
            updatePartnersItem: action,
        })
    }

    partners = [
        {
            id: 0,
            ref_id: 'test19052022',
            registrations: '1',
            discount_percentages: '15',
            discount_rubles: '',
            discount_date: '18.05.2022',
            controls: '',
        },
        {
            id: 1,
            ref_id: 'ecomexpo',
            registrations: '1',
            discount_percentages: '30',
            discount_rubles: '',
            discount_date: '27.06.2022',
            controls: '',
        },
        {
            id: 2,
            ref_id: 'test',
            registrations: '0',
            discount_percentages: '50',
            discount_rubles: '200',
            discount_date: '17.06.2022',
            controls: '',
        },
        {
            id: 3,
            ref_id: 'test19052022',
            registrations: '1',
            discount_percentages: '15',
            discount_rubles: '',
            discount_date: '18.05.2022',
            controls: '',
        },
        {
            id: 4,
            ref_id: 'ecomexpo',
            registrations: '1',
            discount_percentages: '30',
            discount_rubles: '',
            discount_date: '27.06.2022',
            controls: '',
        },
        {
            id: 5,
            ref_id: 'test',
            registrations: '0',
            discount_percentages: '50',
            discount_rubles: '',
            discount_date: '17.06.2022',
            controls: '',
        },
        {
            id: 6,
            ref_id: 'test19052022',
            registrations: '1',
            discount_percentages: '15',
            discount_rubles: '',
            discount_date: '18.05.2022',
            controls: '',
        },
        {
            id: 7,
            ref_id: 'ecomexpo',
            registrations: '1',
            discount_percentages: '30',
            discount_rubles: '',
            discount_date: '27.06.2022',
            controls: '',
        },
        {
            id: 8,
            ref_id: 'test',
            registrations: '0',
            discount_percentages: '50',
            discount_rubles: '',
            discount_date: '17.06.2022',
            controls: '',
        },
    ]
    pagination = {
        page: 1,
        total: 9,
        totalPages: 1,
        limit: 10,
    }

    pending = false

    tableValues = Object.fromEntries(
        this.partners.map((partner) => [
            partner.id,
            {
                discount_percentages: partner.discount_percentages,
                discount_rubles: partner.discount_rubles,
                discount_date: partner.discount_date,
            },
        ])
    )

    setTableValues = (id, field, value) => {
        this.tableValues[id][field] = value
    }

    updatePartnersItem = (id) => {
        console.log(this.tableValues[id])
    }
}

export default AdminPartnersStore
