import {
    addDays,
    addMonths,
    differenceInCalendarDays,
    endOfDay,
    endOfMonth,
    endOfWeek,
    isSameDay,
    startOfDay,
    startOfMonth,
    startOfWeek,
} from 'date-fns'

const staticRangeHandler = {
    range: {},
    isSelected(range) {
        const definedRange = this.range()
        return (
            isSameDay(range.startDate, definedRange.startDate) &&
            isSameDay(range.endDate, definedRange.endDate)
        )
    },
}

const createStaticRanges = (ranges) => {
    return ranges.map((range) => ({...staticRangeHandler, ...range}))
}

const defineds = {
    startOfWeek: startOfWeek(new Date(), {weekStartsOn: 1}),
    endOfWeek: new Date(),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7), {weekStartsOn: 1}),
    endOfLastWeek: endOfDay(
        endOfWeek(addDays(new Date(), -7), {weekStartsOn: 1})
    ),
    startOfToday: startOfDay(new Date()),
    endOfToday: new Date(),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: new Date(),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfDay(endOfMonth(addMonths(new Date(), -1))),
}

export const customStaticRanges = createStaticRanges([
    {
        label: 'Сегодня',
        range: () => ({
            startDate: defineds.startOfToday,
            endDate: defineds.endOfToday,
        }),
    },
    {
        label: 'Вчера',
        range: () => ({
            startDate: defineds.startOfYesterday,
            endDate: defineds.endOfYesterday,
        }),
    },

    {
        label: 'На этой неделе',
        range: () => ({
            startDate: defineds.startOfWeek,
            endDate: defineds.endOfWeek,
        }),
    },
    {
        label: 'На прошлой неделе',
        range: () => ({
            startDate: defineds.startOfLastWeek,
            endDate: defineds.endOfLastWeek,
        }),
    },
    {
        label: 'В этом месяце',
        range: () => ({
            startDate: defineds.startOfMonth,
            endDate: defineds.endOfMonth,
        }),
    },
    {
        label: 'В прошлом месяце',
        range: () => ({
            startDate: defineds.startOfLastMonth,
            endDate: defineds.endOfLastMonth,
        }),
    },
])

export const customInputRanges = [
    {
        label: 'дней до сегодня',
        range(value) {
            return {
                startDate: addDays(
                    defineds.startOfToday,
                    (Math.max(Number(value), 1) - 1) * -1
                ),
                endDate: defineds.endOfToday,
            }
        },
        getCurrentValue(range) {
            if (!isSameDay(range.endDate, defineds.endOfToday)) return '-'
            if (!range.startDate) return '∞'
            return (
                differenceInCalendarDays(defineds.endOfToday, range.startDate) +
                1
            )
        },
    },
]
