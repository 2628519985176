import {ImportMarketOnboard} from '@components/navigation/header/Onboarding/pages/ImportMarketOnboard'
import {observer} from 'mobx-react'

const ONBOARD_PAGE = {
    // [ONBOARD_STEP.mpAdded]: MarketOnboard,
    // [ONBOARD_STEP.mpImportComplete]: ImportMarketOnboard,
    // [ONBOARD_STEP.costsAdded]: CoastOnboard,
    // [ONBOARD_STEP.profitChecked]: MarginOnboard,
    // [ONBOARD_STEP.unitChecked]: UnitOnboard,
    // [ONBOARD_STEP.abcChecked]: AbcOnboard,
    // [ONBOARD_STEP.forecastChecked]: SupplyForecastOnboard,
    // [ONBOARD_STEP.regionalChecked]: SalesRegionOnboard,
    // [ONBOARD_STEP.finalClosed]: EndOnboard,
}

export const Onboarding = observer(({onboardingStepStore}) => {
    // const Component = useMemo(() => {
    //     return ONBOARD_PAGE[onboardingStepStore.step]
    // }, [onboardingStepStore.step])
    return (
        <div>
            {/*<OnboardingWrapper onClose={onboardingStepStore.onOpenConfirmClose}>*/}
            <ImportMarketOnboard onboardingStepStore={onboardingStepStore} />
            {/*</OnboardingWrapper>*/}
            {/*<ModalConfirm*/}
            {/*    open={onboardingStepStore.confirmCloseModalControl.isOpen}*/}
            {/*    title={`Обучение будет отключено!`}*/}
            {/*    description={*/}
            {/*        'Вы действительно хотите пропустить вводное обучение?'*/}
            {/*    }*/}
            {/*    accept={'Пропустить'}*/}
            {/*    decline={'Вернуться'}*/}
            {/*    onClose={onboardingStepStore.confirmCloseModalControl.close}*/}
            {/*    onAccept={onboardingStepStore.onClose}*/}
            {/*/>*/}
        </div>
    )
})

Onboarding.displayName = 'Onboarding'
