import cn from 'classnames'
import {ModalOverlay} from './ModalOverlay'

export const ModalBody = ({open, onClose, children, className}) => {
    return (
        <ModalOverlay open={open} onClose={onClose}>
            <div
                className={cn(
                    className ? className : 'sm:max-w-sm',
                    'inline-block w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all dark:bg-gray-700 sm:my-8 sm:p-6 sm:align-middle'
                )}
            >
                {children}
            </div>
        </ModalOverlay>
    )
}
