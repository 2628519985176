import {AuthUrl, ProfileUrl} from '@routesEnums'
import {baseConfig} from '@services/apiConfigs'
import {ApiFactory} from '@services/ApiFactory'

class ProfileService {
    static instance = ApiFactory.createApi(baseConfig)
    static getProfile = async () => {
        return await ProfileService.instance.get(ProfileUrl.profile)
    }

    static postProfile = async (payload, options) => {
        return await ProfileService.instance.post(
            ProfileUrl.profile,
            payload,
            options
        )
    }

    static getAvatar = async () => {
        const newConfig = {
            responseType: 'blob',
        }
        return await ProfileService.instance.get_raw(
            ProfileUrl.profileAvatar,
            undefined,
            newConfig
        )
    }

    static postAvatar = async (file) => {
        return await ProfileService.instance.postFile(
            ProfileUrl.profileAvatar,
            file
        )
    }

    static getConfirmUser = async (userUuid) => {
        return await ProfileService.instance.get(
            ProfileUrl.profileConfirmUser(userUuid)
        )
    }

    static getConfirmChangeEmail = async (token) => {
        return await ProfileService.instance.get(
            ProfileUrl.profileConfirmChangeEmail(token)
        )
    }

    static getConfirmChangePhone = async (payload) => {
        return await ProfileService.instance.get(
            ProfileUrl.profileConfirmChangePhone,
            payload
        )
    }

    static postConfirmChangePhone = async (payload) => {
        const config = {
            params: payload,
        }
        return await ProfileService.instance.post(
            ProfileUrl.profileConfirmChangePhone,
            undefined,
            config
        )
    }
    static postConfirmEmailRepeat = async (payload) => {
        return await ProfileService.instance.post(
            AuthUrl.confirmEmailRepeat,
            payload
        )
    }
}

export default ProfileService
