import {CheckBox} from '@components/forms'

const TableCheckBox = React.forwardRef(({indeterminate, ...rest}, ref) => {
    const defaultRef = React.useRef({})
    const resolvedRef = ref || defaultRef
    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <CheckBox ref={ref} {...rest} />
})

export {TableCheckBox}
