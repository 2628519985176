import Mail from '@assets/icons/providers/mail.svg'
import Vk from '@assets/icons/providers/vk.svg'
import Yandex from '@assets/icons/providers/yandex.svg'
import PropTypes from 'prop-types'

const PROVIDERS_ICONS = {
    mailru: Mail,
    vk: Vk,
    yandex: Yandex,
}

const ProviderIcon = ({
    name,
    size = 40,
    iconClassName = '',
    className = '',
}) => {
    if (!name) return null
    const Icon = PROVIDERS_ICONS[name.toLowerCase()]

    return (
        <div className={className} style={{width: size}}>
            <img
                src={Icon}
                className={iconClassName}
                width={size}
                height={size}
                alt={''}
            />
        </div>
    )
}

ProviderIcon.propTypes = {
    name: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    iconClassName: PropTypes.string,
    className: PropTypes.string,
}

export {ProviderIcon}
