//libs
import {observer} from 'mobx-react'
import {useMemo} from 'react'

//components
import {MultiSelectTags} from '@components/forms/Selects/MultiSelectTags'
import {useAutocomplete} from '@components/forms/Selects/SelectAutocomplete/useAutocomplete'

const MultiSelectAutocomplete = observer(
    ({
        label,
        className,
        selectClassName,
        options,
        selected,
        disabled,
        placeholder,
        helperText,
        error,
        size,
        onSearch,
        onChange = () => {},
        onClick = () => {},
        onBlur = () => {},
        onFocus = () => {},
    }) => {
        const {getSearchInput, inputRef, searchString} = useAutocomplete({
            selected,
            onSearch,
            placeholder,
            disabled,
        })

        const selectOptions = useMemo(() => {
            return options.filter((option) => {
                return String(option.name)
                    .toLowerCase()
                    .includes(String(searchString).toLowerCase())
            })
        }, [options, searchString])

        return (
            <>
                <MultiSelectTags
                    className={className}
                    selectClassName={selectClassName}
                    selectedNode={getSearchInput()}
                    label={label}
                    options={selectOptions}
                    selected={selected}
                    helperText={helperText}
                    error={error}
                    size={size}
                    onChange={onChange}
                    onBlur={() => {
                        inputRef.current.blur()
                        onBlur()
                    }}
                    onFocus={() => {
                        inputRef.current.focus()
                        onFocus()
                    }}
                    onClick={(e) => {
                        inputRef.current.focus()
                        onClick()
                    }}
                    disabled={disabled}
                    portal
                />
            </>
        )
    }
)

export {MultiSelectAutocomplete}
