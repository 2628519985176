import {QuestionMarkCircleIcon} from '@heroicons/react/outline'

const HelperLabel = ({label, onOpen}) => {
    return (
        <div className={'mb-4'}>
            <span
                className={
                    'flex cursor-pointer items-center text-base text-blue-500 hover:text-blue-400 dark:text-blue-400 dark:hover:text-blue-300'
                }
                onClick={() => onOpen(true)}
            >
                <QuestionMarkCircleIcon
                    className={'mr-2 h-5 w-5 flex-shrink-0'}
                />
                {label}
            </span>
        </div>
    )
}

export default HelperLabel
