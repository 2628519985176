import classNames from 'classnames'
import {forwardRef} from 'react'

import {Badge, Button} from '@components/elements'

import {compiler} from 'markdown-to-jsx'

export const NotificationItem = forwardRef(
    ({notification, style, onClickMoreDetails, ...props}, ref) => {
        const handleClickMoreDetails = () => {
            onClickMoreDetails(notification)
        }
        return (
            <div
                ref={ref}
                style={style}
                {...props}
                className={classNames(
                    'relative mt-4 flex w-full items-start rounded-md border border-gray-100 p-4 dark:border-gray-600'
                )}
            >
                {!notification.read && (
                    <Badge
                        className={'absolute -top-2 right-2'}
                        bright
                        color={'red'}
                    >
                        Новое
                    </Badge>
                )}
                <div
                    className={
                        'flex w-full items-center justify-between overflow-hidden'
                    }
                >
                    <div className={'flex w-10/12 flex-col'}>
                        <span
                            className={classNames(
                                'text-base dark:text-gray-100',
                                !notification.read && 'font-bold'
                            )}
                        >
                            {notification.title}
                        </span>
                        {notification.body.length ? (
                            <span
                                className={
                                    'mt-1 inline-flex h-4 text-xs text-gray-800 dark:text-gray-300'
                                }
                            >
                                {compiler(notification.body)}
                            </span>
                        ) : null}
                    </div>
                    <div className={'flex w-2/12'}>
                        <Button
                            color={'white'}
                            size={'sm'}
                            onClick={handleClickMoreDetails}
                        >
                            Подробнее
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
)

NotificationItem.displayName = 'NotificationItem'
