import {LoaderFull} from '@components/elements/Loader'
import {mergeRefs} from '@utils/helpers/index.js'
import classNames from 'classnames'
import {forwardRef, useRef} from 'react'
import {SortIndicators} from './SortIndicators.jsx'
import './Table.scss'
import {TableScrollWrapper} from './TableScrollWrapper'
import {useTableScrollTop} from './useTableScrollTop'
export const Table = forwardRef(
    (
        {
            isBlocked,
            exceedProducts,
            exceedCabinets,
            isTrial,
            loader,
            tableInstance,
            className,
            changeSortBy,
            cellClassName,
            headerStyle,
            cellStyle,
            tableClassName,
            renderRowSubComponent = () => {},
        },
        ref
    ) => {
        const tableScrollRef = useRef(null)
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            page,
            prepareRow,
            state,
            visibleColumns,
            ...rest
        } = tableInstance

        const bodyList = page && page.length ? page : rows

        const {
            syncScroll,
            offsetWidth,
            headerScrollRef,
            showHeaderScroll,
            setShowHeaderScroll,
        } = useTableScrollTop(tableScrollRef)

        const onClickSortBy = (column) => {
            if (isBlocked || isTrial) return
            const {sortBy} = state
            const sortedItem = sortBy.find((item) => item.id === column.id)
            if (!sortedItem) return
            const dir = sortedItem.dir === 'asc' ? 'desc' : 'asc'
            const newSortedItem = {
                ...sortedItem,
                dir,
            }
            changeSortBy && changeSortBy(column.id, newSortedItem)
        }

        return (
            <>
                <TableScrollWrapper
                    ref={headerScrollRef}
                    data={bodyList}
                    offsetWidth={offsetWidth}
                    tableRef={tableScrollRef}
                    syncScroll={syncScroll}
                    showHeaderScroll={showHeaderScroll}
                    setShowHeaderScroll={setShowHeaderScroll}
                />
                <div
                    className={classNames(
                        className,
                        'relative w-full overflow-auto'
                    )}
                    ref={mergeRefs(tableScrollRef, ref)}
                    onScroll={syncScroll}
                >
                    {loader && <LoaderFull absolute />}
                    <table
                        {...getTableProps()}
                        className={classNames(tableClassName, 'w-full')}
                    >
                        <thead
                            className={classNames(
                                'sticky top-0 z-[2] bg-gray-50',
                                'dark:bg-gray-900'
                            )}
                        >
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column, idx) => {
                                        const {key, ...props} =
                                            column.getHeaderProps()
                                        return (
                                            <th
                                                key={key}
                                                {...props}
                                                scope='col'
                                                style={{
                                                    ...headerStyle,
                                                    position: column.sticky
                                                        ? 'sticky'
                                                        : 'static',
                                                    right:
                                                        column.sticky ===
                                                        'right'
                                                            ? column.stickyStyle
                                                                ? column.stickyStyle
                                                                : 0
                                                            : 'auto',
                                                    left:
                                                        column.sticky === 'left'
                                                            ? column.stickyStyle
                                                                ? column.stickyStyle
                                                                : 0
                                                            : 'auto',
                                                }}
                                                className={classNames(
                                                    cellClassName,
                                                    column.cls,
                                                    idx === 0 ? 'pl-8' : 'pl-4',
                                                    'z-50 bg-gray-50 py-3 pr-4 text-left text-xs font-medium uppercase tracking-wider text-gray-500',
                                                    'dark:bg-gray-900 dark:text-gray-400'
                                                )}
                                            >
                                                {column.border && (
                                                    <div
                                                        className={`border-${
                                                            column.sticky ===
                                                            'right'
                                                                ? 'right'
                                                                : 'left'
                                                        }`}
                                                    />
                                                )}
                                                <div
                                                    className={classNames(
                                                        cellClassName,

                                                        {
                                                            'flex items-center':
                                                                state?.sortBy &&
                                                                !!state?.sortBy.find(
                                                                    (item) =>
                                                                        item.id ===
                                                                        column.id
                                                                ),
                                                            'justify-end':
                                                                column.end,
                                                        }
                                                    )}
                                                >
                                                    {column.render('Header')}
                                                    <SortIndicators
                                                        column={column}
                                                        onChangeMode={() =>
                                                            changeSortBy &&
                                                            onClickSortBy(
                                                                column
                                                            )
                                                        }
                                                        sortBy={state.sortBy}
                                                        isBlocked={isBlocked}
                                                        isTrial={isTrial}
                                                        exceedProducts={
                                                            exceedProducts
                                                        }
                                                        exceedCabinets={
                                                            exceedCabinets
                                                        }
                                                        changeSortBy={
                                                            changeSortBy
                                                        }
                                                    />
                                                </div>
                                            </th>
                                        )
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()} key={'body'}>
                            {bodyList.map((row, rowIndex) => {
                                prepareRow(row)
                                const {key, rowProps} = row.getRowProps()
                                return (
                                    <>
                                        <tr key={key} {...rowProps}>
                                            {row.cells.map(
                                                (cell, cellIndex) => {
                                                    return (
                                                        <td
                                                            key={cellIndex}
                                                            {...cell.getCellProps()}
                                                            style={{
                                                                ...cellStyle,
                                                                position: cell
                                                                    .column
                                                                    .sticky
                                                                    ? 'sticky'
                                                                    : 'static',
                                                                right:
                                                                    cell.column
                                                                        .sticky ===
                                                                    'right'
                                                                        ? cell
                                                                              .column
                                                                              .stickyStyle
                                                                            ? cell
                                                                                  .column
                                                                                  .stickyStyle
                                                                            : 0
                                                                        : 'auto',
                                                                left:
                                                                    cell.column
                                                                        .sticky ===
                                                                    'left'
                                                                        ? cell
                                                                              .column
                                                                              .stickyStyle
                                                                            ? cell
                                                                                  .column
                                                                                  .stickyStyle
                                                                            : 0
                                                                        : 'auto',
                                                            }}
                                                            className={classNames(
                                                                cell.column
                                                                    .cellCls,
                                                                cellClassName,
                                                                cellIndex === 0
                                                                    ? 'pl-8'
                                                                    : 'pl-4',
                                                                'relative whitespace-nowrap py-4 pr-4 text-sm text-gray-500',
                                                                'dark:text-gray-400',
                                                                !row.depth
                                                                    ? rowIndex %
                                                                          2 ===
                                                                      0
                                                                        ? 'bg-white dark:bg-gray-700'
                                                                        : 'bg-gray-50 dark:bg-gray-600'
                                                                    : '',
                                                                {
                                                                    'bg-gray-100 dark:bg-innerTableBlack':
                                                                        row.depth >
                                                                        0,
                                                                }
                                                            )}
                                                        >
                                                            {cell.column
                                                                .border && (
                                                                <div
                                                                    className={`border-${
                                                                        cell
                                                                            .column
                                                                            .sticky ===
                                                                        'right'
                                                                            ? 'right'
                                                                            : 'left'
                                                                    }`}
                                                                />
                                                            )}
                                                            {cell.render(
                                                                'Cell'
                                                            )}
                                                        </td>
                                                    )
                                                }
                                            )}
                                        </tr>
                                        {row.isExpanded ? (
                                            <tr
                                                key={`expended_${rowIndex} .`}
                                                className={
                                                    rowIndex % 2 === 0
                                                        ? 'bg-white dark:bg-gray-700'
                                                        : 'bg-gray-50 dark:bg-gray-600'
                                                }
                                            >
                                                <td
                                                    id={'dataExpanded'}
                                                    colSpan={
                                                        visibleColumns.length
                                                    }
                                                >
                                                    {renderRowSubComponent({
                                                        row,
                                                    })}
                                                </td>
                                            </tr>
                                        ) : null}
                                    </>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
)
