import {Button, Timer} from '@components/elements'
import {Loader} from '@components/elements/Loader'
import {TextFieldMask} from '@components/forms/TextFields'
import ArrowNarrowLeftIcon from '@heroicons/react/solid/esm/ArrowNarrowLeftIcon'
import {yupResolver} from '@hookform/resolvers/yup'
import {observer} from 'mobx-react'
import {useEffect, useRef} from 'react'
import {useForm, useWatch} from 'react-hook-form'
import * as yup from 'yup'

const schema = yup.object().shape({
    smsCode: yup
        .string()
        .max(5, 'Код содержит 5 символа')
        .required('СМС-код не должен быть пустым'),
})

export const ConfirmCode = observer(
    ({
        phone,
        onConfirmSuccess,
        onClickToBack,
        onSendSMSCode,
        onCheckSMSCode,
        loader,
        error,
        userUuid,
    }) => {
        const formRef = useRef(null)

        const {
            setError,
            register,
            handleSubmit,
            control,
            setValue,
            formState: {errors},
        } = useForm({mode: 'onBlur', resolver: yupResolver(schema)})

        const smsCode = useWatch({
            control,
            name: 'smsCode',
            defaultValue: '',
        })

        useEffect(() => {
            if (error) {
                setError('smsCode', {type: 'custom', message: error})
            }
        }, [error])

        useEffect(() => {
            if (smsCode.length === 5) {
                formRef.current?.click()
            }
        }, [smsCode])

        const onSubmit = (data) => {
            onCheckSMSCode(data.smsCode, userUuid, onConfirmSuccess)
        }

        const handleClickReSendSMS = (restart) => {
            setValue('smsCode', '')
            restart(new Date().setSeconds(new Date().getSeconds() + 59))
            onSendSMSCode(phone)
        }
        return (
            <div className='space-y-4'>
                <span
                    className='flex cursor-pointer items-center text-base font-medium text-blue-500 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-500'
                    onClick={onClickToBack}
                >
                    <span aria-hidden='true'>
                        <ArrowNarrowLeftIcon className='mr-2 h-8 w-8' />
                    </span>
                    Назад
                </span>
                <h3 className={'text-xl text-gray-700 dark:text-gray-300'}>
                    Подтверждение номера телефона
                </h3>
                <p
                    className={'text-sm text-gray-700 dark:text-gray-300'}
                >{`На номер ${phone} отправлен СМС-код`}</p>
                <form onSubmit={handleSubmit(onSubmit)} className='space-y-1'>
                    <TextFieldMask
                        {...register('smsCode')}
                        mask={'99999'}
                        name='smsCode'
                        label={'СМС-код'}
                        type='text'
                        autoComplete='smsCode'
                        error={!!errors.smsCode}
                        helperText={errors?.smsCode?.message}
                    />
                    <input ref={formRef} className={'hidden'} type={'submit'} />
                </form>
                {/*{error && <ErrorBlock error={error} />}*/}
                <div className='space-y-1'>
                    <Timer>
                        {({isRunning, seconds, restart}) => (
                            <>
                                {seconds > 0 && (
                                    <span className={'mb-5 text-gray-300'}>
                                        {`Повторная отправка через ${seconds} сек.`}
                                    </span>
                                )}
                                <Button
                                    className={'w-full'}
                                    disabled={isRunning || loader}
                                    onClick={() =>
                                        handleClickReSendSMS(restart)
                                    }
                                >
                                    {loader ? (
                                        <Loader sm overlayed={false} />
                                    ) : (
                                        'Отправить код повторно'
                                    )}
                                </Button>
                            </>
                        )}
                    </Timer>
                </div>
            </div>
        )
    }
)
