import {ImportWbUrl} from '@routesEnums'
import {baseConfig} from '@services/apiConfigs'
import {ApiFactory} from '@services/ApiFactory'

export class ImportWbService {
    static instance = ApiFactory.createApi(baseConfig)
    static postWbDetailed = async (payload) => {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
        return await ImportWbService.instance.post(
            ImportWbUrl.wbDetailed,
            payload,
            config
        )
    }
    static postRecalc = async (payload) => {
        return await ImportWbService.instance.post(
            ImportWbUrl.wbDetailedRecalc,
            payload
        )
    }
    static postImportWbList = async (payload) => {
        return await ImportWbService.instance.post(
            ImportWbUrl.wbDetailedList,
            payload
        )
    }
    static patchSource = async (payload) => {
        return await ImportWbService.instance.patch(
            ImportWbUrl.wbDetailedSource,
            payload
        )
    }
}
