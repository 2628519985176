import {ServicesSection} from '@components/applicationUi/sections'
import {ContainerFullOnMobile} from '@components/layout'
import {Header} from '@components/navigation/header/Header'
import {useAnalyticEventsHook} from '@hooks/analyticEventsHook'
import {useSelector} from '@hooks/mobxStoreHooks/useSelector'
import {observer} from 'mobx-react'
import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {toast} from 'react-toastify'

const Promotion = observer(({setSidebarOpen}) => {
    const {myProfileOptions} = useSelector((store) => store.layoutSidebarStore)
    const {pageView} = useAnalyticEventsHook()
    const location = useLocation()

    useEffect(() => {
        pageView()
        if (location.state?.quiz) {
            toast(
                'На данный момент в нашей платформе нет инструментов внешней аналитики, которые вам нужны. Однако, мы можем быть вам полезны услугами по анализу товарных ниш, выведению на маркетплейсы и ведением магазина/рекламы под ключ!',
                {type: 'warning', autoClose: 30000}
            )
        }
    }, [])
    return (
        <>
            <Header
                setSidebarOpen={setSidebarOpen}
                menuOptions={myProfileOptions}
                title={'Другие услуги'}
            />
            <ContainerFullOnMobile className='flex-grow py-6'>
                <div className='mx-auto mb-6'>
                    <ServicesSection servicesSection={true} isRollUp={false} />
                </div>
                {/*<PromotionContent />*/}
            </ContainerFullOnMobile>
        </>
    )
})
export default Promotion
