import {Modal, ModalHeader} from '@components/overlays'

import {TelegramChannelDialog} from './components/TelegramChannelDialog'

export const TelegramChannelModal = ({onClose, open}) => {
    return (
        <Modal className={'sm:max-w-lg'} open={open} onClose={onClose}>
            <ModalHeader title={'Наш канал в телеграме'} onCancel={onClose} />
            <TelegramChannelDialog />
        </Modal>
    )
}
