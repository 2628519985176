import {Button, Mdash} from '@components/elements'
import {DocumentTextIcon} from '@heroicons/react/outline'
import {convertByteToMByte} from '@utils/helpers'
import {observer} from 'mobx-react'

export const RejectedFiles = observer(({rejectedFiles, onClear}) => {
    return (
        <div className={'flex flex-col mt-4 gap-4'}>
            {rejectedFiles.map(({file}, idx) => {
                return (
                    <div key={idx} className={'flex flex-col gap-1'}>
                        <div className={'flex gap-2'}>
                            <DocumentTextIcon
                                className={
                                    'h-5 w-5 text-blue-500 dark:text-blue-300'
                                }
                            />
                            <span
                                className={'text-blue-500 dark:text-blue-300'}
                            >
                                {file.name}
                            </span>
                            <Mdash />
                            <span>{convertByteToMByte(file.size)} MB</span>
                        </div>
                    </div>
                )
            })}
            <p className={'text-sm text-red-500 dark:text-red-300'}>
                Допустимый размер загружаемого файла 20мб. Если ваш файл весит
                больше, источником данных может служить только API
            </p>
            <Button color={'white'} onClick={onClear}>
                Очистить
            </Button>
        </div>
    )
})

RejectedFiles.displayName = 'RejectedFiles'
