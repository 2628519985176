import MarketService from '@services/MarketService/MarketService'
import OnboardingService from '@services/OnboardingService/OnboardingService'
import {LoaderStore} from '@store/LoaderStore'
import {ModalControl} from '@store/ModalControl'
import {ONBOARD_STEP} from '@utils/constants'
import {makeAutoObservable, reaction} from 'mobx'

export class OnboardingStepStore {
    constructor(marketStore, authStore) {
        this.marketStore = marketStore
        this.authStore = authStore
        makeAutoObservable(this)
        this.disposer = reaction(
            () => this.step,
            (step) => {
                if (step === ONBOARD_STEP.completed) {
                    this.isOnboarding = false
                }
            }
        )

        this.disposerUnsubscribeDemo = reaction(
            () => [
                this.marketStore.isOnlyDemoAcc,
                this.marketStore.isOnlyRealAcc,
            ],
            ([isOnlyDemoAcc, isOnlyRealAcc], [prevIsOnlyDemoAcc]) => {
                if (isOnlyRealAcc) return
                if (prevIsOnlyDemoAcc && !isOnlyDemoAcc) {
                    this.unsubscribeDemoAcc()
                }
            }
        )
    }

    isOnboarding = true
    step = ''
    isInsertCoast = false
    isConnectedCabinet = false
    confirmCloseModalControl = new ModalControl(false)

    stepLoader = new LoaderStore()

    setStep = (value) => {
        this.step = value
    }
    setIsInsertCoast = (value) => {
        this.isInsertCoast = value
    }
    setIsConnectedCabinet = (value) => {
        this.isConnectedCabinet = value
    }

    destroy = () => {
        this.step = ''
        this.isInsertCoast = false
        this.isConnectedCabinet = false
        this.isOnboarding = true
        this.stepLoader.setLoading(false)
        this.disposer()
        this.disposerUnsubscribeDemo()
    }

    getOnboardCurrentStep = async () => {
        try {
            const {data} = await OnboardingService.getOnboardCurrentStep()
            this.setStep(data.label.replace(/^onboarding_/, ''))
        } catch (e) {
            console.log(e)
        }
    }

    putOnboardSetStep = async (label, status) => {
        const payload = {
            label: `onboarding_${label}`,
            status,
        }
        this.stepLoader.setLoading(true)
        try {
            const {data} = await OnboardingService.putOnboardSetStep(payload)
            this.setStep(data.label.replace(/^onboarding_/, ''))
            return Promise.resolve()
        } catch (e) {
            console.log(e)
            return Promise.reject(e)
        } finally {
            this.stepLoader.setLoading(false)
        }
    }

    unsubscribeDemoAcc = async () => {
        if (!this.marketStore.getAllDemoAccIds.length) return
        const payload = {
            account_ids: this.marketStore.getAllDemoAccIds,
        }
        try {
            const {data} = await MarketService.deleteUnsubscribeByIds(payload)
            if (data.result) {
                await this.authStore.sendRefreshToken()
                await this.marketStore.getList()
            }
        } catch (e) {
            console.log(e)
        }
    }

    onOpenConfirmClose = () => {
        this.confirmCloseModalControl.open()
    }

    onClose = async () => {
        await this.putOnboardSetStep(ONBOARD_STEP.finalClosed, true)
        this.confirmCloseModalControl.close()
    }
}
