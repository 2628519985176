import {endOfWeek, startOfWeek, subDays, subWeeks} from 'date-fns'

export const getMonday12WeeksAgo = () => {
    const today = new Date()

    // Получаем текущий понедельник
    const currentMonday = startOfWeek(today, {weekStartsOn: 1})

    // Вычитаем 13 недель от текущего понедельника
    return subWeeks(currentMonday, 13)
}

export const getLastSundayExcludingCurrentWeek = () => {
    // Получаем текущую дату
    const today = new Date()

    // Получаем текущий понедельник
    const currentMonday = startOfWeek(today, {weekStartsOn: 1})

    // Вычитаем 1 день от текущего понедельника, чтобы получить предыдущее воскресенье
    return subDays(currentMonday, 1)
}

export const getStartOfWeek = (date) => {
    return startOfWeek(date, {weekStartsOn: 1})
}

export const getEndOfWeek = (date) => {
    return endOfWeek(date, {weekStartsOn: 1})
}
