import {Tag} from '@components/elements/Tag'
import {CheckBox} from '@components/forms'
import {Listbox} from '@headlessui/react'
import {SelectorIcon} from '@heroicons/react/solid'
import {useOnClickOutside} from '@hooks'
import GoogleTagManager from '@utils/googleUtils/googleTagManager'
import classNames from 'classnames'
import {useMemo, useState} from 'react'
import {usePopper} from 'react-popper'

const MultiSelectTags = ({
    label,
    className,
    options,
    children,
    placeholder,
    helperText,
    onChange = () => {},
    onFocus = () => {},
    onBlur = () => {},
    onClick = () => {},
    selectedNode,
    isTagName,
    error,
    disabled,
    selected = [],
    selectedInHelper = true,
    size = 'base',
}) => {
    const [referenceElement, setReferenceElement] = useState(null)
    const [popperElement, setPopperElement] = useState(null)
    const {styles, attributes} = usePopper(referenceElement, popperElement, {
        placement: 'bottom',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 0],
                },
            },
        ],
    })

    const [isOpen, setIsOpen] = useState(false)

    useOnClickOutside({current: referenceElement}, () => {
        setIsOpen(false)
    })

    useMemo(() => {
        if (error && helperText) {
            GoogleTagManager.dataLayer({
                event: 'mainEvent',
                eventCategory: 'Message - Front',
                eventAction: 'message',
                eventLabel: helperText,
                // email: userData.email,
                // user_id: userData.user_id,
            })
        }
    }, [error, helperText])

    const isSelected = (value) => {
        let fn = (el) => el === value
        if (value.key) fn = (el) => el.key === value.key
        return !!selected.find(fn)
    }

    const handleSelect = (value) => {
        if (!isSelected(value)) {
            const selectedOptionsUpdated = [
                ...selected,
                options.find((el) =>
                    value.key ? el.key === value.key : el === value
                ),
            ]
            onChange(selectedOptionsUpdated)
        } else {
            handleDeselect(value)
        }
        setIsOpen(true)
    }

    const handleDeselect = (value) => {
        const selectedOptionsUpdated = selected.filter((el) =>
            value.key ? el.key !== value.key : el !== value
        )
        onChange(selectedOptionsUpdated)
        return setIsOpen(true)
    }

    const {selectedOptionsTag, selectedOptionsName} = useMemo(() => {
        const selectedTag = []
        const selectedName = []
        selected.map((option, index) => {
            let value = option
            if (typeof option === 'object') value = option.name
            const tag = (
                <Tag
                    key={index}
                    className={'my-2 mr-3'}
                    onCancel={(e) => {
                        e.stopPropagation()
                        handleDeselect(option)
                    }}
                >
                    {value}
                </Tag>
            )
            selectedTag.push(tag)
            selectedName.push(value)
        })
        return {
            selectedOptionsTag: selectedTag,
            selectedOptionsName: selectedName.join(', '),
        }
    }, [selected])

    const handleClick = (e) => {
        onClick()
        setIsOpen(!isOpen)
    }
    return (
        <Listbox
            value={selected}
            onChange={(value) => handleSelect(value)}
            open={isOpen}
        >
            {() => (
                <div className={classNames(className, 'w-full')}>
                    {label?.length && (
                        <Listbox.Label className='mb-1 block text-sm font-medium text-gray-700 dark:text-gray-200'>
                            {label}
                        </Listbox.Label>
                    )}
                    <div className='relative w-full'>
                        <Listbox.Button
                            ref={setReferenceElement}
                            onClick={handleClick}
                            open={isOpen}
                            className={classNames(
                                'relative flex min-h-[38px] w-full cursor-pointer justify-between rounded-md border border-gray-300 bg-white text-left shadow-sm transition-all focus:outline-none',
                                'dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600',
                                size === 'sm' && 'py-1.5 pl-3 pr-2.5 text-xs',
                                size === 'md' &&
                                    'py-2 pl-4 pr-3 text-sm font-medium leading-4',
                                size === 'base' &&
                                    'py-2 pl-5 pr-4 text-sm font-medium',
                                size === 'lg' &&
                                    'py-2 pl-5 pr-4 text-base font-medium',
                                size === 'xl' &&
                                    'py-3 pl-7 pr-6 text-base font-medium'
                            )}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            disabled={disabled}
                        >
                            <>
                                {selectedNode || (
                                    <span className='block truncate'>
                                        {isTagName && selectedOptionsTag.length
                                            ? selectedOptionsTag
                                            : selectedOptionsName || (
                                                  <span
                                                      className={
                                                          'text-gray-300'
                                                      }
                                                  >
                                                      {placeholder}
                                                  </span>
                                              )}
                                    </span>
                                )}
                                <span className='pointer-events-none flex items-center'>
                                    <SelectorIcon
                                        className='h-5 w-5 text-gray-400 dark:text-gray-200'
                                        aria-hidden='true'
                                    />
                                </span>
                            </>
                        </Listbox.Button>
                        {!!helperText && (
                            <p
                                className={classNames(
                                    'mt-2 text-sm text-gray-500 dark:text-gray-400',
                                    {
                                        'text-red-500 dark:text-red-300': error,
                                    }
                                )}
                            >
                                {helperText}
                            </p>
                        )}
                        <Listbox.Options
                            className={classNames(
                                'z-10 mt-1 max-h-60 w-full bg-white shadow-lg',
                                'overflow-auto rounded-md py-1 text-base ring-1 ring-black ring-opacity-5',
                                'focus:outline-none sm:text-sm',
                                'dark:bg-gray-700'
                            )}
                            ref={setPopperElement}
                            style={styles.popper}
                            {...attributes.popper}
                        >
                            {options.map((option, index) => {
                                const selected = isSelected(option)
                                return (
                                    <div
                                        key={index}
                                        className={classNames(
                                            selected
                                                ? 'bg-blue-500 text-white dark:bg-blue-400'
                                                : 'text-gray-900 dark:text-gray-300',
                                            'relative cursor-pointer select-none list-none py-2 pl-3 pr-9',
                                            size === 'sm' &&
                                                'py-1.5 pl-3 pr-9 text-xs',
                                            size === 'md' &&
                                                'py-2 pl-4 pr-9 text-sm font-medium leading-4',
                                            size === 'base' &&
                                                'py-2 pl-5 pr-9 text-sm font-medium',
                                            size === 'lg' &&
                                                'py-2 pl-5 pr-9 text-base font-medium',
                                            size === 'xl' &&
                                                'py-3 pl-7 pr-9 text-base font-medium'
                                        )}
                                        onClick={() => handleSelect(option)}
                                    >
                                        <CheckBox
                                            checked={selected}
                                            label={
                                                <span
                                                    className={classNames(
                                                        selected
                                                            ? 'font-semibold'
                                                            : 'font-normal',
                                                        'text-gray-900 dark:text-gray-300'
                                                        // active
                                                        //     ? 'text-white bg-blue-500 dark:bg-blue-400'
                                                        //     :
                                                    )}
                                                >
                                                    {children
                                                        ? children({
                                                              option,
                                                              selected,
                                                          })
                                                        : option.name}
                                                </span>
                                            }
                                            onChange={() => {}}
                                        />
                                    </div>
                                )
                            })}
                        </Listbox.Options>
                        {selectedOptionsName && selectedInHelper && (
                            <div className='flex flex-wrap pt-1 text-gray-900 dark:text-gray-300'>
                                {selectedOptionsTag}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </Listbox>
    )
}

export {MultiSelectTags}
