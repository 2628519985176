import {Button, MarketIcon, VideoPreview} from '@components/elements'
import {Card} from '@components/layout'
import {useResponsive} from '@hooks'
import {VideoPlayerModal} from '@views/marketplaces/pages/Marketplaces/components/Available/GreetingVideoCard/VideoPlayerModal'
import {observer} from 'mobx-react'
import {useState} from 'react'
import {ModalControl} from '../../../../../../../store/ModalControl'

export const GreetingVideoCard = observer(() => {
    const {sm} = useResponsive()
    const [modalControl] = useState(() => new ModalControl())

    return (
        <>
            <Card defaultPadding={false} innerClassName={'px-4 pb-5 pt-3'}>
                <div
                    className={
                        'flex h-full items-center justify-between overflow-hidden sm:flex-col'
                    }
                >
                    <div
                        className={
                            'flex items-center overflow-hidden sm:flex-col'
                        }
                    >
                        {!sm ? (
                            <MarketIcon
                                size={30}
                                className={'mr-3 flex-shrink-0 sm:mb-6 sm:mr-0'}
                            />
                        ) : (
                            <VideoPreview
                                videoUrl={
                                    'https://rutube.ru/video/32f900a1ab8d473af024cbe37a8d779a/'
                                }
                                onClick={modalControl.open}
                                width={'220px'}
                                height={'120px'}
                            />
                        )}
                        <h3
                            className={
                                'text-base font-medium text-gray-800 dark:text-gray-200 sm:mb-4 sm:text-xl'
                            }
                        >
                            {sm ? (
                                'Знакомство с платформой за 2 минуты'
                            ) : (
                                <span>
                                    Начало работы с S24 <br />
                                    за 2 минуты
                                </span>
                            )}
                        </h3>
                    </div>
                    <Button
                        size={sm ? 'base' : 'sm'}
                        color={'white'}
                        onClick={modalControl.open}
                    >
                        {`Смотреть ${sm ? 'видео' : ''}`}
                    </Button>
                </div>
            </Card>
            <VideoPlayerModal
                open={modalControl.isOpen}
                onClose={modalControl.close}
            />
        </>
    )
})
