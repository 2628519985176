import {Pagination} from '@components/elements/Pagination/Pagination'

const TablePagination = ({
    tableInstance,
    max,
    className,
    isLimit,
    isBlocked,
    totalRatio,
    disabled,
    externalButton,
}) => {
    const {
        state: {pageIndex, pageSize, total},
        pageCount,
        gotoPage,
        setPageSize,
    } = tableInstance

    return (
        <>
            <Pagination
                disabled={disabled}
                page={pageIndex + 1}
                limit={pageSize}
                total={total}
                isLimit={isLimit}
                totalPages={pageCount}
                onClickPage={(page) => gotoPage(page - 1)}
                onChangeLimit={setPageSize}
                max={max}
                className={className}
                isBlocked={isBlocked}
                totalRatio={totalRatio}
                externalButton={externalButton}
            />
        </>
    )
}

export {TablePagination}
