import {useOnClickOutside} from '@hooks'
import classNames from 'classnames'
import {useContext, useRef} from 'react'
import {Portal} from '../Portal'
import {PopoverLiteContext} from './PopoverLiteProvider.jsx'

export const PopoverLitePanel = ({
    children,
    className,
    style,
    zIndex = 999,
}) => {
    const context = useContext(PopoverLiteContext)
    const ref = useRef(null)
    useOnClickOutside(ref, () => context.setIsActive(false))

    const getPosition = () => {
        if (context.withinPortal) return null
        if (context.buttonRef.current) {
            const rect = context.buttonRef.current.getBoundingClientRect()
            return {
                top: rect.bottom + window.scrollY, // Нижняя граница триггера
                left: rect.left + window.scrollX, // Левая граница триггера
            }
        }
        return {top: 0, left: 0}
    }

    const renderPortal = (elem) => {
        if (context.withinPortal) return elem
        return <Portal>{elem}</Portal>
    }

    return renderPortal(
        <div
            className={classNames(
                'absolute',
                {hidden: !context.isActive, block: context.isActive},
                className
            )}
            style={{...getPosition(), ...style, zIndex}}
            ref={ref}
        >
            {children}
        </div>
    )
}
