import {TimeLoaderIcon} from '@components/elements/Icon/Icon'
import {CheckIcon} from '@heroicons/react/outline'
import {observer} from 'mobx-react'

export const Status = observer(({status}) => {
    const getText = () => {
        switch (status) {
            case 'published':
                return (
                    <div
                        className={
                            'flex items-center whitespace-normal text-green-500'
                        }
                    >
                        <CheckIcon className={'mr-2 h-5 w-5 flex-shrink-0'} />
                        Опубликован
                    </div>
                )
            case 'on_moderation':
                return (
                    <div
                        className={
                            'flex items-center whitespace-normal text-gray-500 dark:text-gray-400'
                        }
                    >
                        <TimeLoaderIcon
                            className={'mr-2 flex-shrink-0'}
                            width={18}
                            height={18}
                        />
                        На модерации
                    </div>
                )
            case 'failed_moderation':
                return (
                    <div
                        className={
                            'whitespace-normal text-red-500 dark:text-red-400'
                        }
                    >
                        Не прошел модерацию
                    </div>
                )
            case 'unpublished':
                return (
                    <div
                        className={
                            'whitespace-normal text-gray-500 dark:text-gray-400'
                        }
                    >
                        Не опубликован
                    </div>
                )
            default:
                return (
                    <div
                        className={
                            'whitespace-normal text-gray-500 dark:text-gray-400'
                        }
                    >
                        Данные отсутствуют
                    </div>
                )
        }
    }
    return getText()
})
