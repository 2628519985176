import {TelegramUrl} from '@routesEnums'
import {baseConfig} from '@services/apiConfigs'
import {ApiFactory} from '@services/ApiFactory'

class TelegramService {
    static instance = ApiFactory.createApi(baseConfig)
    static getTgUrl = async () => {
        return await TelegramService.instance.get(TelegramUrl.telegramUrl)
    }
    static getTgQr = async () => {
        return await TelegramService.instance.get(TelegramUrl.telegramQr)
    }
    static getTgAccount = async () => {
        return await TelegramService.instance.get(TelegramUrl.telegramAccount)
    }
    static getTgSettings = async () => {
        return await TelegramService.instance.get(TelegramUrl.telegramSettings)
    }
    static getTgNotificationTypes = async () => {
        return await TelegramService.instance.get(
            TelegramUrl.telegramNotificationTypes
        )
    }
    static postTgToken = async () => {
        return await TelegramService.instance.post(TelegramUrl.telegramToken)
    }
    static patchTgNotificationSlug = async (slug, query) => {
        const config = {
            params: query,
        }
        return await TelegramService.instance.patch(
            TelegramUrl.telegramNotificationSlug(slug),
            undefined,
            config
        )
    }
    static patchTgCommonNotifications = async (query) => {
        const config = {
            params: query,
        }
        return await TelegramService.instance.patch(
            TelegramUrl.telegramCommonNotifications,
            undefined,
            config
        )
    }
    static patchTgNotificationMpChange = async (query) => {
        const config = {
            params: query,
        }
        return await TelegramService.instance.patch(
            TelegramUrl.telegramNotificationMpChange,
            undefined,
            config
        )
    }
    static patchTgSwitchTimeSet = async (query) => {
        const config = {
            params: query,
        }
        return await TelegramService.instance.patch(
            TelegramUrl.telegramSwitchTimeSet,
            undefined,
            config
        )
    }
    static patchTgTimeSet = async (query) => {
        const config = {
            params: query,
        }
        return await TelegramService.instance.patch(
            TelegramUrl.telegramTimeSet,
            undefined,
            config
        )
    }
    static deleteTgAccount = async (query) => {
        const config = {
            params: query,
        }
        return await TelegramService.instance.delete(
            TelegramUrl.telegramAccount,
            undefined,
            config
        )
    }
}

export default TelegramService
