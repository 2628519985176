import {useSelector} from '@hooks/mobxStoreHooks/useSelector'
import {marketplaceName} from '@utils/constants'
import {observer} from 'mobx-react'
import {useEffect} from 'react'
import {
    OzonConnector,
    WildberriesConnector,
    YandexConnector,
} from '../Connectors'
import ConnectedCard from './ConnectedCard'

export const Connected = observer(() => {
    const {credentials, statuses, marketplaces, getList} = useSelector(
        (store) => store.marketStore
    )

    useEffect(() => {
        getList()
    }, [])

    const findMarketName = (id) => {
        const marketplace = marketplaces.find((item) => item?.id === id)
        return marketplace?.name
    }

    const getConnector = (
        marketName,
        connectorData,
        onCancel,
        onSuccess,
        onError
    ) => {
        switch (marketName) {
            case marketplaceName.Wildberries:
                return (
                    <WildberriesConnector
                        connectorData={connectorData}
                        onCancel={onCancel}
                        onSuccess={onSuccess}
                        onError={onError}
                    />
                )
            case marketplaceName.Ozon:
                return (
                    <OzonConnector
                        connectorData={connectorData}
                        onCancel={onCancel}
                        onSuccess={onSuccess}
                        onError={onError}
                    />
                )
            case marketplaceName.Yandex:
                return (
                    <YandexConnector
                        connectorData={connectorData}
                        onCancel={onCancel}
                        onSuccess={onSuccess}
                        onError={onError}
                    />
                )
        }
    }

    return (
        <div className={'mb-2'}>
            <h2
                className={
                    'mb-4 text-2xl font-medium text-gray-800 dark:text-gray-200'
                }
            >
                Подключённые площадки
            </h2>
            <div className={'flex flex-col'}>
                {credentials.length ? (
                    credentials.map((item) => (
                        <div className={'mb-4'} key={item?.id}>
                            <ConnectedCard
                                credId={item?.id}
                                marketName={findMarketName(item?.marketplaceId)}
                                marketId={item?.marketplaceId}
                                label={item?.name}
                                isValid={item?.isValid}
                                statuses={statuses.get(item.id)}
                                canManage={item.canManage}
                                canUnsubscribe={item.canUnsubscribe}
                                connector={(
                                    onCancel,
                                    onSuccess,
                                    onError = () => {}
                                ) =>
                                    getConnector(
                                        findMarketName(item?.marketplaceId),
                                        item,
                                        onCancel,
                                        onSuccess,
                                        onError
                                    )
                                }
                            />
                        </div>
                    ))
                ) : (
                    <p
                        className={
                            'text-md mb-4 text-gray-400 dark:text-gray-500'
                        }
                    >
                        У Вас нет подключённых маркетплейсов
                    </p>
                )}
            </div>
        </div>
    )
})

Connected.displayName = 'Connected'
