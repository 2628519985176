import {UrlDisabledService} from '@services/UrlDisabledService'
import {LoaderStore} from '@store/LoaderStore'
import {toSnake} from '@utils/helpers'
import {makeAutoObservable, observable, runInAction} from 'mobx'

export class PathBlockedStore {
    constructor() {
        makeAutoObservable(this)
    }

    pathBlocked = observable.map()
    loaderStore = new LoaderStore()

    getPathData = async () => {
        this.loaderStore.start()
        try {
            const {data} = await UrlDisabledService.getUrlDisableData()
            runInAction(() => {
                Object.entries(data).forEach(([key, value]) => {
                    this.pathBlocked.set(toSnake(key), value)
                })
            })
        } catch (e) {
            console.log(e)
        } finally {
            this.loaderStore.stop()
        }
    }
}
