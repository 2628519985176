import {Modal} from '@components/overlays'

import {SuccessLegalDialog} from './components/SuccessLegalDialog'

export const SuccessLegalModal = ({onClose, open, ...props}) => {
    return (
        <Modal className={'sm:max-w-lg'} open={open} onClose={onClose}>
            <SuccessLegalDialog {...props} />
        </Modal>
    )
}
